import * as React from "react";
import { Form, Select, ColorPicker, Row, Col, Slider, InputNumber, Space, Radio } from "antd";
import { useState } from "react";
import { blendModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleInnerGlow({ config, setConfig }) {
    const innerGlowPos = [
        { label: "居中", value: 0 },
        { label: "边缘", value: 1 },
    ];

    const [blendMode, setBlendMode] = useState(config?.layer_styles.inner_glow["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.inner_glow["opacity"] ?? 100);
    const [color, setColor] = useState(config ? rgbaToHex(config?.layer_styles.inner_glow["color"]) : "#ff00ffff");
    const [pos, setPos] = useState(0);
    const [choke, setChoke] = useState(config?.layer_styles.inner_glow["choke"] ?? 0);
    const [size, setSize] = useState(config?.layer_styles.inner_glow["size"] ?? 0);
    const [range, setRange] = useState(config?.layer_styles.inner_glow["range"] ?? 0);

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.inner_glow = { ...cfg.layer_styles.inner_glow, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const color_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setColor(newValue.toHexString());
        updateConfig({ color: hexToRGB(newValue.toHexString()) });
    };

    const choke_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setChoke(newValue);
        updateConfig({ choke: newValue });
    };

    const size_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSize(newValue);
        updateConfig({ size: newValue });
    };

    const range_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setRange(newValue);
        updateConfig({ range: newValue });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 60, marginLeft: 10 }}
                                value={opacity}
                                onChange={opacity_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="颜色">
                <ColorPicker value={color} onChange={color_onChange} />
            </Form.Item>
            {/* 
            <Form.Item label="源">
                <Radio.Group onChange={(e) => setPos(e.target.value)} value={pos}>
                    {innerGlowPos.map((o, i) => (
                        <Radio key={i} value={o.value}>
                            {o.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item> */}

            <Form.Item label="阻塞">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={choke} onChange={choke_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={100}
                            style={{ width: 60, marginLeft: 10 }}
                            value={choke}
                            onChange={choke_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="大小">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={size} onChange={size_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={250}
                            style={{ width: 60, marginLeft: 10 }}
                            value={size}
                            onChange={size_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            {/* <Form.Item label="范围">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={range} onChange={range_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber min={0} max={100} style={{ width: 60, marginLeft: 10 }} value={range}
                            onChange={range_onChange} />
                    </Col>
                </Row>
            </Form.Item> */}
        </Form>
    );
}

export default LayerStyleInnerGlow;
