import * as React from "react";
import { Form, Select, ColorPicker, InputNumber, Slider, Row, Col, Radio, Space } from "antd";
import { useState } from "react";
import { blendModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleOverlayColor({ config, setConfig }) {

    const [blendMode, setBlendMode] = useState(config?.layer_styles.overlay_color["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.overlay_color["opacity"] ?? 100);
    const [color, setColor] = useState(config ? rgbaToHex(config?.layer_styles.overlay_color["color"]) : "#ff00ffff");

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.overlay_color = { ...cfg.layer_styles.overlay_color, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const color_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setColor(newValue.toHexString());
        updateConfig({ color: hexToRGB(newValue.toHexString()) });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">

            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 60, marginLeft: 10 }}
                                value={opacity}
                                onChange={opacity_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="颜色">
                <ColorPicker value={color} onChange={color_onChange} />
            </Form.Item>

        </Form>
    );
}

export default LayerStyleOverlayColor;
