import "./ConfigPanel.css";


import { loadConfig, saveConfig } from "../utils/configio";


import { Button, Checkbox, Layout, Menu, Modal, Space, Form, Col, Row, Input, InputNumber } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";

function ConfigPanel({ open, setOpen, data }) {

    const [panelOpen, setPanelOpen] = useState(false)
    const [canceled, setCanceled] = useState(false)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [sdHost, setSDHost] = useState("")
    const [samHost, setSAMHost] = useState("")
    const [fsHost, setFSHost] = useState("")


    useEffect(() => {
    
        // console.log("ConfigPanel .......................", data);

        if (data) {
            // console.log("xxxxxxxx", data.sd);
            // setSDHost(data.sd);
            // setSAMHost(data.sam);
            // setFSHost(data.fs);
            setWidth(data.s[0]);
            setHeight(data.s[1]);
        }

    }, [data])
    

    const handleOk = () => {
        // console.log("ooo", "handleConfirm");
        setOpen(false);
        let cfg = {
            s:[parseInt(width), parseInt(height)],
            // sd: sdHost,
            // sam: samHost,
            // fs: fsHost,
            api_prefix: window.api_prefix,
        }
        let ok = window.PSMan?.getInstance().setConfig(cfg);
        saveConfig(cfg, () => {console.log("++++++++++++++++");});
    }

    const handleCancel = () => {
        setCanceled(true)
        setPanelOpen(false)
        setTimeout(() => {
            setOpen(false)
        }, 0)
    }

    const [disabled, setDisabled] = useState(true);



    return (
        <Modal
            // maskStyle={{ background: "none" }}
            centered
            open={open}
            width={600}
            onOk={handleOk}
            onCancel={handleCancel}
            // footer={null}
            // closeIcon={<span className="hidden-close"></span>}
            // className="no-padding-modal"
            // maskClosable={false}
            // okButtonProps={{
            //     disabled: true,
            // }}
            // cancelButtonProps={{
            //     disabled: true,
            // }}
            title={
                <div
                    style={{
                        width: "100%",
                        fontSize: "13pt",
                        // cursor: "move",
                        textAlign: "center",
                        // background: "#424242",
                        padding: 0,
                        // borderRadius: "8px 8px 0 0",
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                    onFocus={() => { }}
                    onBlur={() => { }}
                >
                    设置
                </div>
            }
            // title={"设置"}
        // afterOpenChange={handleOpenChange}
        // modalRender={(modal) => (
        //     <Draggable
        //         disabled={disabled}
        //         bounds={bounds}
        //         nodeRef={draggleRef}
        //         onStart={(event, uiData) => onStart(event, uiData)}
        //     >
        //         <div ref={draggleRef}>{modal}</div>
        //     </Draggable>
        // )}
        >

            <Form size="small" labelCol={{ span: 10 }} labelAlign="left">

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={4}> 初始画板大小: </Col>
                        <Col span={4}>
                            <InputNumber min={128} max={8192} value={width} onChange={(v) => setWidth(v)}></InputNumber>
                        </Col>
                        X
                        <Col span={4}>
                            <InputNumber min={128} max={8192} value={height} onChange={(v) => setHeight(v)}></InputNumber>

                        </Col>
                    </Row>
                </Form.Item>


                {/* <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={4}> Stable Diffusion: </Col>
                        <Col span={10}>
                            <Input placeholder="" value={sdHost} onChange={(e) => setSDHost(e.target.value)}></Input>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={4}> Segment Anything: </Col>
                        <Col span={10}>
                            <Input placeholder="" value={samHost} onChange={(e) => setSAMHost(e.target.value)}></Input>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={4}> FaceSwap: </Col>
                        <Col span={10}>
                            <Input placeholder="" value={fsHost} onChange={(e) => setFSHost(e.target.value)}></Input>
                        </Col>
                    </Row>
                </Form.Item> */}
            </Form>
        </Modal>
    );
}

export default ConfigPanel;
