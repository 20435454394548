import "./style/ModelSelect.scss";

import { Button, Checkbox, Layout, Menu, Modal, Space } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useEffect, useMemo, useState } from "react";
import { useLoraStore } from "./store/lora";
import LoraItem from "./LoraItem";
import { mkCategoryHierarchy, mkMenuDefaultOptions } from "../../utils/util";
import Empty from "../Empty";
import { ReloadOutlined } from "@ant-design/icons";

function LoraSelect({ open, setOpen, addNewLora, delNewLora, selectedLoras, ckpt }) {
    const loraStore = useLoraStore();

    const [available, setAvailable] = useState(false);

    const categoryList = useMemo(() => available ? loraStore.categoryListByBaseModel : loraStore.categoryList, [available, loraStore.categoryListByBaseModel, loraStore.categoryList])

    const categoryHierarchy = useMemo(
        () => mkCategoryHierarchy(categoryList, loraStore.categorySize, false, loraStore.categoryTotalSize),
        [categoryList, loraStore.categorySize, loraStore.categoryTotalSize]
    );

    const defaultMenuOptions = useMemo(() => mkMenuDefaultOptions(categoryHierarchy), [categoryHierarchy])

    const [category, setCategory] = useState(
        defaultMenuOptions.activeKey ? defaultMenuOptions.activeKey.split("/") : []
    );

    const [selectedKeys, setSelectedKeys] = useState(defaultMenuOptions.selectedKeys);
    const [openKeys, setOpenKeys] = useState([])

    const handleCategoryChange = ({ key }) => {
        setCategory(key.split("/"));
    };

    const items = useMemo(() => loraStore.searchCategory(category), [category, loraStore]);

    const filteredItems = useMemo(() => {
        let fitems = items;
        if (available) {
            fitems = items.filter((l) => ckpt.sdVersion === l.sdVersion);
        }
        if (fitems.length === 0 && loraStore.loras.length > 0) {
            setOpenKeys(defaultMenuOptions.openKeys);
            setSelectedKeys(defaultMenuOptions.selectedKeys);
            setCategory(defaultMenuOptions.activeKey ? defaultMenuOptions.activeKey.split("/") : []);
        }
        return fitems;
    }, [items, available, ckpt, defaultMenuOptions, loraStore.loras]);

    useEffect(() => {
        loraStore.setSdVersion(available ? ckpt.sdVersion : undefined);
    }, [ckpt, available, loraStore])

    const totalSize = useMemo(
        () => Object.values(loraStore.categorySize).reduce((n, c) => n + c, 0),
        [loraStore.categorySize]
    );

    return (
        <Modal
            maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
            centered
            open={open}
            width={1150}
            onCancel={() => setOpen(false)}
            title={
                <Space>
                    画笔风格
                    <span>{totalSize}</span>
                    <Button type="text" icon={<ReloadOutlined />} onClick={loraStore.refresh}></Button>
                    <Checkbox checked={available} onChange={(e) => setAvailable(e.target.checked)}>
                        当前可用
                    </Checkbox>
                </Space>
            }
            cancelButtonProps={{ className: "hidden" }}
            okText="确定"
            onOk={() => setOpen(false)}
        >
            <Layout className="model-select" style={{ background: "none" }}>
                <Sider style={{ background: "none", marginRight: "1px" }}>
                    <div className="no-bg-scrollbar category-menu">
                        <Menu
                            selectedKeys={selectedKeys}
                            openKeys={openKeys}
                            onOpenChange={setOpenKeys}
                            onSelect={(e) => setSelectedKeys(e.selectedKeys)}
                            onClick={handleCategoryChange}
                            style={{ border: "none" }}
                            mode="inline"
                            items={categoryHierarchy}
                            defaultSelectedKeys={defaultMenuOptions.selectedKeys}
                            defaultOpenKeys={defaultMenuOptions.openKeys}
                        ></Menu>
                    </div>
                </Sider>
                <Content>
                    <div className="main-title">{category.join(" > ")} </div>
                    <div className="model-list no-bg-scrollbar">
                        {!filteredItems.length && <Empty />}
                        {filteredItems.map((item, i) => (
                            <LoraItem
                                key={item.name}
                                lora={item}
                                addNewLora={addNewLora}
                                delNewLora={delNewLora}
                                selectedLoras={selectedLoras}
                                ckpt={ckpt}
                            />
                        ))}
                    </div>
                </Content>
            </Layout>
        </Modal>
    );
}

export default LoraSelect;
