
function downloadImageResource(outnam, arr) {
    console.log("downloadImageResource() : ", outnam);

    // let outnam = "";
    let blob = null;
    // outnam = "output.png";
    blob = new Blob([arr], { type: "image/png" });

    // if (false) {
    //     outnam = "out.txt";
    //     let utf8decoder = new TextDecoder(); // default 'utf-8' or 'utf8'
    //     let sss = utf8decoder.decode(arr);
    //     console.log("================================ " + sss);

    //     blob = new Blob([sss]);

    // } else {
    //     outnam = "out.png";
    //     blob = new Blob([arr], { type: "image/png" });
    // }

    const linkNode = document.createElement('a');

    console.log("================================", linkNode);

    linkNode.download = outnam; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击
    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);
}


function downloadJSONResource(outnam, obj) {
    console.log("downloadJSONResource() : ", outnam, obj, JSON.stringify(obj));

    // let outnam = "";
    let blob = null;
    // outnam = "output.png";
    let txt = JSON.stringify(obj);
    blob = new Blob([txt], { type: "text/json" });

    // if (false) {
    //     outnam = "out.txt";
    //     let utf8decoder = new TextDecoder(); // default 'utf-8' or 'utf8'
    //     let sss = utf8decoder.decode(arr);
    //     console.log("================================ " + sss);

    //     blob = new Blob([sss]);

    // } else {
    //     outnam = "out.png";
    //     blob = new Blob([arr], { type: "image/png" });
    // }

    const linkNode = document.createElement('a');

    console.log("================================", linkNode);

    linkNode.download = outnam; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击
    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);
}


export {downloadImageResource, downloadJSONResource};