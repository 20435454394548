import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";

function useConfig() {
    const API_URL = process.env.REACT_APP_API_URL;

    const [baseModel, setBaseModel] = useState([]);
    const load = async () => {
        const response = await fetch(`${API_URL}/tu/configs`).then((resp) => resp.json());
        setBaseModel(response.base_model);
    };

    useEffect(() => {
        load();
    }, []);

    const baseModelSelect = useMemo(() => {
        return [{ label: "未选择", value: "" }, ...baseModel.map((o) => ({ label: o, value: o }))];
    }, [baseModel]);

    return {
        baseModelSelect,
    };
}

export const [useConfigStore] = createGlobalStore(useConfig);
