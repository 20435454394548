
import * as React from 'react';

import { useState, useRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "../styles/ag-theme-custom.css";
import Resizer from '../components/Resizer/Resizer';

function HistoryPanel(props) {

    // console.log("HistoryPanel::init............................ : " + window.PSMan);


    const gridRef = useRef();

    const [rowData, setRowData] = React.useState([]);

    React.useEffect(() => {
        console.log("HistoryPanel::componentWillMount() " + window.PSMan);

        window.PSMan_onHistoryEvents = function (nm, obj) {
            console.log("PSMan_onHistoryEvents()>>>>>>:: ", nm, obj);

            if (nm.startsWith("history::")) {
                //console.log(obj);

                let its = obj.items;
                var dataSrc = its.map((item, idx) => ({ key: item.id, idx: item.idx, id: item.id, desc: item.desc, shadow: item.shadow }));
                // console.log(" =======>new TableSource :\n", dataSrc);
    
                setRowData(dataSrc);
            }

            
        }

        return () => {
            console.log("HistoryPanel::componentWillUnmount()");
            // Anything in here is fired on component unmount.
            delete window.PSMan_onHistoryEvents;
        }
    }, [])

    const onRowClicked = (e) => {

        console.log("HistoryPanel::onRowSelected ... ", e.data);
        let cid = window.PSMan.getInstance().historyManager().jump(e.data.idx);

    };

    const [columnDefs] = useState([
        {
            headerName: 'No.',
            field: 'idx',
            width: 50,
        },
        {
            headerName: 'Description',
            field: 'desc',
            width: 248,
        }
    ])


    return (
        <>
            <Resizer maxHeight={450} minHeight={100}>
            <div className="ag-theme-custom-dark" style={{ width: 300, height: 200 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    suppressHorizontalScroll={true}
                    suppressCellFocus={true}
                    suppressRowClickSelection={false}
                    suppressMovableColumns={true}
                    animateRows={true}
                    rowSelection="single"
                    onRowClicked={onRowClicked}
                ></AgGridReact>
            </div>
            </Resizer>
        </>
    );
}








export default HistoryPanel;