import { Button, Divider, Form, Radio, Select, Slider, Space } from "antd";
import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";

import "./BasePanel.css";


function BeautyPanel(props) {

    const skinOptions = [
        { label: "无", value: "skin-none" },
        { label: "极致", value: "skin-hazy" },
        { label: "精准", value: "skin-natural" },
        { label: "自然", value: "skin-plus" },
    ]

    const DEFAULT_SMOOTHING = 50;
    const DEFAULT_FAIR = 20;
    const DEFAULT_RUDDY = 30;
    const DEFAULT_SHARPEN = 50;

    const [skinType, setSkinType] = useState(skinOptions[0].value)
    const [smoothing, setSmoothing] = useState(DEFAULT_SMOOTHING);
    const [fair, setFair] = useState(DEFAULT_FAIR);
    const [ruddy, setRuddy] = useState(DEFAULT_RUDDY);
    const [sharpen, setSharpen] = useState(DEFAULT_SHARPEN);


    const [plasticEnable, setPlasticEnable] = useState(false)
    const [faceCount, setFaceCount] = useState(0);

    const plasticOptions = [
        { label: "关", value: false },
        { label: "开", value: true },
    ]

    const [eyeEnlarge, setEyeEnlarge] = useState(0);;            // 眼睛大小
    const [cheekThin, setCheekThin] = useState(0);               // 瘦脸
    const [foreheadHeight, setForeheadHeight] = useState(0);     // 额头高低
    const [faceSmall, setFaceSmall] = useState(0);               // 小脸
    const [cheekNarrow, setCheekNarrow] = useState(0);           // 窄脸
    const [cheekBoneNarrow, setCheekBoneNarrow] = useState(0);   // 瘦颧骨
    const [cheekLowBoneNarrow, setCheekLowBoneNarrow] = useState(0); // 下颌骨
    const [browThickness, setBrowThickness] = useState(0);       // 眉毛粗细
    const [browHeight, setBrowHeight] = useState(0);             // 眉毛高低
    const [eyeAngle, setEyeAngle] = useState(0);;                // 眼角
    const [eyeDistance, setEyeDistance] = useState(0);           // 眼距
    const [eyeHeight, setEyeHeight] = useState(0);               // 眼睛高低
    const [eyeInnerConer, setEyeInnerConer] = useState(0);       // 内眼角
    const [eyeOuterConer, setEyeOuterConer] = useState(0);       // 外眼角
    const [noseWidth, setNoseWidth] = useState(0);               // 鼻子宽度
    const [noseHeight, setNoseHeight] = useState(0);             // 鼻子长度
    const [philterumThickness, setPhilterumThickness] = useState(0); // 缩人中
    const [mouthWidth, setMouthWidth] = useState(0);             // 嘴巴宽度
    const [lipsThickness, setLipsThickness] = useState(0);       // 嘴唇厚度
    const [chinThickness, setChinThickness] = useState(0);       // 下巴高低



    useEffect(() => {
        if (props.tool === "beauty") {

            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(beauty)>>>>>>:: ", nm, obj);
                // if (nm.startsWith("tool::beauty")) {
                // }


                if (nm === "tool::beauty-face-state") {
                    if (obj["face_count"] > 0) {
                        setFaceCount(obj["face_count"]);
                    } else {
                        setFaceCount(0);
                    }
                }

            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg);
                // updateImage(cfg.image);
                // setSnapping(cfg.snapping);

                setSkinType(cfg.skin.type);
                setPlasticEnable(cfg.plastic.enable);
                setEyeEnlarge(cfg.plastic.eyeEnlarge);
                setCheekThin(cfg.plastic.cheekThin);

            }
        }
    }, [props]);

    const skinType_onChange = (e) => {
        if (e == null) {
            return;
        }

        console.log("BeautyPanel::skinType_onChange: ", e);

        setSkinType(e.target.value);

        console.log("BeautyPanel::skinType_onChange: ", window.PSMan?.getInstance().toolManager().current());

        window.PSMan?.getInstance().toolManager().current().setConfig({ skin: { type: e.target.value } });
    };

    // beautify functions. --------------------------------------------------
    const smoothing_onChange = (newValue) => {
        if (newValue == null) { return; }
        setSmoothing(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ skin: { smoothing: newValue } });
    };

    const fair_onChange = (newValue) => {
        if (newValue == null) { return; }
        setFair(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ skin: { fair: newValue } });
    };

    const ruddy_onChange = (newValue) => {
        if (newValue == null) { return; }
        setRuddy(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ skin: { ruddy: newValue } });
    };

    const sharpen_onChange = (newValue) => {
        if (newValue == null) { return; }
        setSharpen(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ skin: { sharpen: newValue } });
    };

    // plastic functions. --------------------------------------------------
    const plasticEnable_onChange = (e) => {
        if (e == null) { return; }

        console.log("BeautyPanel::plasticEnable_onChange: ", e);

        setPlasticEnable(e.target.value);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { enable: e.target.value } });
    };

    const eyeEnlarge_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeEnlarge(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeEnlarge: newValue } });
    };
    const cheekThin_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCheekThin(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { cheekThin: newValue } });
    };
    const foreheadHeight_onChange = (newValue) => {
        if (newValue == null) { return; }
        setForeheadHeight(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { foreheadHeight: newValue } });
    };
    const faceSmall_onChange = (newValue) => {
        if (newValue == null) { return; }
        setFaceSmall(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { faceSmall: newValue } });
    };

    const cheekNarrow_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCheekNarrow(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { cheekNarrow: newValue } });
    };
    const cheekBoneNarrow_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCheekBoneNarrow(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { cheekBoneNarrow: newValue } });
    };
    const cheekLowBoneNarrow_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCheekLowBoneNarrow(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { cheekLowBoneNarrow: newValue } });
    };
    const browThickness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBrowThickness(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { browThickness: newValue } });
    };
    const browHeight_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBrowHeight(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { browHeight: newValue } });
    };
    const eyeAngle_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeAngle(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeAngle: newValue } });
    };
    const eyeDistance_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeDistance(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeDistance: newValue } });
    };
    const eyeHeight_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeHeight(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeHeight: newValue } });
    };
    const eyeInnerConer_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeInnerConer(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeInnerConer: newValue } });
    };
    const eyeOuterConer_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEyeOuterConer(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { eyeOuterConer: newValue } });
    };
    const noseWidth_onChange = (newValue) => {
        if (newValue == null) { return; }
        setNoseWidth(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { noseWidth: newValue } });
    };
    const noseHeight_onChange = (newValue) => {
        if (newValue == null) { return; }
        setNoseHeight(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { noseHeight: newValue } });
    };
    const philterumThickness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setPhilterumThickness(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { philterumThickness: newValue } });
    };
    const mouthWidth_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMouthWidth(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { mouthWidth: newValue } });
    };
    const lipsThickness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setLipsThickness(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { lipsThickness: newValue } });
    };
    const chinThickness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setChinThickness(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { chinThickness: newValue } });
    };


    const reset_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().setConfig({ plastic: { enable: false }, skin: { type: skinOptions[0].value } });

        setSkinType(skinOptions[0].value);
        setPlasticEnable(false);
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setSkinType(skinOptions[0].value);
        setPlasticEnable(false);
    }


    return (<BasePanel className={props.className} title={"美颜:"}>
        <Form size="middle" labelCol={{ span: 6 }} labelAlign="left" >
            <Divider orientation="left" plain> 美肤 </Divider>

            <FormItem label="">
                <Radio.Group value={skinType} onChange={skinType_onChange}>
                    {skinOptions.map((o) => (<Radio key={o.label} value={o.value}>{o.label}</Radio>))}
                </Radio.Group>
                {/* <Select value={skinType} onChange={setSkinType} options={skinOptions}></Select> */}
            </FormItem>

            {skinType === "skin-hazy" && (
                <>
                    <FormItem label="磨皮">
                        <Slider min={0} max={100} value={smoothing} onChange={smoothing_onChange} />
                    </FormItem>
                    <FormItem label="美白">
                        <Slider min={0} max={100} value={fair} onChange={fair_onChange} />
                    </FormItem>
                    <FormItem label="红润">
                        <Slider min={0} max={100} value={ruddy} onChange={ruddy_onChange} />
                    </FormItem>
                </>
            )}
            {skinType === "skin-natural" && (
                <>
                    <FormItem label="磨皮">
                        <Slider min={0} max={100} value={smoothing} onChange={smoothing_onChange} />
                    </FormItem>
                    <FormItem label="美白">
                        <Slider min={0} max={100} value={fair} onChange={fair_onChange} />
                    </FormItem>
                    <FormItem label="红润">
                        <Slider min={0} max={100} value={ruddy} onChange={ruddy_onChange} />
                    </FormItem>
                </>
            )}
            {skinType === "skin-plus" && (
                <>
                    <FormItem label="磨皮">
                        <Slider min={0} max={100} value={smoothing} onChange={smoothing_onChange} />
                    </FormItem>
                    <FormItem label="美白">
                        <Slider min={0} max={100} value={fair} onChange={fair_onChange} />
                    </FormItem>
                    <FormItem label="锐化">
                        <Slider min={0} max={100} value={sharpen} onChange={sharpen_onChange} />
                    </FormItem>
                </>
            )}

            <Divider orientation="left" plain> 微整形 </Divider>
            <Radio.Group value={plasticEnable} onChange={plasticEnable_onChange}>
                {plasticOptions.map((o) => (<Radio key={o.label} value={o.value}> {o.label} </Radio>))}
            </Radio.Group>
            {
                plasticEnable && (
                    <label>
                        人脸数: {faceCount}
                    </label>
                )
            }
            {(plasticEnable && faceCount > 0) &&
                (<div className="psman-scroll-area" >
                    <FormItem label="大眼">
                        <Slider min={0} max={100} value={eyeEnlarge} onChange={eyeEnlarge_onChange} />
                    </FormItem>
                    <FormItem label="瘦脸">
                        <Slider min={0} max={100} value={cheekThin} onChange={cheekThin_onChange} />
                    </FormItem>

                    <FormItem label="额高">
                        <Slider min={-100} max={100} value={foreheadHeight} onChange={foreheadHeight_onChange} />
                    </FormItem>
                    <FormItem label="小脸">
                        <Slider min={0} max={100} value={faceSmall} onChange={faceSmall_onChange} />
                    </FormItem>
                    <FormItem label="窄脸">
                        <Slider min={0} max={100} value={cheekNarrow} onChange={cheekNarrow_onChange} />
                    </FormItem>
                    <FormItem label="瘦颧骨">
                        <Slider min={0} max={100} value={cheekBoneNarrow} onChange={cheekBoneNarrow_onChange} />
                    </FormItem>
                    <FormItem label="下颌骨">
                        <Slider min={0} max={100} value={cheekLowBoneNarrow} onChange={cheekLowBoneNarrow_onChange} />
                    </FormItem>
                    <FormItem label="眉毛粗细">
                        <Slider min={-100} max={100} value={browThickness} onChange={browThickness_onChange} />
                    </FormItem>
                    <FormItem label="眉毛高低">
                        <Slider min={-100} max={100} value={browHeight} onChange={browHeight_onChange} />
                    </FormItem>
                    <FormItem label="眼角">
                        <Slider min={-100} max={100} value={eyeAngle} onChange={eyeAngle_onChange} />
                    </FormItem>
                    <FormItem label="眼距">
                        <Slider min={-100} max={100} value={eyeDistance} onChange={eyeDistance_onChange} />
                    </FormItem>
                    <FormItem label="眼睛高低">
                        <Slider min={-100} max={100} value={eyeHeight} onChange={eyeHeight_onChange} />
                    </FormItem>
                    <FormItem label="内眼角">
                        <Slider min={0} max={100} value={eyeInnerConer} onChange={eyeInnerConer_onChange} />
                    </FormItem>
                    <FormItem label="外眼角">
                        <Slider min={0} max={100} value={eyeOuterConer} onChange={eyeOuterConer_onChange} />
                    </FormItem>
                    <FormItem label="鼻子宽度">
                        <Slider min={0} max={100} value={noseWidth} onChange={noseWidth_onChange} />
                    </FormItem>
                    <FormItem label="鼻子长度">
                        <Slider min={0} max={100} value={noseHeight} onChange={noseHeight_onChange} />
                    </FormItem>
                    <FormItem label="缩人中">
                        <Slider min={-100} max={100} value={philterumThickness} onChange={philterumThickness_onChange} />
                    </FormItem>
                    <FormItem label="嘴巴宽度">
                        <Slider min={-100} max={100} value={mouthWidth} onChange={mouthWidth_onChange} />
                    </FormItem>
                    <FormItem label="嘴唇厚度">
                        <Slider min={-100} max={100} value={lipsThickness} onChange={lipsThickness_onChange} />
                    </FormItem>
                    <FormItem label="下巴高低">
                        <Slider min={-100} max={100} value={chinThickness} onChange={chinThickness_onChange} />
                    </FormItem>

                </div>)
            }
            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>
                        重置
                    </Button>
                    <Button size="small" onClick={apply_onClick} type="primary">
                        确定
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    </BasePanel>
    );
}

export default BeautyPanel;