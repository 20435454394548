import "./style/CartTree.scss";

import { Tree } from "antd"
import { useCartStore } from "./store/cart";
import CartItem from "./CartItem";

function CartTree({ direction }) {
    const cartStore = useCartStore();

    const treeData = (data) => {
        if (!data || data.length === 0) return [];
        return data.map((item) => {
            return {
                key: item.name,
                title: <CartItem data={item} direction={direction} />,
                children: item.children ? treeData(item.children) : null,
            };
        });
    };

    const onDrop = (info) => {
        const loop = (data, key, callback) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].name === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children, key, callback);
                }
            }
        };

        const data = [...cartStore[direction]];
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;

        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        let ar = [];
        let i;
        loop(data, dropKey, (_item, index, arr) => {
            ar = arr;
            i = index;
        });
        if (info.dropPosition === -1) {
            ar.splice(i, 0, dragObj);
        } else {
            ar.splice(i + 1, 0, dragObj);
        }
        cartStore.setter(direction)(ar);
    };

    return (
        <Tree
            onDrop={onDrop}
            className="prompt-tree"
            blockNode
            autoExpandParent
            draggable
            selectable={false}
            treeData={treeData(cartStore[direction])}
        ></Tree>
    );
}

export default CartTree
