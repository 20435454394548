import { Button, Form, Image, Modal, Space, Upload, message, notification } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import {datetimeString} from "../../utils/util"

function GenFont({ children }) {
    const child = React.Children.only(children);

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const Trigger = () =>
        React.cloneElement(child, {
            onClick: handleClick,
        });

    const [fontFile, setFontFile] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification()
    const [uploading, setUploading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [img, setImg] = useState("");
    const [error, setError] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [msg, setMsg] = useState('')

    const generate = () => {
        if (uploading || generating) {
            return;
        }

        setGenerating(true);
        setError("");
        axios
            .post(`/api/tu/font/generate_font`, fontFile, { timeout: 600000 }) // 10m
            .then((res) => {
                if (res.status !== 200) {
                    messageApi.error(res.statusText);
                    setError(res.statusText);
                    return;
                }
                const resp = res.data;
                if (resp.length > 0) {
                    download(resp);
                } else {
                    setError("字体创建失败!");
                    messageApi.error("字体创建失败！");
                }
            })
            .catch((e) => {
                messageApi.error(e.message);
                setError(e.message);
                console.log(e);
            })
            .finally(() => {
                setGenerating(false);
            });
    };

    const beforeUpload = (file) => {
        if (uploading || generating) {
            return false;
        }
        setFontFile(null);
        setImg("");
        setError("");
        setMsg("")

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImg(reader.result);
        });
        if (file) {
            reader.readAsDataURL(file);
        }

        let data = new FormData();
        data.append("file", file);
        setUploading(true);
        axios
            .post(`/api/tu/font/get_characters`, data)
            .then((res) => {
                if (res.status !== 200) {
                    messageApi.error(res.statusText);
                    return;
                }
                const re = /error, valid number .+ (\d+)/;
                const resp = res.data;
                if (resp === "ok, success") {
                    setFontFile(data);
                } else if (resp.match(re) !== null) {
                    messageApi.warning("字符数少于 " + resp.match(re)[1] + " 个, 请重新上传");
                } else {
                    messageApi.error(resp);
                }
            })
            .catch((e) => {
                messageApi.error(e.message);
                console.log(e);
            })
            .finally(() => {
                setUploading(false);
            });
        return false;
    };

    const download = (fontName) => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        axios
            .get(`/api/tu/font/file/font_ttf/${fontName}.ttf`, {
                responseType: "arraybuffer",
            })
            .then(({ data }) => {
                const name = `font-${datetimeString()}.ttf`;
                let savnam = `/psman/${name}`;
                console.log("font file", data.length);
                window.PSMan.getInstance()
                    .resourceManager()
                    .save(savnam, data, () => {
                        const msg = "字体创建成功！" + name;
                        setMsg(msg)
                        notificationApi['success']({
                            key: "font",
                            message: "自定义字体",
                            description: msg,
                            duration: 0
                        })
                    });
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    return (
        <>
            {notificationContextHolder}
            {contextHolder}
            <Trigger />
            <Modal onCancel={() => setOpen(false)} open={open} footer={false}>
                <Form labelCol={{ span: 2 }}>
                    <FormItem label="图片">
                        {img && (
                            <div style={{ paddingBottom: 4 }}>
                                <Image width={200} src={img} />
                            </div>
                        )}
                        <Upload name="file" accept=".jpg, .jpeg, .png" fileList={[]} beforeUpload={beforeUpload}>
                            <Button disabled={generating} icon={<UploadOutlined />} loading={uploading}>
                                上传图片
                            </Button>
                        </Upload>
                    </FormItem>
                    <FormItem wrapperCol={{ offset: 2 }}>
                        <div>
                            <Space>
                                {fontFile && !msg && (
                                    <Button
                                        onClick={generate}
                                        size="middle"
                                        disabled={fontFile === null}
                                        type="primary"
                                        loading={generating}
                                    >
                                        创建字体
                                    </Button>
                                )}
                                {msg && <span>{msg}</span>}
                            </Space>
                        </div>
                        <div>{error}</div>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}

export default GenFont;
