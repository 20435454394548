import * as React from "react";
import { Form, Select, ColorPicker, Row, Col, Slider, InputNumber, Space, Switch } from "antd";
import { useState } from "react";
import { blendModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleSatin({ config, setConfig }) {
    const [blendMode, setBlendMode] = useState(config?.layer_styles.satin["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.satin["opacity"] ?? 100);
    const [color, setColor] = useState(config ? rgbaToHex(config?.layer_styles.satin["color"]) : "#ff00ffff");
    const [size, setSize] = useState(config?.layer_styles.satin["size"] ?? 1);
    const [degree, setDegree] = useState(config?.layer_styles.satin["degree"] ?? 0);
    const [distance, setDistance] = useState(config?.layer_styles.satin["distance"] ?? 0);
    const [invert, setInvert] = useState(config?.layer_styles.satin["invert"] ?? false);

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.satin = { ...cfg.layer_styles.satin, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const size_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSize(newValue);
        updateConfig({ size: newValue });
    };

    const color_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setColor(newValue.toHexString());
        updateConfig({ color: hexToRGB(newValue.toHexString()) });
    };

    const degree_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setDegree(newValue);
        updateConfig({ degree: newValue });
    };

    const distance_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setDistance(newValue);
        updateConfig({ distance: newValue });
    };

    const invert_onChange = (checked) => {
        setInvert(checked);
        updateConfig({ invert: checked });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 60, marginLeft: 10 }}
                                value={opacity}
                                onChange={opacity_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="颜色">
                <ColorPicker value={color} onChange={color_onChange} />
            </Form.Item>

            <Form.Item label="大小">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={250} value={size} onChange={size_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={250}
                            style={{ width: 60, marginLeft: 10 }}
                            value={size}
                            onChange={size_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="角度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={359} value={degree} onChange={degree_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={359}
                            style={{ width: 60, marginLeft: 10 }}
                            value={degree}
                            onChange={degree_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="距离">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={359} value={distance} onChange={distance_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={359}
                            style={{ width: 60, marginLeft: 10 }}
                            value={distance}
                            onChange={distance_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="反转">
                <Switch checked={invert} onChange={invert_onChange}></Switch>
            </Form.Item>
        </Form>
    );
}

export default LayerStyleSatin;
