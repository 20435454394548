import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";
import { group } from "../../../utils/util";

function useLora() {
    const DEFAULT_WEIGHT = 0.75
    const API_URL = process.env.REACT_APP_API_URL;
    const [loras, setLoras] = useState([]);
    const [sdVersion, setSdVersion] = useState(undefined)

    const categorySize = useMemo(() => {
        const size = {};
        for (const m of loras.filter((l) => sdVersion === undefined || l.sdVersion === sdVersion)) {
            const key = m.category.join("/");
            size[key] = (size[key] ?? 0) + 1;
        }
        return size;
    }, [loras, sdVersion]);

    const categoryTotalSize = useMemo(() => {
        const size = {};
        for (const c in categorySize) {
            const cs = c.split("/");
            for (let i = 0; i < cs.length + 1; i++) {
                const p = cs.slice(0, i).join("/");
                if (p) {
                    size[p] = (size[p] ?? 0) + categorySize[c];
                }
            }
        }
        return size;
    }, [categorySize]);

    const categoryList = useMemo(() => {
        const dedup = {};
        const filtered = loras
            .map(({ category }) => category.join("/"))
            .filter((e) => !(dedup[e] = e in dedup))
            .sort()
            .map((e) => e.split("/"));
        return filtered;
    }, [loras]);

    const categoryListByBaseModel = useMemo(() => {
        const dedup = {};
        const filtered = loras
            .filter((l) => sdVersion === undefined || l.sdVersion === sdVersion)
            .map(({ category }) => category.join("/"))
            .filter((e) => !(dedup[e] = e in dedup))
            .sort()
            .map((e) => e.split("/"));
        return filtered;
    }, [loras, sdVersion])

    const lorasByCategory = useMemo(() => {
        return group(loras, (t) => t.category.join("/"));
    }, [loras]);

    const searchCategory = (category) => {
        return lorasByCategory[category.join("/")] ?? [];
    };

    const load = async (refresh) => {
        const _refresh = typeof refresh === "boolean" && refresh ? true : false;
        const response = await fetch(`${API_URL}/tu/lora-models?refresh=${_refresh}`).then((resp) => resp.json());
        const items = response.map((o) => {
            o.thumb = o.preview;
            if (!o.preview && o.images?.length > 0) {
                o.thumb = o.images[0].url;
            }
            return {
                ...o,
                weight: o.config?.weight ? o.config.weight : DEFAULT_WEIGHT,
                alias: o.config?.alias && o.config.alias !== "" ? o.config.alias : o.name,
                category: o.config?.category && o.config.category.length > 0 ? o.config.category : ["通用"],
                sdVersion: o.config?.base_model ?? ""
            };
        });
        setLoras(items);
    };

    const refresh = () => load(true)

    useEffect(() => {
        load();
    }, []);

    return {
        API_URL,
        loras,
        categorySize,
        categoryTotalSize,
        categoryList,
        searchCategory,
        load,
        refresh,
        categoryListByBaseModel,
        setSdVersion,
    };
}

export const [useLoraStore] = createGlobalStore(useLora);
