import BasePanel from "./BasePanel";
import { Form, Slider, Radio, Select, Button, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import { ReloadOutlined, HolderOutlined } from "@ant-design/icons";
import { maskOperateModes } from "../utils/defs";


function MaskToolPanel(props) {
    let lassoBlurValue = 0;
    let brushBlurValue = 0;
    let rectBlurValue = 0;
    let radiusValue = 20;
    let samBlurValue = 0;

    const [maskTool, setMaskTool] = useState("rect-mask");

    const [lassoBlurSize, setLassoBlurSize] = useState(lassoBlurValue);

    const [radius, setRadius] = useState(radiusValue);
    const [brushBlurSize, setBrushBlurSize] = useState(brushBlurValue);

    const [rectblurSize, setRectBlurSize] = useState(rectBlurValue);
    const [rectSnapping, setRectSnapping] = useState(false);

    const [wandBlurSize, setWandBlurSize] = useState(rectBlurValue);
    const [wandTolerance, setWandTolerance] = useState(50);


    const [samBlurSize, setSamBlurSize] = useState(samBlurValue);


    const [maskOperateMode, setMaskOperateMode] = useState(0);


    const [modelOptions, setModelOptions] = useState([]);
    const [model, setModel] = useState("");
    const [mask, setMask] = useState(0);
    const [ptype, setPType] = useState(0);
    // const [mode, setMode] = useState(0);

    const changeTool = () => {
        if (props.tool !== "mask") {
            return;
        }

        // setMaskTool(props.subTool);

        window.PSMan?.getInstance().toolManager().use(maskTool);

        let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();

        if (maskTool === "brush-mask") {
            if (cfg) {
                // setMode(cfg.mode);
                setRadius(cfg.radius);
                setBrushBlurSize(cfg.blur);
                setMaskOperateMode(cfg.mode);
            }
        } else if (maskTool === "lasso-mask") {
            if (cfg) {
                // setMode(cfg.mode);
                setLassoBlurSize(cfg.blur);
                setMaskOperateMode(cfg.mode);
            }
        } else if (maskTool === "rect-mask") {
            if (cfg) {
                // setMode(cfg.mode);
                setRectBlurSize(cfg.blur);
                setMaskOperateMode(cfg.mode);
            }
        } else if (maskTool === "wand-mask") {
            if (cfg) {
                // setMode(cfg.mode);
                setWandBlurSize(cfg.blur);
                setMaskOperateMode(cfg.mode);
            }
        } else if (maskTool === "sam-mask") {
            window.PSMan.getInstance().toolManager().current().sendAction("get-models", {});
            if (cfg) {
                // setMode(cfg.mode);
                setMaskOperateMode(cfg.mode);
                setModel(cfg.model);
                setSamBlurSize(cfg.blur);
            }
        } else if (maskTool === "auto-sam-mask") {
            window.PSMan.getInstance().toolManager().current().sendAction("get-models", {});
            if (cfg) {
                // setMode(cfg.mode);
                setMaskOperateMode(cfg.mode);
                setModel(cfg.model);
                setSamBlurSize(cfg.blur);
            }
        }
    }

    useEffect(() => {
        if (props.tool === "mask") {
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(mask)>>>>>>:: ", nm, obj);

                if (nm === "tool::sam-mask") {
                    const cfgModels = obj;
                    const options = cfgModels.reduce(
                        (a, c) => {
                            a.push({ label: c, value: c });
                            return a;
                        },
                        []
                    );
                    setModelOptions(options);
                    if (options.length > 0) {
                        setModel(options[0].value);
                    }
                } else if (nm === "tool::auto-sam-mask") {
                    const cfgModels = obj;
                    const options = cfgModels.reduce(
                        (a, c) => {
                            a.push({ label: c, value: c });
                            return a;
                        },
                        []
                    );
                    setModelOptions(options);
                    if (options.length > 0) {
                        setModel(options[0].value);
                    }
                }
            };
            changeTool();
        }
    }, [props.tool]);

    useEffect(() => {
        changeTool();
    }, [maskTool]);

    useEffect(() => {
        if (props.tool === "mask") {
            setMaskTool(props.subTool);
            changeTool();
        }
    }, [props.subTool]);


    // brush 
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current().setConfig({ radius: radius, blur: brushBlurSize });
        }
    }, [radius, brushBlurSize]);

    // rect 
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current().setConfig({ blur: rectblurSize });
        }
    }, [rectblurSize]);

    // rect 
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current().setConfig({ blur: lassoBlurSize });
        }
    }, [lassoBlurSize]);

    // wand 
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current()
                .setConfig({ blur: wandBlurSize, tolerance: wandTolerance });
        }
    }, [wandBlurSize, wandTolerance]);

    // sam
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current().setConfig({ model: model });
        }
    }, [model]);
    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current().setConfig({ blur: samBlurSize });
        }
    }, [samBlurSize]);

    useEffect(() => {
        if (typeof window.PSMan != "undefined") {
            window.PSMan.getInstance().toolManager().current()
                .setConfig({ mode: maskOperateMode });
        }
    }, [maskOperateMode]);



    const handleMaskChange = (e) => {
        const value = e.target.value;
        setMask(value);
        console.log("mask change:", value);
        window.PSMan.getInstance().toolManager().current().sendAction("choose", value);
    };


    const handlePTChange = (e) => {
        const value = e.target.value;
        setPType(value);
        window.PSMan.getInstance().toolManager().current().sendAction("point-type", value);
    };

    const handleRefreshModels = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("get-models", {});
    };

    const updateSnapping = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ snapping: val });
        setRectSnapping(val);
    }

    // const onMaskShowChange = (vs, e) => {
    //     setMaskShow(vs.target.checked);
    //     // // console.log("--------- maskShow: ", vs.target.checked);
    //     window.PSMan.getInstance().maskManager().show(vs.target.checked);
    // };

    const onMaskReverse = (e) => {
        window.PSMan.getInstance().maskManager().reverse();
    };

    const onMaskClear = (e) => {
        window.PSMan.getInstance().maskManager().clear();
    };


    return (
        <BasePanel className={props.className} title={"选区:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item>
                    <Radio.Group onChange={(e) => setMaskTool(e.target.value)} value={maskTool}>
                        <Radio value={"rect-mask"}>矩形</Radio>
                        <Radio value={"lasso-mask"}>套索</Radio>
                        <Radio value={"brush-mask"}>画笔</Radio>
                        <Radio value={"wand-mask"}>魔棒</Radio>
                        <Radio value={"sam-mask"}>SAM</Radio>
                        <Radio value={"auto-sam-mask"}>AutoSAM</Radio>
                    </Radio.Group>
                </Form.Item>

                <div style={{ paddingTop: "20px" }} />

                <Form.Item className={""}>
                    <Radio.Group onChange={(e) => setMaskOperateMode(e.target.value)} value={maskOperateMode}>
                        <Radio value={maskOperateModes[0].value} >新选区</Radio>
                        <Radio value={maskOperateModes[1].value} >添加到选区</Radio>
                        <Radio value={maskOperateModes[2].value} >从选区减去</Radio>
                        <Radio value={maskOperateModes[3].value} >与选区交叉</Radio>
                    </Radio.Group>
                </Form.Item>
                <div style={{ paddingTop: "20px" }} />

                <Form.Item>
                    <Space>
                        <Button
                            // shape="circle"
                            size="small"
                            onClick={onMaskReverse}
                        >
                            反转
                        </Button>

                        <Button
                            type="dashed"
                            danger
                            // shape="circle"
                            size="small"
                            onClick={onMaskClear}
                        >
                            清除
                        </Button>
                    </Space>
                </Form.Item>

                <div style={{ paddingTop: "20px" }} />

                <Form.Item className={maskTool === "lasso-mask" ? "" : "hidden"}>
                    <Form.Item label="羽化">
                        <Slider value={lassoBlurSize} min={0} max={40} onChange={setLassoBlurSize} />
                    </Form.Item>
                </Form.Item>

                <Form.Item className={maskTool === "brush-mask" ? "" : "hidden"}>
                    <Form.Item label="大小">
                        <Slider value={radius} min={5} max={40} onChange={setRadius} />
                    </Form.Item>
                    <Form.Item label="羽化">
                        <Slider value={brushBlurSize} min={0} max={40} onChange={setBrushBlurSize} />
                    </Form.Item>
                </Form.Item>

                <Form.Item className={maskTool === "rect-mask" ? "" : "hidden"}>
                    <Form.Item label="对齐到网格:">
                        <Switch checked={rectSnapping} onChange={updateSnapping} />
                    </Form.Item>
                    <Form.Item label="羽化">
                        <Slider value={rectblurSize} min={0} max={40} onChange={setRectBlurSize} />
                    </Form.Item>
                </Form.Item>

                <Form.Item className={maskTool === "wand-mask" ? "" : "hidden"}>
                    <Form.Item label="羽化">
                        <Slider value={wandBlurSize} min={0} max={40} onChange={setWandBlurSize} />
                    </Form.Item>
                    <Form.Item label="容差">
                        <Slider value={wandTolerance} min={0} max={255} onChange={setWandTolerance} />
                    </Form.Item>
                </Form.Item>

                <Form.Item className={maskTool === "sam-mask" ? "" : "hidden"}>
                    <Form.Item>
                        <Space.Compact>
                            <Form.Item label="SAM模型">
                                <Select
                                    style={{ width: 180 }}
                                    value={model}
                                    onChange={setModel}
                                    options={modelOptions}
                                ></Select>
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button shape="circle" icon={<ReloadOutlined />} onClick={handleRefreshModels} />
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item label="模式">
                        <Radio.Group defaultValue={0} onChange={handlePTChange} value={ptype}>
                            <Radio value={0}>Positive</Radio>
                            <Radio value={1}>Negative</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="结果">
                        <Radio.Group defaultValue={0} onChange={handleMaskChange} value={mask}>
                            <Radio value={0}>0</Radio>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>X</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="羽化">
                        <Slider value={samBlurSize} min={0} max={40} onChange={setSamBlurSize} />
                    </Form.Item>
                </Form.Item>

                <Form.Item className={maskTool === "auto-sam-mask" ? "" : "hidden"}>
                    <Form.Item>
                        <Space.Compact>
                            <Form.Item label="SAM模型">
                                <Select
                                    style={{ width: 180 }}
                                    value={model}
                                    onChange={setModel}
                                    options={modelOptions}
                                ></Select>
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button shape="circle" icon={<ReloadOutlined />} onClick={handleRefreshModels} />
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item label="羽化">
                        <Slider value={samBlurSize} min={0} max={40} onChange={setSamBlurSize} />
                    </Form.Item>
                </Form.Item>
            </Form>
        </BasePanel>
    );
}

export default MaskToolPanel;