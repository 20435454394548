import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { Button as AButton, Dropdown, Switch, Input, Radio, Tooltip, Collapse } from 'antd';

import { DownOutlined } from '@ant-design/icons';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BasePanel from "./BasePanel";
import { Form, Col, InputNumber, Row, Slider, Space, Select } from 'antd';
import { LeftOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import DreamBuilder from "../components/dream-builder/DreamBuilder";
import { CNTypes, DreamPaintTypes, samplerList } from "../utils/defs"
import DreamResultPanel from "./DreamResultPanel"

const { TextArea } = Input;
const { Panel } = Collapse;



function OutpaintPanel(props) {

    // defaultConfigs.width = 10;
    //const defaultNegPrompt = "glitch, error, text, watermark, bad quality, blurry"
    const defaultNegPrompt = "lowres, error, extra digit, fewer digits, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, curve, irregular, coarse, cheap, defect, misplace, distort"

    const [prompt, setPrompt] = useState("")
    const [negativePrompt, setNegativePrompt] = useState(defaultNegPrompt)
    const [width, setWidth] = useState(512)
    const [height, setHeight] = useState(512)
    const [iter, setIter] = useState(1)               // 生成批次(Iterations) [1 - 100]
    const [batchSize, setBatchSize] = useState(4)     // 每批数量(Batch Size) [1 - 8]
    const [cfgScale, setCfgScale] = useState(1.0)     // 提示词相关性(CFG Scale)
    const [seed, setSeed] = useState(1.0)             // 随机种子(seed)

    const [denoising, setDenoising] = useState(1.0)   // 重绘幅度(Denoising)
    const [outpaintType, setOutpaintType] = useState(DreamPaintTypes[2])    // 蒙版蒙住的内容(Outpaint Type)

    // const [inpaintModels, setInpaintModels] = useState([]);
    const [inpaintModels, setInpaintModels] = useState([{ label: "-", value: -1 }]);
    const [inpaintModel, setInpaintModel] = useState("");
    const [inpaintArgs, setInpaintArgs] = useState("");

    const [cnEnabled, setCnEnabled] = useState(true);

    const [styleFidelity, setStyleFidelity] = useState(0.5);
    // const [cnpreEnabled, setCnpreEnabled] = useState(false);

    // const [cnType, setCNType] = useState("请选择")    // CN type
    // const [cnpreType, setCNpreType] = useState("N/A")    // CN preprocess type
    // const [cnpreTypes, setCNpreTypes] = useState(null)    // CN types
    // const [cnmode, setCnmode] = useState(1);


    const [overMaskPx, setOverMaskPx] = useState(0)   // 蒙版边缘预留像素(Overmask px)
    const [keepUnmaskedBlur, setKeepUnmaskedBlur] = useState(0)           // 蒙版模糊(Keep Unmasked Blur)


    const [curImageIndex, setCurImageIndex] = useState(0)
    const [generatedImages, setGeneratedImages] = useState(0)
    // const [indexText, setIndexText] = useState("0/0");
    const [models, setModels] = useState([]);
    const [modelTitle, setModelTitle] = useState('');

    const [actionState, setActionState] = useState(0);
    const [dreamState, setDreamState] = useState("");

    const [mode, setMode] = useState(false);

    const [sampler, setSampler] = useState("DPM++ 2M Karras");
    const [steps, setSteps] = useState(1);

    const defaultConfigs = useRef(null);

    const [reflayer, setReflayer] = useState("当前图层");
    const [reflayerList, setReflayerList] = useState([]);

    const [indexButtonLabel, setIndexButtonLabel] = useState("0/0");

    useEffect(() => {
        let lb = "" + curImageIndex + "/" + generatedImages;
        setIndexButtonLabel(lb);
    }, [curImageIndex, generatedImages]);

    useEffect(() => {
        if (props.tool == "outpaint") {
            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(outpaint)>>>>>>:: ", nm, obj);

                //获取当前SDModel
                if (nm === "tool::sd-model-update") {
                    console.log("Model updated successfully!!!");
                    setModelTitle(obj)
                }
                //当前所有SD模型
                if (nm.startsWith("tool::sd-models")) {
                    var rowData = obj.map((item, idx) => ({ value: item.title, label: item.title }));
                    setModels(rowData);
                }

                if (nm === "tool::inpaint-models") {

                    let m = obj.map((e, idx) => ({ label: e, value: idx }))
                    setInpaintModels(m);
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    setInpaintModel(config["inpaint_model"]);
                }

                //更新UI状态
                if (nm === "tool::outpaint-state") {
                    setWidth(obj["width"]);
                    setHeight(obj["height"]);

                    setCurImageIndex(obj.index);
                    setGeneratedImages(obj.size);

                    setActionState(obj.action_info);

                    if (obj.info) {
                        console.log("Info: ", obj.info);
                        setDreamState(obj.info)
                    } else {
                        setDreamState("")
                    }
                }


                // //更新Config值
                // if (nm === "tool::outpaint-config-update") {
                //     let config = window.PSMan.getInstance().toolManager().current().getConfig();
                //     console.log(config);
                //     setWidth(config.width);
                //     setHeight(config.height);
                //     setMode(config.mask_mode);
                //     setSampler(config.sampler);
                //     setSteps(config.steps);
                //     setCfgScale(config["cfg_scale"]);
                //     setSeed(config["seed"]);
                //     setDenoising(config["denoising"]);
                //     let ss = DreamPaintTypes[config["outpaint_type"]];
                //     // console.log("--------------\n", ss, { ...ss });
                //     setOutpaintType(ss);
                //     setOverMaskPx(config["overmask_px"]);
                //     setKeepUnmaskedBlur(config["keep_unmask_blur"]);


                // }

            }


            let config = window.PSMan.getInstance().toolManager().current().getConfig();

            console.log("OutpaintPanel::config: ", config);
            defaultConfigs.current = config;

            updateDreamPrompt({ positive: prompt, negative: negativePrompt });

            // setDreamPrompt(config["prompt"]);
            // setDreamNegativePrompt(config["negative_prompt"]);
            setWidth(config["width"]);
            setHeight(config["height"]);
            setIter(config["n_iter"]);
            setBatchSize(config["batch_size"]);
            setSteps(config["steps"]);
            setCfgScale(config["cfg_scale"]);
            setSeed(config["seed"]);

            setDenoising(config["denoising"]);
            // setOutpaintType(DreamPaintTypes[config["inpainting_fill"]]);
            setOverMaskPx(config["overmask_px"]);
            setKeepUnmaskedBlur(config["keep_unmask_blur"]);
            setCurImageIndex(0);
            setReflayer("当前图层");
            setInpaintModel(config["inpaint_model"]);

            let inpaint_args = config["inpaint_args"];

            {
                const pattern = /.*reference_only:*([0-9.]*);*.*/
                const matches = inpaint_args.match(pattern);
                // console.log("=>>>>>>>>>>>>>>>>: ", inpaint_args);
                if (matches && matches.length > 1) {
                    const strval = matches[1];
                    // console.log("=>>>>>>>>>>>>>>>>:: ", strval);
                    if (strval.length > 0) {
                        const val = parseFloat(strval);
                        // console.log("=>>>>>>>>>>>>>>>>::: ", val);
                        setStyleFidelity(val);
                    }
                }
            }
            setInpaintArgs(inpaint_args);

            // config["info_imgs_count"] = _priv->images.size();
            // config["info_cur_img_index"] = _priv->image_idx;

            // window.PSMan.getInstance().toolManager().current().sendAction("sd-state", {});

        } else {
            // setResultPanelVisible(false);
        }

    }, [props.tool])


    // dream params ------------------------------


    const cnmode_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        let mv = newValue.target.value;
        // console.log(" ================ :: ", );
        // setCnEnabled(newValue);
        // setCnmode(mv);
        // window.PSMan.getInstance().toolManager().current().setConfig({ cn_mode: mv });
        // setCNpreType("none");
        // setCNType("请选择");
    };


    const reset_config = () => {
        let newcfg = { ...defaultConfigs.current };
        delete newcfg.prompt;
        delete newcfg.negative_prompt;
        delete newcfg.sd_model;
        console.log(newcfg);
        window.PSMan.getInstance().toolManager().current().setConfig(newcfg);

    }

    const dreamWidth_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setWidth(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ width: newValue });
    };

    const dreamHeight_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setHeight(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ height: newValue });
    };

    const cfgScale_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setCfgScale(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ cfg_scale: newValue });
    };

    const seed_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSeed(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ seed: newValue });
    };

    const denoising_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setDenoising(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ denoising: newValue });
    };

    const sampler_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSampler(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ sampler: newValue });
    };

    const steps_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSteps(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ steps: newValue });
    };

    const iter_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setIter(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ n_iter: newValue });
    };

    const batchSize_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setBatchSize(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ batch_size: newValue });
    };

    const overMaskPx_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOverMaskPx(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ overmask_px: newValue });
    };

    const keepUnmaskedBlur_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setKeepUnmaskedBlur(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ keep_unmask_blur: newValue });
    };

    // const outpaintType_onChange = (value) => {
    //     let newValue = DreamPaintTypes[value];
    //     setOutpaintType(newValue);
    //     window.PSMan.getInstance().toolManager().current().setConfig({ inpainting_fill: newValue.value });
    // };

    // dream result ------------------------------
    const DreamButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("dream", {});
    }
    const PreviousButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("prev", {});
    }

    const IndexButton_Click = () => {
    }

    const NextButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("next", {});
    }

    const MoreButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("more", {});
    }

    const ApplyButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("apply", {});
    }

    const CancelButton_Click = () => {
        // setResultPanelVisible(false)
        window.PSMan.getInstance().toolManager().current().sendAction("cancel", {});
    }

    const SaveResourcesButton_Click = () => {
        // download current index image .
        window.PSMan.getInstance().toolManager().current().sendAction("resources", {});
    }

    // const DownloadButton_Click = () => {
    //     // add current index image into resources list.
    //     window.PSMan.getInstance().toolManager().current().sendAction("download", {});
    // }


    const openDreamBuilder = (e) => {
        e.target.blur()
        setDrOpen(true)
    }

    // dream builder
    const [drOpen, setDrOpen] = useState(false)

    const updateDreamPrompt = ({ positive, negative }) => {
        window.PSMan?.getInstance().toolManager().current().setConfig({ prompt: positive, negative_prompt: negative });
    };

    const onMaskModeChange = (e) => {
        const val = e.target.value;
        setMode(val);
        // console.log("--------- maskMode: ", val);
        window.PSMan.getInstance().toolManager().current().setConfig({ mask_mode: val });
    };

    const reflayer_onSelect = (e) => {
        let keyidx = parseInt(e.key, 10);
        console.log("reflayer_onSelect ...............", e, e.idx, reflayerList, keyidx);
        if (keyidx < 0) {
            setReflayer("当前图层");
            window.PSMan?.getInstance().toolManager().current().setConfig({ bg_layer: -1 });
            return;
        }
        let ldat = reflayerList[keyidx + 1];
        setReflayer(ldat.label);
        console.log("LLLLLLLLLLL: ", ldat);
        window.PSMan?.getInstance().toolManager().current().setConfig({ bg_layer: ldat.value });
    }

    const reflayer_onClick = (e) => {
        console.log("reflayer_onClick ...............", e);
        if (e) {
            let s = window.PSMan.getInstance().layerManager().state();
            console.log("XXXXX ", s);
            let ss = s.layers.filter((item) => !!item.vis);
            let ss2 = ss.map((item, idx) => ({ key: item.idx, label: item.name, value: item.id }));
            ss2.unshift({ key: -1, label: "当前图层", value: -1 });
            setReflayerList(ss2);
        }

    }

    const updateInpaintModel = (e) => {
        console.log("updateInpatinModel ... ", e);
        let cur = inpaintModels[e];
        window.PSMan?.getInstance().toolManager().current().setConfig({ inpaint_model: cur.label });
        setInpaintModel(cur.label);
    }

    const updateInpaintArgs = (e) => {
        console.log("updateInpaintArgs ... ", e);
        let v = e ? "reference_only:" + styleFidelity : "";
        window.PSMan?.getInstance().toolManager().current().setConfig({ inpaint_args: v });
        setInpaintArgs(v);
    }

    const updatStyleFidelity = (val) => {
        console.log("updatStyleFidelity ... ", val);
        setStyleFidelity(val);
        let v = val ? "reference_only:" + val : "";
        setInpaintArgs(v);

        window.PSMan?.getInstance().toolManager().current().setConfig({ inpaint_args: v });
    }

    return (
        <BasePanel className={props.className} title={"扩图:"}>

            <Form size="small" labelAlign="left">

                <Form.Item align="middle" className="ant-form-item">
                    <Row> 模型： </Row>
                    <Select open={false} onClick={openDreamBuilder} style={{ width: 300 }} options={models}
                        defaultValue={"请选择模型"} value={modelTitle} />
                </Form.Item>
                <Form.Item align="middle" className="ant-form-item">
                    <Row> 提示词： </Row>
                    <TextArea placeholder="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} onClick={openDreamBuilder} />
                </Form.Item>
                {/* <Form.Item align="middle" className="ant-form-item">
                    <Collapse size="small" bordered={false}>
                        <Panel header="反向提示词:" key="1">
                            <TextArea placeholder="Negative Prompt" value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)} onClick={openDreamBuilder} />
                        </Panel>
                    </Collapse>
                </Form.Item> */}

                {/* <Form.Item align="middle" className="ant-form-item">
                    <Row> 反向提示词： </Row>
                    <TextArea placeholder="Negative Prompt" value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)} onClick={openDreamBuilder} />
                </Form.Item> */}



                <Form.Item align="middle" className="ant-form-item">
                    <Row>
                        <Space >
                            <Col span={6}>
                                <label>ControlNet:</label>
                            </Col>
                            {/* <Col span={2}>
                                <Switch checked={cnEnabled} onChange={(v) => setCnEnabled(v)} />
                            </Col> */}
                            {
                                cnEnabled && (
                                    <>
                                        <Col span={6}>
                                            <label>reference_only:</label>
                                        </Col>
                                        <Col span={2}>
                                            <Switch checked={inpaintArgs.length > 0} onChange={updateInpaintArgs} />
                                        </Col>

                                        {inpaintArgs.length > 0 && (
                                            <Col span={4}>
                                                <InputNumber min={0.0} max={1.0} step={0.1} style={{  width: 60 }}
                                                    value={styleFidelity} onChange={updatStyleFidelity} />
                                            </Col>

                                        )}

                                    </>
                                )

                            }

                        </Space>

                    </Row>

                    {
                        cnEnabled && (
                            <Row>
                                <Select onChange={updateInpaintModel} value={inpaintModel} options={inpaintModels} />
                            </Row>
                        )
                    }
                    

                    {/* {cnEnabled &&
                        <><Row>
                            <Col span={8}>CN 类型:</Col>
                            <Col span={16}><Select options={CNTypes} value={cnType}
                                onChange={cnType_onChange} /></Col>
                        </Row>
                            {
                                cnType && <Row>
                                    <Col span={8}>预处理:</Col>
                                    <Col span={16}><Select options={cnpreTypes} value={cnpreType}
                                        onChange={cnpreType_onChange} /></Col>
                                </Row>
                            }

                        </>
                    } */}


                </Form.Item>

                <Form.Item align="middle" className="ant-form-item">
                    <Row align="middle" >
                        <Col span={4}>
                            <label>宽高：</label>
                        </Col>
                        <Col span={4}>
                            <Space>
                                <InputNumber readOnly min={128} max={2048} style={{ margin: "0 10px", width: 64 }}
                                    value={width} onChange={dreamWidth_onChange} />
                                x
                                <InputNumber readOnly min={128} max={2048} style={{ margin: "0 10px", width: 64 }}
                                    value={height} onChange={dreamHeight_onChange} />
                            </Space>
                        </Col>
                    </Row>
                </Form.Item>

                <Collapse size="small" bordered={false}>
                    <Panel header="高级参数:" key="1">
                        <Form.Item className="ant-form-item">
                            <AButton
                                type="primary"
                                onClick={() => reset_config()}>重置参数</AButton>
                        </Form.Item>
                        {/* <Form.Item>
                            <Row align="middle" >
                                <Radio.Group onChange={onMaskModeChange} value={mode}>
                                    <Radio value={true}>重绘非蒙版区</Radio>
                                    <Radio value={false}>重绘蒙版区</Radio>
                                </Radio.Group>
                            </Row>
                        </Form.Item> */}
                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={12}> 采样方法(Sampler):</Col>
                                <Col span={12}>
                                    <Select value={sampler} options={samplerList} onChange={sampler_onChange}></Select>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={12}>
                                    <label>采样迭代步数(Steps):</label>
                                </Col>
                                <Col span={8}>
                                    <Slider min={1} max={150}
                                        value={steps} onChange={steps_onChange} />
                                </Col>
                                <Col span={1}>
                                    <InputNumber min={1} max={150} style={{ margin: "0 6px", width: 42 }}
                                        value={steps} onChange={steps_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>


                        {/* <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={19}> 蒙版边缘预留像素(Overmask px):</Col>
                                <Col span={1}>
                                    <InputNumber min={0} max={64} style={{ width: 64 }}
                                        value={overMaskPx} onChange={overMaskPx_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={19}>蒙版模糊(Keep Unmasked Blur):</Col>
                                <Col span={2}>
                                    <InputNumber min={0} max={64} style={{ width: 64 }}
                                        value={keepUnmaskedBlur} onChange={keepUnmaskedBlur_onChange} />
                                </Col>
                            </Row>
                        </Form.Item> */}

                        {/* <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={16}> 生成批次(Iterations):</Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={100} style={{ width: 64 }}
                                        value={iter} onChange={iter_onChange} />
                                </Col>
                            </Row>
                        </Form.Item> */}

                        {/* <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={16}> 每批数量(Batch Size): </Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={8} style={{ width: 64 }}
                                        value={batchSize} onChange={batchSize_onChange} />
                                </Col>
                            </Row>
                        </Form.Item> */}
                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={14}> 生成数量: </Col>
                                <Col span={10}>
                                    <Space>
                                        <InputNumber min={1} max={10} style={{ width: 48 }}
                                            value={iter} onChange={iter_onChange} />
                                        X
                                        <InputNumber min={1} max={8} style={{ width: 48 }}
                                            value={batchSize} onChange={batchSize_onChange} />
                                    </Space>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 提示词相关性(CFG Scale):</Col>
                                <Col span={2}>
                                    <InputNumber min={1.0} max={30.0} step={0.5} style={{ width: 100 }} precision={1}
                                        value={cfgScale} onChange={cfgScale_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>


                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 重绘幅度(Denoising): </Col>
                                <Col span={8}>
                                    <InputNumber min={0.0} max={1.0} step={0.01} style={{ width: 100 }} precision={2}
                                        value={denoising} onChange={denoising_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>


                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 随机种子(seed): </Col>
                                <Col span={8}>
                                    <InputNumber min={-100} max={100} style={{ width: 100 }}
                                        value={seed} onChange={seed_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        {/* <Form.Item className="ant-form-item" >
                            <Row> 蒙版蒙住的内容(Outpaint Type): </Row>
                            <Select style={{ width: 270 }} options={DreamPaintTypes} value={outpaintType.label}
                                onChange={outpaintType_onChange} />
                        </Form.Item> */}
                    </Panel>
                </Collapse>

                <DreamResultPanel actionState={actionState} imageIndex={curImageIndex} imageSize={generatedImages} dreamInfo={dreamState}></DreamResultPanel>


            </Form>


            <DreamBuilder
                open={drOpen}
                setOpen={setDrOpen}
                ckptTitle={modelTitle}
                positiveTags={prompt}
                setPositiveTags={setPrompt}
                negativeTags={negativePrompt}
                setNegativeTags={setNegativePrompt}
                updateDreamPrompt={updateDreamPrompt} />

        </BasePanel>
    );
}




export default OutpaintPanel;