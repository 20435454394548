import * as React from 'react';

import { ColorPicker, Checkbox, Select, Row, Col } from 'antd';
import { Form, Slider } from "antd";
import BasePanel from './BasePanel';
import { hexToRGB } from "../utils/util"
import { ADE20KColors } from '../utils/defs';


function PenPanel(props) {

    // console.log("PenPanel::init............................ : " + window.PSMan);

    const [color, setColor] = React.useState("#ffffffff");
    const [radius, setRadius] = React.useState(50);
    const [blurSize, setBlurSize] = React.useState(20);
    const [amask, setAMask] = React.useState(true);
    const [colorName, setColorName] = React.useState("-");
    const [colorOptions, setColorOptions] = React.useState(ADE20KColors);


    React.useEffect(() => {
        if (props.tool == "pen") {


            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(pen)>>>>>>:: ", nm, obj);

                if (nm == "tool::pen-config-update") {
                    let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
                    setRadius(cfg.radius);
                    setBlurSize(cfg.blur_size);
                    setAMask(cfg.alter_mask);
                    setColor(cfg.color);
                }
            }


            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                // console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg);
                setRadius(cfg.radius);
                setBlurSize(cfg.blur_size);
                setAMask(cfg.alter_mask);
                setColor(cfg.color);
            }


            // let rgba;
            // rgba.r = config["color"][0];
            // rgba.g = config["color"][1];
            // rgba.b = config["color"][2];
            // rgba.a = config["color"][3];
            // setColor(rgba);
            // setRadius(config["radius"]);
            // setBlurSize(config["blur_size"]);
        }

    }, [props.tool])

    const updateAMask = (e) => {
        console.log(e);
        setAMask(e.target.checked);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ alter_mask: e.target.checked });
    }
    const updateRadius = (val) => {
        setRadius(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ radius: val });
    }
    const updateBlur = (val) => {
        setBlurSize(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ blur_size: val });
    }
    const updateColor = (val) => {
        setColor(val);
        
        // window.PSMan?.getInstance().toolManager().current()?.setConfig({ color: hexToRGB(val)});
        console.log("updateColor::", val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ color: val });

        let tcolor = val.toUpperCase();

        let o = ADE20KColors.filter((it) => {
            return it.color == tcolor;
        });
        if (o.length > 0) {
            setColorName(o[0].label);
        } else {
            setColorName("-");
        }
    }

    const changeColor= (val, opt) => {
        // console.log(val);
        console.log("changeColor::", val, opt);
        setColor(opt.color);
        setColorName(opt.label);
        setColorOptions(ADE20KColors);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ color: opt.color });
    }

    const searchColor= (val) => {
        console.log("searchColor::", val);
        let nop = ADE20KColors.filter((o)=>{
            return o.label.startsWith(val)
        })
        // console.log("mmmmmmmmmmmmmm : ", nop);
        setColorOptions(nop)
    }

    // React.useEffect(() => {
    //     console.log('useEffect ' + JSON.stringify(color) + " / " + radius + window.PSMan);

    //     console.log('PenPanel::useEffect ', color);

    //     if (typeof window.PSMan != "undefined") {
    //         // window.PSMan.tool_manager.set_config({ color: hexToRGB(color), radius: radius });
    //         window.PSMan.getInstance().toolManager().current().setConfig({ color: hexToRGB(color), radius: radius, blur_size: blurSize, alter_mask: amask });
    //     }
    // }, [color, radius, blurSize, amask]
    // );

    return (
        <BasePanel className={props.className} title={"画笔:"}>
            <Form labelCol={{ span: 4 }} labelAlign="left">

                {/* <Checkbox checked={amask} onChange={updateAMask}>同时更新蒙版</Checkbox> */}

                <div style={{ paddingTop: "20px" }} />

                <Form.Item label="颜色" >
                    <ColorPicker value={color} onChange={(v) => updateColor(v.toHexString())} />
                </Form.Item>

                <Form.Item>

                    <Row align="middle">
                        <Col span={8}> ADE20K预设:</Col>
                        <Col span={10}>
                        <Select showSearch options={colorOptions} value={colorName}
                                onChange={changeColor}
                                onSearch={searchColor}
                                filterOption={false}
                                // placeholder="点击选择"
                                />
                        </Col>
                    </Row>


                    
                </Form.Item>

                <Form.Item label="大小">
                    <Slider value={radius} min={2} max={40} onChange={updateRadius} />
                </Form.Item>

                <Form.Item label="模糊">
                    <Slider value={blurSize} min={0} max={40} onChange={updateBlur} />
                </Form.Item>
            </Form>
        </BasePanel>
    );
}


export default PenPanel;