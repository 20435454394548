import "./style/TagItem.scss";

import { Button, Card, Tooltip } from "antd"
import TagPostCount from "./TagPostCount"
import useClipboard from "react-use-clipboard";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useCartStore } from "./store/cart";

function TagItem({ meta, showCategory = false }) {

    const cartStore = useCartStore()

    const tagKey = meta.key
    const [copied, copy] = useClipboard(tagKey, { successDuration: 1000 })

    const inPositive = useMemo(() => cartStore.existsPositive("tag", meta.key), [meta, cartStore]);
    const inNegative = useMemo(() => cartStore.existsNegative("tag", meta.key), [meta, cartStore]);
    const aliasInPositive = useMemo(() =>
        meta?.alias &&
        meta.alias.reduce(
            (a, t) => {
                a[t] = cartStore.existsPositive("tag", t);
                return a
            },
            {}
        )
        , [meta, cartStore])
    const aliasInNegative = useMemo(() =>
        meta?.alias &&
        meta.alias.reduce(
            (a, t) => {
                a[t] = cartStore.existsNegative("tag", t);
                return a
            },
            {}
        )
        , [meta, cartStore])

    function togglePositive(tag) {
        if (tag === meta.key) {
            if (!inPositive) {
                cartStore.appendPositiveTag(tag);
            } else {
                cartStore.removePositiveTag(tag);
            }
        } else {
            if (!aliasInPositive?.[tag]) {
                cartStore.appendPositiveTag(tag);
            } else {
                cartStore.removePositiveTag(tag);
            }
        }
    }

    function toggleNegative(tag) {
        if (tag === meta.key) {
            if (!inNegative) {
                cartStore.appendNegativeTag(tag);
            } else {
                cartStore.removeNegativeTag(tag);
            }
        } else {
            if (!aliasInNegative?.[tag]) {
                cartStore.appendNegativeTag(tag);
            } else {
                cartStore.removeNegativeTag(tag);
            }
        }
    }

    const description = meta.descirption?.split('\n').map((t, i) => {
        return <p key={i} className="text descirption">{t}</p>
    })

    const aliasList = meta.alias?.map((alias, index) => {
        return (
            <li key={index} className="alias-tag">
                <div className="alias-tag flex-button-container">
                    <div>
                        <code className="tag-name">{ alias }</code>
                    </div>
                    <div className="buttons">
                        <Tooltip title="我想要" mouseEnterDelay={0.75}>
                            <Button
                                type={aliasInPositive[alias] ? 'primary' : 'default'}
                                shape="circle"
                                size="small"
                                onClick={() => togglePositive(alias)}>
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </Button>
                        </Tooltip>
                        <Tooltip title="我不想要" mouseEnterDelay={0.75}>
                            <Button
                                type="default"
                                danger={aliasInNegative[alias] ? true : false}
                                shape="circle"
                                size="small"
                                onClick={() => toggleNegative(alias)}>
                                <FontAwesomeIcon icon={faThumbsDown} />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </li >
        )
    })

    return (
        <Card>
            <div className="imagecard-content">
                <div className="card-header flex-button-container">
                    <div className="tag-header">
                        <code className="tag-name large">{meta.key}</code>
                        {/* <TagPostCount tag={meta.key} /> */}
                    </div>
                    <div className="buttons">
                        <Tooltip open={copied} title="已复制到剪贴板">
                            <Button shape="circle" onClick={copy}>
                                <FontAwesomeIcon icon={faClipboard} />
                            </Button>
                        </Tooltip>
                        {false && meta.wikiURL && (
                            <a href={meta.wikiURL} target="_blank" rel="noreferrer">
                                <Tooltip title="Danbooru Wiki" mouseEnterDelay={0.75}>
                                    <Button shape="circle" type="primary" circle>
                                        <FontAwesomeIcon icon={faLink} />
                                    </Button>
                                </Tooltip>
                            </a>
                        )}
                        <Tooltip title="我想要" mouseEnterDelay={0.75}>
                            <Button
                                type={inPositive ? "primary" : "default"}
                                shape="circle"
                                onClick={() => togglePositive(meta.key)}
                            >
                                <FontAwesomeIcon icon={faThumbsUp} />
                            </Button>
                        </Tooltip>
                        <Tooltip title="我不想要" mouseEnterDelay={0.75}>
                            <Button
                                danger={inNegative ? true : false}
                                shape="circle"
                                onClick={() => toggleNegative(meta.key)}
                            >
                                <FontAwesomeIcon icon={faThumbsDown} />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                {meta.name && <div className="text name">{meta.name}</div>}
                {showCategory && <div className="text category">类别：{meta.category.join("/")}</div>}
                {meta.description && <div> {description} </div>}
                {meta.alias && (
                    <div>
                        <span className="text">别名：</span>
                        <ul>{aliasList}</ul>
                    </div>
                )}
            </div>
        </Card>
    );
}

export default TagItem