import { Button, Popover, Space, Tooltip } from "antd";
import { parseGenerationParameters } from "../../utils/util";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import useClipboard from "react-use-clipboard";
import { EventRegister } from "react-native-event-listeners";
import { useState } from "react";

function ModelMeta({ meta, children }) {
    const [open, setOpen] = useState(false);
      const handleOpenChange = (newOpen) => {
          setOpen(newOpen);
      };
    const res = parseGenerationParameters(meta);
    const [promptCopied, copyPrompt] = useClipboard(res.Prompt, { successDuration: 2000 });
    const [negativePromptCopied, copyNegativePrompt] = useClipboard(res["Negative prompt"], { successDuration: 2000 });
    const [metaCopied, copyMeta] = useClipboard(meta, { successDuration: 2000 });
    const handleApplyPrompt = () => {
        EventRegister.emit("applyPrompt", {
            prompt: res["Prompt"] ?? "",
            negative: res["Negative prompt"] ?? ""
        })
        setOpen(false)
    }
    return (
        <Popover
            placement="topRight"
            trigger={"click"}
            open={open}
            onOpenChange={handleOpenChange}
            content={
                <div className="model-meta">
                    <Space size={10} style={{ width: "100%" }} direction="vertical">
                        {res.Prompt && (
                            <Space direction="vertical" size={0} style={{ width: "100%" }}>
                                <div className="param-prompt">
                                    <div className="param-name">提示词</div>
                                    {promptCopied && <CheckOutlined />}
                                    {!promptCopied && (
                                        <Tooltip title="复制提示词">
                                            <CopyOutlined onClick={copyPrompt} />
                                        </Tooltip>
                                    )}
                                </div>
                                <pre>{res.Prompt}</pre>
                            </Space>
                        )}
                        {res["Negative prompt"] && (
                            <Space direction="vertical" size={0} style={{ width: "100%" }}>
                                <div className="param-prompt">
                                    <div className="param-name">反向提示词</div>
                                    {negativePromptCopied && <CheckOutlined />}
                                    {!negativePromptCopied && (
                                        <Tooltip title="复制反向提示词">
                                            <CopyOutlined onClick={copyNegativePrompt} />
                                        </Tooltip>
                                    )}
                                </div>
                                <pre>{res["Negative prompt"]}</pre>
                            </Space>
                        )}
                        {res["Model"] && (
                            <div className="param-item">
                                <div className="param-name">模型</div>
                                <code>{res["Model"]}</code>
                            </div>
                        )}
                        {res["Sampler"] && (
                            <div className="param-item">
                                <div className="param-name">Sampler</div>
                                <code>{res["Sampler"]}</code>
                            </div>
                        )}
                        <div className="param-grid">
                            {res["CFG scale"] && (
                                <div className="param-item">
                                    <div className="param-name">CFG scale</div>
                                    <code>{res["CFG scale"]}</code>
                                </div>
                            )}
                            {res["Steps"] && (
                                <div className="param-item">
                                    <div className="param-name">Steps</div>
                                    <code>{res["Steps"]}</code>
                                </div>
                            )}
                            {res["Seed"] && (
                                <div className="param-item">
                                    <div className="param-name">Seed</div>
                                    <code>{res["Seed"]}</code>
                                </div>
                            )}
                            {res["Clip skip"] && (
                                <div className="param-item">
                                    <div className="param-name">Clip skip</div>
                                    <code>{res["Clip skip"]}</code>
                                </div>
                            )}
                        </div>
                        <div>
                            <Button
                                style={{ width: "48%", fontSize: "12px" }}
                                size="small"
                                type="primary"
                                className="copy-meta"
                                onClick={copyMeta}
                            >
                                {!metaCopied && (
                                    <>
                                        <CopyOutlined /> 复制生成参数
                                    </>
                                )}
                                {metaCopied && <CheckOutlined />}
                            </Button>
                            <Button
                                onClick={handleApplyPrompt}
                                style={{ width: "48%", fontSize: "12px", marginLeft: "4%" }}
                                size="small"
                                type="primary"
                            >
                                应用提示词
                            </Button>
                        </div>
                    </Space>
                </div>
            }
        >
            {children}
        </Popover>
    );
}

export default ModelMeta;
