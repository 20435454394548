import { DownOutlined } from "@ant-design/icons";
import { useState } from "react";
import "./BasePanel.css"


function BasePanel({ children, className, collapsable, title, width = 300, maxHeight, collapsed = false}) {
    const [collapse, setCollapse] = useState(collapsed);

    return (
        <div className={(className ? className : "") + (collapse ? " panel-collapse" : "")} style={{ width: width }}>
            <div className={`panel-title ${title ? '' : 'hidden'}`}>
                {collapsable && <DownOutlined onClick={() => setCollapse(!collapse)} />}
                {title}
            </div>
            <div className="panel-body" style={{ maxHeight: maxHeight }}>
                {children}
            </div>
        </div>
    );
}

export default BasePanel;