import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";
import { group } from "../../../utils/util";

function useCkpt() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [ckpts, setCkpts] = useState([]);

    const emptyCkpt = {
        title: "请选择模型",
        category: [],
        name: "",
        preview: null,
        config: null,
    };

    const ckptMap = useMemo(() => {
        let m = new Map();
        ckpts.forEach((o) => {
            m.set(o.title, o)
        })
        return m
    }, [ckpts])

    const getCkpt = (title) => {
        const o = ckptMap.get(title)
        if (o === undefined) {
            return emptyCkpt
        }
        return o
    }

    const categorySize = useMemo(() => {
        const size = {};
        for (const m of ckpts) {
            const key = m.category.join("/");
            size[key] = (size[key] ?? 0) + 1;
        }
        return size;
    }, [ckpts]); 

    const categoryTotalSize = useMemo(() => {
        const size = {};
        for (const c in categorySize) {
            const cs = c.split("/")
            for (let i = 0; i < cs.length + 1; i++) {
                const p = cs.slice(0, i).join("/");
                if (p) {
                    size[p] = (size[p] ?? 0) + categorySize[c]
                }
            }
        }
        return size
    }, [categorySize])

    const categoryList = useMemo(() => {
        const dedup = {};
        const filtered = ckpts
            .map(({ category }) => category.join("/"))
            .filter((e) => !(dedup[e] = e in dedup))
            .sort()
            .map((e) => e.split("/"));
        return filtered;
    }, [ckpts]);

    const ckptsByCategory = useMemo(() => {
        return group(
            ckpts,
            (t) => t.category.join("/")
        );
    }, [ckpts]);

    const searchCategory = (category) => {
        return ckptsByCategory[category.join("/")] ?? [];
    }

    const load = async (refresh) => {
        const _refresh = typeof refresh === "boolean" && refresh ? true : false;
        const response = await fetch(`${API_URL}/tu/ckpt-models?refresh=${_refresh}`).then((resp) => resp.json());
        const items = response.map((o) => {
            o.thumb = o.preview;
            if (!o.preview && o.images?.length > 0) {
                o.thumb = o.images[0].url;
            }
            return {
                ...o,
                alias: o.config?.alias && o.config.alias !== "" ? o.config.alias : o.name,
                category: o.config?.category && o.config.category.length > 0 ? o.config.category : ["通用"],
                sdVersion: o.config?.base_model ?? "",
            };
        });
        setCkpts(items);
    };

    const refresh = () => load(true);

    useEffect(() => {
        load()
    }, [])



    return {
        getCkpt,
        API_URL,
        ckpts,
        categorySize,
        categoryTotalSize,
        categoryList,
        searchCategory,
        load,
        refresh,
    };
}

export const [useCkptStore] = createGlobalStore(useCkpt);
