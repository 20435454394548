import "./style/ModelView.scss"

import { Button, Form, Layout, Modal, Space, Table } from "antd"
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import Column from "antd/es/table/Column";
import { useMemo, useState } from "react";
import {
    EditOutlined,
    LeftOutlined,
    RightOutlined,
    InfoCircleOutlined,
    CopyOutlined,
    CheckOutlined,
} from "@ant-design/icons";
import ModelEdit from "./ModelEdit";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ModelMeta from "./ModelMeta";
import { useTagStore } from "../prompt-builder/store/tags";
import TextArea from "antd/es/input/TextArea";
import useClipboard from "react-use-clipboard";

function ModelView({ open, onCancel, model, type = 'lora' }) {

    const { toCt } = useTagStore();

    const infoDataSource = useMemo(() => {
        const dataSource = [];
        dataSource.push({
            key: 'name',
            row: "名称",
            value: model.name
        })
        if (model.sdVersion) {
            dataSource.push({
                key: 'base_model',
                row: "基础版本",
                value: model.sdVersion
            })
        }
        if (model.sd_model) {
            dataSource.push({
                key: 'sd_model',
                row: "基础模型",
                value: model.sd_model
            })
        }
        if (model.config?.trigger?.length > 0) {
            dataSource.push({
                key: 'trigger',
                row: "触发词",
                value: model.config.trigger.map((t, i) => {
                    return <span style={{cursor: 'default'}} className={`${type}-tag`} key={i}>{toCt(t)}</span>
                })
            });
        }
        if (type === 'lora') {
            dataSource.push({
                key: 'weight',
                row: "默认权重",
                value: model.weight
            })
        }
        if (model.resolution) {
            dataSource.push({
                key: 'resolution',
                row: '分辨率',
                value: model.resolution
            })
        }
        if (model.config?.time) {
            dataSource.push({
                key: 'time',
                row: "更新时间",
                value: model.config.time
            });
        }
        return dataSource
    }, [model, type])

    const images = useMemo(() => {
        const imgs = []
        if (model.images?.length === 0 && model.preview) {
            imgs.push({
                url: model.preview,
            });
        }
        if (model.images) {
            imgs.push(...model.images)
        }
        return imgs
    }, [model])

    const [editOpen, setEditOpen] = useState(false)

    const [previewOpen, setPreviewOpen] = useState(false);

    const mkRandomPrompts = () => {
        if (!model.training_dataset_tags) {
            return ""
        }
        let maxCount = null
        let res = []
        for (let [tag, count] of model.training_dataset_tags) {
            if (!maxCount) {
                maxCount = count
            }
            let v = Math.random() * maxCount
            if (count > v) {
                res.push(tag)
            }
        }
        return res.sort().join(", ")
    };

    const [randomPrompts, setRandomPrompts] = useState(mkRandomPrompts())
    const [randomPromptsCopied, copyRandomPrompts] = useClipboard(randomPrompts, { successDuration: 2000 });

    const updateRandomPrompts = () => {
        setRandomPrompts(mkRandomPrompts())
    }

    const [previewSlide, setPreviewSlide] = useState(0)
    const updatePreviewSlide = (n) => {
        let slide = previewSlide + n
        if (slide < 0) {
            slide = images.length - 1
        } else if (slide > images.length - 1) {
            slide = 0;
        }
        setPreviewSlide(slide)
    }

    return (
        <>
            <Modal
                centered
                maskStyle={{ background: "rgba(0, 0, 0, 0.5" }}
                width={1000}
                onCancel={onCancel}
                open={open}
                footer={null}
                title={model.alias}
                destroyOnClose={true}
            >
                <Layout style={{ background: "none", minHeight: 600 }}>
                    <Content style={{ marginRight: 10 }}>
                        {images.length > 0 && (
                            <CarouselProvider
                                naturalSlideWidth={300}
                                naturalSlideHeight={400}
                                totalSlides={images.length}
                                infinite={true}
                                visibleSlides={images.length > 1 ? 2 : 1}
                                step={images.length > 1 ? 2 : 1}
                                dragEnabled={false}
                            >
                                <Slider style={{ height: 400 }}>
                                    {images.map((o, i) => (
                                        <Slide key={i} index={i}>
                                            <div className="slide-item">
                                                <div className="slide-image">
                                                    {o.meta && o.meta !== "" && (
                                                        <ModelMeta meta={o.meta}>
                                                            <InfoCircleOutlined className="meta-btn" />
                                                        </ModelMeta>
                                                    )}
                                                    <img
                                                        onClick={() => {
                                                            setPreviewOpen(true);
                                                            setPreviewSlide(i);
                                                        }}
                                                        crossOrigin="anonymous"
                                                        alt={i}
                                                        src={`${process.env.REACT_APP_API_URL}${o.url}`}
                                                    />
                                                </div>
                                            </div>
                                        </Slide>
                                    ))}
                                </Slider>
                                {images.length > 2 && (
                                    <>
                                        <ButtonBack className="back-btn">
                                            <LeftOutlined />
                                        </ButtonBack>
                                        <ButtonNext className="next-btn">
                                            <RightOutlined />
                                        </ButtonNext>
                                    </>
                                )}
                            </CarouselProvider>
                        )}
                        <div className="model-description">
                            <div>{model.config?.description}</div>
                            {model.training_dataset_tags?.length > 0 && (
                                <div className="random-prompts" style={{ marginTop: 10 }}>
                                    <Form layout="vertical">
                                        <Form.Item label="随机提示词">
                                            <TextArea rows={6} value={randomPrompts}></TextArea>
                                            <div style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
                                                <Space>
                                                    <Button onClick={copyRandomPrompts}>
                                                        {!randomPromptsCopied && (
                                                            <>
                                                                <CopyOutlined /> 复制
                                                            </>
                                                        )}
                                                        {randomPromptsCopied && <CheckOutlined />}
                                                    </Button>
                                                    <Button type="primary" onClick={updateRandomPrompts}>
                                                        生成
                                                    </Button>
                                                </Space>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>
                            )}
                        </div>
                    </Content>
                    <Sider width={300} style={{ background: "none" }}>
                        <Table
                            className="model-detail-table"
                            bordered
                            title={() => (
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>详情</span>
                                    <EditOutlined onClick={() => setEditOpen(true)} />
                                </div>
                            )}
                            showHeader={false}
                            pagination={false}
                            size="small"
                            dataSource={infoDataSource}
                        >
                            <Column
                                width={80}
                                align="left"
                                dataIndex="row"
                                key="row"
                                className="model-detail-column"
                            ></Column>
                            <Column
                                dataIndex="value"
                                key="value"
                                render={(text) => <div style={{ wordBreak: "break-word" }}>{text}</div>}
                            ></Column>
                        </Table>
                    </Sider>
                </Layout>
            </Modal>
            <Modal
                className="preview-modal"
                open={previewOpen}
                width={"100%"}
                footer={null}
                onCancel={() => setPreviewOpen(false)}
                zIndex={99999}
                maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
                modalRender={(modal) => (
                    <>
                        {images.length > 1 && (
                            <>
                                <div className={`preview-back-btn`} onClick={() => updatePreviewSlide(-1)}>
                                    <LeftOutlined />
                                </div>
                                <div className={`preview-next-btn`} onClick={() => updatePreviewSlide(1)}>
                                    <RightOutlined />
                                </div>
                            </>
                        )}
                        {modal}
                    </>
                )}
            >
                <img
                    crossOrigin="anonymous"
                    alt="preview"
                    style={{
                        maxWidth: "80vw",
                        maxHeight: "80vh",
                    }}
                    src={`${process.env.REACT_APP_API_URL}${images[previewSlide]?.url}`}
                />
            </Modal>
            {open && (
                <ModelEdit open={editOpen} model={model} onCancel={() => setEditOpen(false)} type={type}></ModelEdit>
            )}
        </>
    );
}

export default ModelView