import * as React from 'react';
import { Form, Input, Slider, ColorPicker, Col, Row, Select, InputNumber, Button } from "antd";
import { useEffect, useState } from "react";
import BasePanel from "./BasePanel";
import { hexToRGB, RGB2hex } from "../utils/util"
import { textAlignment } from "../utils/defs"
import GenFont from '../components/gen-font/gen-font';
const { TextArea } = Input;


function TextPanel(props) {

    const [fonts, setFonts] = useState([]);

    const [text, setText] = useState("请输入文字");
    const [font, setFont] = useState("请选择字体");
    const [fontSize, setFontSize] = useState(80); // min={8} max={128}
    const [tracking, setTracking] = useState(0); // min={0} max={100}
    const [leading, setLeading] = useState(0); // min={0} max={100}
    const [textColor, setTextColor] = React.useState("#ffffffff");
    const [strokeWidth, setStrokeWidth] = useState(0);
    const [strokeColor, setStrokeColor] = React.useState("#000000f0");
    const [alignment, setAlignment] = useState(1);

    useEffect(() => {

        if (props.tool === "text") {

            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(text)>>>>>>:: ", nm, obj);

                if (nm.startsWith("tool::text-config-update")) {
                    let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
                    setFont(cfg.font);
                    setFontSize(cfg.font_size);
                    setAlignment(cfg.alignment);
                    setTracking(cfg.tracking);
                    setLeading(cfg.leading);
                    setTextColor(RGB2hex(cfg.color));
                    setStrokeWidth(cfg.stroke_width);
                    setStrokeColor(RGB2hex(cfg.stroke_color));
                    setText(cfg.text);
                }
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg);
                // setFont(cfg.font);
                setFontSize(cfg.font_size);
                setAlignment(cfg.alignment);
                setTracking(cfg.tracking);
                setLeading(cfg.leading);
                setTextColor(RGB2hex(cfg.color));
                setStrokeWidth(cfg.stroke_width);
                setStrokeColor(RGB2hex(cfg.stroke_color));
                setText(cfg.text);
            }
            updateText(text);
            handleRefreshFonts();

        }
    }, [props.tool])



    const handleRefreshFonts = () => {
        let flist = window.PSMan.getInstance().resourceManager().list("/psman");
        console.log(flist);

        // flist.filter
        flist = flist.filter((item) => (item.path.endsWith('.ttf') || item.path.endsWith('.TTF') 
        || item.path.endsWith('.otf') || item.path.endsWith('.OTF') 
        || item.path.endsWith('.ttc') || item.path.endsWith('.TTC')
        ));

        let fmap = flist.map((item, idx) => ({ key: idx, value: idx, label: item.name, path: item.path }));
        setFonts(fmap);
        console.log(fmap);
        if (fmap.length > 0 && font == "请选择字体") {
            console.log("dddddddddddddddd", font);
            setFont(fmap[0].label);
        }

        // if (flist.length > 0) {
        //     updateFont(flist[0].path)
        // }
    };

    const updateText = (val) => {
        if (val == null) return;
        setText(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ text: val });
    }

    const updateFont = (idx) => {
        console.log("updateFont", idx, fonts);
        let flist = fonts.filter((item) => (item.key == idx));
        if (flist.length == 1) {
            setFont(flist[0].name);
            window.PSMan?.getInstance().toolManager().current()?.setConfig({ font: flist[0].path });
        }
        // if (val == null) return;
        // setFont(val);
        // window.PSMan?.getInstance().toolManager().current()?.setConfig({ font: val });
    }

    const updateTracking = (val) => {
        if (val == null) return;
        setTracking(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ tracking: val });
    }

    const updateLeading = (val) => {
        if (val == null) return;
        setLeading(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ leading: val });
    }

    const updateFontSize = (val) => {
        if (val == null) return;
        setFontSize(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ font_size: val });
    }

    const updateStrokeWidth = (val) => {
        if (val == null) return;
        setStrokeWidth(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ stroke_width: val });
    }

    const updateColor = (val) => {
        setTextColor(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ color: hexToRGB(val) });
    }

    const updateStrokeColor = (val) => {
        setStrokeColor(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ stroke_color: hexToRGB(val) });
    }

    const alignment_onChange = (idx) => {
        setAlignment(idx);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ alignment: idx });
    };

    return (
        <BasePanel className={props.className} title={"文字:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}> 文本内容： </Col>
                        <Col span={18}>
                            <TextArea value={text} placeholder="请输入..." onChange={(e) => updateText(e.target.value)} />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}> 字体：</Col>
                        <Col span={18}>
                            <Select style={{ width: 225 }} options={fonts} value={font} onFocus={handleRefreshFonts}
                                onChange={updateFont} />
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={6}>
                            <GenFont>
                                <Button style={{marginTop: 4}} type='primary'>自定义字体</Button>
                            </GenFont>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}> 对齐：</Col>
                        <Col span={18}>
                            <Select value={alignment} options={textAlignment} onChange={alignment_onChange}></Select>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}>字间距：</Col>
                        <Col span={6}>
                            <InputNumber min={0} max={100} value={tracking} style={{ width: 50 }} onChange={(e) => updateTracking(e)} />
                        </Col>
                        <Col span={4}>行距：</Col>
                        <Col span={6}>
                            <InputNumber min={0} max={100} value={leading} style={{ width: 50 }} onChange={(e) => updateLeading(e)} />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}>字体大小：</Col>
                        <Col span={6}>
                            <InputNumber min={8} max={128} value={fontSize} style={{ width: 50 }} onChange={(e) => updateFontSize(e)} />
                        </Col>
                        <Col span={6}> 文字颜色：</Col>
                        <Col span={4}>
                            <ColorPicker value={textColor} onChange={(v) => updateColor(v.toHexString())} />
                        </Col>
                    </Row>
                </Form.Item>
                
                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}>描边宽度：</Col>
                        <Col span={6}>
                            <InputNumber min={0} max={48} value={strokeWidth} style={{ width: 50 }} onChange={(e) => updateStrokeWidth(e)} />
                        </Col>
                        <Col span={6}> 描边颜色：</Col>
                        <Col span={4}>
                            <ColorPicker value={strokeColor} onChange={(v) => updateStrokeColor(v.toHexString())} />
                        </Col>
                    </Row>
                </Form.Item>
            </Form>

        </BasePanel>
    );
}

export default TextPanel;
