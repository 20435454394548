import * as React from "react";
import { Form, Select, Row, Col, Slider, InputNumber, Space } from "antd";
import { useState } from "react";
import { blendModes } from "../../utils/defs";

function LayerStyleBlend({ config, setConfig }) {
    const [blendMode, setBlendMode] = useState(config?.layer_styles["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles["opacity"] ?? 100);
    const [fillOpacity, setFillOpacity] = useState(config?.layer_styles["fill_opacity"] ?? 100);

    const updateConfig = (c) => {

        let cfg = Object.assign({}, config);
        if (cfg.hasOwnProperty("layer_styles")) {
            cfg.layer_styles.blend = c.blend;
            cfg.layer_styles.opacity = c.opacity;
            cfg.layer_styles.fill_opacity = c.fill_opacity;
            setConfig(cfg);
        }
    };


    
    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const fillOpacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setFillOpacity(newValue);
        updateConfig({ fill_opacity: newValue });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber min={0} max={100} style={{ width: 60, marginLeft: 10 }} value={opacity} onChange={opacity_onChange} />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="填充不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={fillOpacity} onChange={fillOpacity_onChange} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber min={0} max={100} style={{ width: 60, marginLeft: 10 }} value={fillOpacity} onChange={fillOpacity_onChange} />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>


        </Form>
    );
}

export default React.memo(LayerStyleBlend);
