import { Button, Form, Slider, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";

function VibrancePanel(props) {

    const [vibrance, setVibrance] = useState(0);
    const [saturation, setSaturation] = useState(0);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-vibrance") {
            console.log("VibrancePanel: ", props);
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(vibrance)>>>>>>:: ", nm, obj);
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("VibrancePanel: init config:", cfg);
                setVibrance(cfg.vibrance);
                setSaturation(cfg.saturation);
            }
        }
    }, [props]);


    const vibrance_onChange = (newValue) => {
        if (newValue == null) { return; }
        setVibrance(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ vibrance: newValue });
    };

    const saturation_onChange = (newValue) => {
        if (newValue == null) { return; }
        setSaturation(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ saturation: newValue });
    };

    const reset_onClick = () => {
        setVibrance(0);
        setSaturation(0);
        window.PSMan?.getInstance().toolManager().current().setConfig({ vibrance: 0, saturation: 0 });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setVibrance(0);
        setSaturation(0);
        props.onApply();
    }


    return (
        <>
            <FormItem label="自然饱和度">
                <Slider min={-100} max={100} value={vibrance} onChange={vibrance_onChange} />
            </FormItem>
            <FormItem label="饱和度">
                <Slider min={-100} max={100} value={saturation} onChange={saturation_onChange} />
            </FormItem>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default VibrancePanel;