import { Button, Form, Slider, Select, Space, Switch } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { Tones } from "../../utils/defs";


function ColorBalancePanel(props) {

    const [tone, setTone] = useState(Tones[1].value);
    const [shadowRed, setShadowRed] = useState(0);
    const [shadowGreen, setShadowGreen] = useState(0);
    const [shadowBlue, setShadowBlue] = useState(0);
    const [midRed, setMidRed] = useState(0);
    const [midGreen, setMidGreen] = useState(0);
    const [midBlue, setMidBlue] = useState(0);
    const [highRed, setHighRed] = useState(0);
    const [highGreen, setHighGreen] = useState(0);
    const [highBlue, setHighBlue] = useState(0);
    const [preserveLum, setPreserveLum] = useState(true);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-color-balance") {

            console.log("ColorBalancePanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("ColorBalancePanel: init config:", cfg);
                setTone(cfg.tone);
                setShadowRed(cfg.shadow_red);
                setShadowGreen(cfg.shadow_green);
                setShadowBlue(cfg.shadow_blue);
                setMidRed(cfg.mid_red);
                setMidGreen(cfg.mid_green);
                setMidBlue(cfg.mid_blue);
                setHighRed(cfg.high_red);
                setHighGreen(cfg.high_green);
                setHighBlue(cfg.high_blue);
                setPreserveLum(cfg.preserve_lum);
            }
        }
    }, [props]);

    const tone_onChange = (idx) => {
        setTone(idx);

        window.PSMan?.getInstance().toolManager().current().setConfig({ tone: idx });
    };

    const shadowRed_onChange = (newValue) => {
        if (newValue == null) { return; }
        setShadowRed(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ shadow_red: newValue });
    };
    const shadowGreen_onChange = (newValue) => {
        if (newValue == null) { return; }
        setShadowGreen(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ shadow_green: newValue });
    };
    const shadowBlue_onChange = (newValue) => {
        if (newValue == null) { return; }
        setShadowBlue(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ shadow_blue: newValue });
    };

    const midRed_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMidRed(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ mid_red: newValue });
    };
    const midGreen_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMidGreen(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ mid_green: newValue });
    };
    const midBlue_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMidBlue(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ mid_blue: newValue });
    };

    const highRed_onChange = (newValue) => {
        if (newValue == null) { return; }
        setHighRed(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ high_red: newValue });
    };
    const highGreen_onChange = (newValue) => {
        if (newValue == null) { return; }
        setHighGreen(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ high_green: newValue });
    };
    const highBlue_onChange = (newValue) => {
        if (newValue == null) { return; }
        setHighBlue(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ high_blue: newValue });
    };

    const preserveLum_onChanged = (val) => {
        setPreserveLum(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ preserve_lum: val });
    }

    const reset_onClick = () => {
        setTone(Tones[1].value);
        setShadowRed(0); setShadowGreen(0); setShadowBlue(0);
        setMidRed(0); setMidGreen(0); setMidBlue(0);
        setHighRed(0); setHighGreen(0); setHighBlue(0);
        setPreserveLum(true);

        window.PSMan?.getInstance().toolManager().current().setConfig({
            tone: Tones[1].value,
            shadow_red: 0, shadow_green: 0, shadow_blue: 0,
            mid_red: 0, mid_green: 0, mid_blue: 0,
            high_red: 0, high_green: 0, high_blue: 0,
            preserve_lum: true
        });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setTone(Tones[1].value);
        setShadowRed(0); setShadowGreen(0); setShadowBlue(0);
        setMidRed(0); setMidGreen(0); setMidBlue(0);
        setHighRed(0); setHighGreen(0); setHighBlue(0);
        setPreserveLum(true);

        props.onApply();
    }


    return (
        <>
            <Form.Item label="色调">
                <Select value={tone} options={Tones} onChange={tone_onChange}></Select>
            </Form.Item>

            {tone == 0 && <>
                <FormItem label="青色/红色">
                    <Slider min={-100} max={100} value={shadowRed} onChange={shadowRed_onChange} />
                </FormItem>
                <FormItem label="洋红/绿色">
                    <Slider min={-100} max={100} value={shadowGreen} onChange={shadowGreen_onChange} />
                </FormItem>
                <FormItem label="黄色/蓝色">
                    <Slider min={-100} max={100} value={shadowBlue} onChange={shadowBlue_onChange} />
                </FormItem>
            </>}

            {tone == 1 && <>
                <FormItem label="青色/红色">
                    <Slider min={-100} max={100} value={midRed} onChange={midRed_onChange} />
                </FormItem>
                <FormItem label="洋红/绿色">
                    <Slider min={-100} max={100} value={midGreen} onChange={midGreen_onChange} />
                </FormItem>
                <FormItem label="黄色/蓝色">
                    <Slider min={-100} max={100} value={midBlue} onChange={midBlue_onChange} />
                </FormItem>
            </>}

            {tone == 2 && <>
                <FormItem label="青色/红色">
                    <Slider min={-100} max={100} value={highRed} onChange={highRed_onChange} />
                </FormItem>
                <FormItem label="洋红/绿色">
                    <Slider min={-100} max={100} value={highGreen} onChange={highGreen_onChange} />
                </FormItem>
                <FormItem label="黄色/蓝色">
                    <Slider min={-100} max={100} value={highBlue} onChange={highBlue_onChange} />
                </FormItem>
            </>}

            <Form.Item label="保留明度">
                <Switch checked={preserveLum} onChange={preserveLum_onChanged} />
            </Form.Item>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default ColorBalancePanel;