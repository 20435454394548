import { Outlet, Link } from "react-router-dom";

import styled from "styled-components";

const NavItem = styled.h1`
  font-size: 1.0em;
//   text-align: center;
  color: palevioletred;
`;


const Layout = () => {
    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <Link to="/"><NavItem>go Home</NavItem></Link>
                    </li>
                    <li>
                        <Link to="/testbed"><NavItem>go Testbed</NavItem></Link>
                    </li>
                    {/* <li>
            <Link to="/blogs">Blogs</Link>
          </li> */}
                    <li>
                        <Link to="/contact"><NavItem>go Contact</NavItem></Link>
                    </li>
                </ul>
            </nav>

            <Outlet />
        </div>
    )
};

export default Layout;