import {
    Button,
    Checkbox,
    Col,
    Form,
    Image,
    InputNumber,
    Radio,
    Row,
    Select,
    Slider,
    Space,
    Tooltip,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useControlNetStore } from "./store";
import { useEffect, useMemo, useState } from "react";
import { ThunderboltOutlined } from "@ant-design/icons";

function Unit({ unit, index, updateUnit, curlayer }) {
    const store = useControlNetStore();

    const [layers, setLayers] = useState([{ label: "当前图层", value: -1 }]);
    // const [refLayer, setRefLayer] = useState(null);


    useEffect(() => {

        console.log("XXXXXXXXXXXXXXXX ", curlayer, unit);

        let ls = window.PSMan.getInstance()
            .layerManager()
            .state()
            .layers//.filter((l) => l.vis)
            .map((l) => ({ label: l.name, value: l.id }));
        // ls.unshift({ label: "当前图层", value: -1 });
        setLayers(ls);
        if (unit.layer_id < 0) {
            if (curlayer) {
                // setRefLayer([curlayer[0], curlayer[1]]);
                unit.layer_id = curlayer[0];
                unit.layer_name = curlayer[1];
                updateUnit(unit, index);
            }
        } else {
            // setRefLayer([curlayer[0], curlayer[1]]);
 
            // setRefLayer([unit.layer_id, curlayer[1]]);
        }

    }, [curlayer, unit.layer_id]);


    const moduleOptions = useMemo(() => {
        if (store.controlTypes[unit.type]) {
            return store.controlTypes[unit.type]["module_list"].map((m) => ({ label: m, value: m }));
        }
        return [];
    }, [unit.type, store.controlTypes]);

    const defaultModule = (type) => {
        if (store.controlTypes[type]) {
            return store.controlTypes[type]["default_option"];
        }
        return "none";
    };

    const modelOptions = useMemo(() => {
        if (store.controlTypes[unit.type]) {
            return store.controlTypes[unit.type]["model_list"].map((m) => ({ label: m, value: m }));
        }
        return [];
    }, [unit.type, store.controlTypes]);

    const defaultModel = (type) => {
        if (store.controlTypes[type]) {
            return store.controlTypes[type]["default_model"];
        }
        return "None";
    };

    const sliders = useMemo(() => {
        if (store.moduleDetail[unit.module]) {
            return store.moduleDetail[unit.module]["sliders"];
        }
        return [];
    }, [unit.module, store.moduleDetail]);

    const layerChange = (value) => {
        console.log("onLayerChange ..... ", value);
        updateRefLayer(value);

        // window.PSMan.getInstance().toolManager().current().sendAction("selected-image", {"id": value});
    };

    const updateRefLayer = (layerid) => {
        if (layerid < 0) {
            let ls = layers.filter((l) => l.value == curlayer[0]);
            layerid = curlayer[0];
            // setRefLayer([layerid, ls[0].label]);
            unit.layer_id = layerid;
            unit.layer_name = ls[0].label;
            updateUnit(unit, index);
        } else {
            let ls = layers.filter((l) => l.value == layerid);
            // setRefLayer([layerid, ls[0].label]);
            unit.layer_id = layerid;
            unit.layer_name = ls[0].label;
            updateUnit(unit, index);
        }
    }

    const listLayers = (value) => {
        let ls = window.PSMan.getInstance()
            .layerManager()
            .state()
            .layers//.filter((l) => l.vis)
            .map((l) => ({ label: l.name, value: l.id }));
        // ls.unshift({ label: "当前图层", value: -1 });
        setLayers(ls);
    }

    const updatePreprocessValues = () => {
        let sliders = [];
        if (store.moduleDetail[unit.module]) {
            sliders = store.moduleDetail[unit.module]["sliders"];
        }
        unit.processor_res = sliders[0] ? sliders[0].value : 512;
        unit.threshold_a = sliders[1] ? sliders[1].value : 64;
        unit.threshold_b = sliders[2] ? sliders[2].value : 64;
    };

    const controlTypeChange = (value) => {
        unit.type = value;
        unit.module = defaultModule(value);
        unit.model = defaultModel(value);
        updatePreprocessValues();
        setPreprocessOutput("");
        updateUnit(unit, index);
    };

    const moduleChange = (value) => {
        unit.module = value;
        updatePreprocessValues();
        setPreprocessOutput("");
        updateUnit(unit, index);
    };

    const modelChange = (value) => {
        unit.model = value;
        updateUnit(unit, index);
    };

    const weightChange = (value) => {
        unit.weight = value;
        updateUnit(unit, index);
    };

    const startChange = (value) => {
        unit.guidance_start = value;
        updateUnit(unit, index);
    };

    const endChange = (value) => {
        unit.guidance_end = value;
        updateUnit(unit, index);
    };
    const guidanceChange = (value) => {
        unit.guidance_start = value[0];
        unit.guidance_end = value[1];
        updateUnit(unit, index);
    };

    const resChange = (value) => {
        unit.processor_res = value;
        setPreprocessOutput("");
        updateUnit(unit, index);
    };

    const threAChange = (value) => {
        unit.threshold_a = value;
        setPreprocessOutput("");
        updateUnit(unit, index);
    };

    const threBChange = (value) => {
        unit.threshold_b = value;
        setPreprocessOutput("");
        updateUnit(unit, index);
    };

    const pixelChange = (value) => {
        unit.pixel_perfect = value;
        updateUnit(unit, index);
    };

    const controlModeChange = (value) => {
        unit.control_mode = value;
        updateUnit(unit, index);
    };

    const resizeModeChange = (value) => {
        unit.resize_mode = value;
        updateUnit(unit, index);
    };

    const newImageLayer = (b64image, rect) => {
        console.log("XXXX newImageLayer()  : ", unit);
        window.PSMan.getInstance().layerManager().newBase64Image("" + unit.layer_name + ":" + unit.type, b64image, rect);
    }

    const [preprocessOutput, setPreprocessOutput] = useState("");
    const [preprocessOutputRect, setPreprocessOutputRect] = useState(null);
    const [preprocessing, setPreprocessing] = useState(false);

    const runPreprocess = () => {
        console.log("XXXX runPreprocess()  : ", unit);
        const imagess = window.PSMan.getInstance().toolManager().current().getState("selected-image", { "id": unit.layer_id });
        console.log("CUR IMAGE , ", imagess);
        // const image = ""; //
        const image = imagess.image;
        const imrect = imagess.rect;
        setPreprocessOutputRect(imrect);

        if (unit.module === "none") {
            setPreprocessOutput(image);
            return;
        }
        setPreprocessing(true);
        fetch(`${process.env.REACT_APP_API_URL}/controlnet/detect`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                controlnet_input_images: [image],
                controlnet_module: unit.module,
                controlnet_processor_res: unit.processor_res,
                controlnet_threshold_a: unit.threshold_a,
                controlnet_threshold_b: unit.threshold_b,
            }),
        })
            .then((res) => {
                if (res.status !== 200) {
                    console.log(res.statusText);
                    return;
                }
                res.json().then((data) => {
                    if (data["images"].length > 0 && data["images"][0]) {
                        setPreprocessOutput(`data:image/png;base64,${data["images"][0]}`);
                    } else {
                        setPreprocessOutput("");
                    }
                });
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setPreprocessing(false);
            });
    };

    return (
        <div>
            <Form layout="vertical" size="small" style={{ padding: "0 10px" }}>

                <FormItem label="控制类型">
                    <Select onChange={controlTypeChange} value={unit.type} options={store.controlTypeOptions} />
                </FormItem>
                <FormItem label="参考图层">
                    <Select onDropdownVisibleChange={listLayers} onChange={layerChange} value={unit.layer_id} options={layers} />
                </FormItem>
                <FormItem label="预处理器">
                    <Row gutter={10}>
                        <Col span={22}>
                            <Select onChange={moduleChange} value={unit.module} options={moduleOptions} />
                        </Col>
                        <Col span={1}>
                            <Tooltip title="预处理">
                                <Button
                                    loading={preprocessing}
                                    onClick={runPreprocess}
                                    type="primary"
                                    icon={<ThunderboltOutlined />}
                                ></Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </FormItem>

                {preprocessOutput && (
                    <FormItem>
                        <Row>
                            <Image src={preprocessOutput} width={200} height={200} object-fit="contain" />
                        </Row>
                        <Row>
                            <Button
                                onClick={() => newImageLayer(preprocessOutput, preprocessOutputRect)}
                                type="primary"
                            >
                                创建图层
                            </Button>
                        </Row>
                    </FormItem>
                )}
                {sliders[0] && (
                    <FormItem
                        label={
                            <Space>
                                <span>{sliders[0].name}</span>
                                <InputNumber
                                    step={sliders[0].step ?? 1}
                                    value={unit.processor_res}
                                    min={sliders[0].min}
                                    max={sliders[0].max}
                                    onChange={resChange}
                                    style={{ width: 80 }}
                                />
                            </Space>
                        }
                    >
                        <Slider
                            step={sliders[0].step ?? 1}
                            value={unit.processor_res}
                            min={sliders[0].min}
                            max={sliders[0].max}
                            onChange={resChange}
                        />
                    </FormItem>
                )}
                {sliders[1] && (
                    <FormItem
                        label={
                            <Space>
                                <span>{sliders[1].name}</span>
                                <InputNumber
                                    step={sliders[1].step ?? 1}
                                    value={unit.threshold_a}
                                    min={sliders[1].min}
                                    max={sliders[1].max}
                                    onChange={threAChange}
                                    style={{ width: 80 }}
                                />
                            </Space>
                        }
                    >
                        <Slider
                            step={sliders[1].step ?? 1}
                            value={unit.threshold_a}
                            min={sliders[1].min}
                            max={sliders[1].max}
                            onChange={threAChange}
                        />
                    </FormItem>
                )}
                {sliders[2] && (
                    <FormItem
                        label={
                            <Space>
                                <span>{sliders[2].name}</span>
                                <InputNumber
                                    step={sliders[2].step ?? 1}
                                    value={unit.threshold_b}
                                    min={sliders[2].min}
                                    max={sliders[2].max}
                                    onChange={threBChange}
                                    style={{ width: 80 }}
                                />
                            </Space>
                        }
                    >
                        <Slider
                            step={sliders[2].step ?? 1}
                            value={unit.threshold_b}
                            min={sliders[2].min}
                            max={sliders[2].max}
                            onChange={threBChange}
                        />
                    </FormItem>
                )}

                <FormItem label="ControlNet模型">
                    <Select onChange={modelChange} value={unit.model} options={modelOptions} />
                </FormItem>
                <FormItem>
                    <Row style={{ paddingTop: 10 }}>
                        <Col span={8}>
                            <Space>
                                <span>控制权重</span>
                                <InputNumber
                                    step={0.05}
                                    min={0}
                                    max={2}
                                    value={unit.weight}
                                    onChange={weightChange}
                                    style={{ width: 60 }}
                                />
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Space>
                                <span>引导介入时机</span>
                                <InputNumber
                                    step={0.01}
                                    min={0}
                                    max={1}
                                    value={unit.guidance_start}
                                    onChange={startChange}
                                    style={{ width: 60 }}
                                />
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Space>
                                <span>引导退出时机</span>
                                <InputNumber
                                    step={0.01}
                                    min={0}
                                    max={1}
                                    value={unit.guidance_end}
                                    onChange={endChange}
                                    style={{ width: 60 }}
                                />
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Slider step={0.05} min={0} max={2} value={unit.weight} onChange={weightChange} />
                        </Col>
                        <Col span={16}>
                            <Slider step={0.01} min={0} max={1} range value={[unit.guidance_start, unit.guidance_end]} onChange={guidanceChange} />
                        </Col>
                        {/* <Col span={8}>
                            <Slider step={0.01} min={0} max={1} value={unit.guidance_start} onChange={startChange} />
                        </Col>
                        <Col span={8}>
                            <Slider step={0.01} min={0} max={1} value={unit.guidance_end} onChange={endChange} />
                        </Col> */}
                    </Row>
                </FormItem>

                <FormItem label="控制模式">
                    <Radio.Group
                        value={unit.control_mode}
                        onChange={({ target: { value } }) => controlModeChange(value)}
                    >
                        <Radio value={0}>平衡</Radio>
                        <Radio value={1}>提示词优先</Radio>
                        <Radio value={2}>ControlNet优先</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem label="缩放模式">
                    <Radio.Group value={unit.resize_mode} onChange={({ target: { value } }) => resizeModeChange(value)}>
                        <Radio value={0}>仅缩放</Radio>
                        <Radio value={1}>裁剪后缩放</Radio>
                        <Radio value={2}>缩放后填充空白</Radio>
                    </Radio.Group>
                </FormItem>
                <FormItem>
                    <Checkbox checked={unit.pixel_perfect} onChange={(e) => pixelChange(e.target.checked)}>
                        完美像素模式
                    </Checkbox>
                </FormItem>
            </Form>
        </div>
    );
}

export default Unit;
