import "./style/PercentageWeightIdentifier.scss";

import { useMemo, useRef, useState } from "react";
import Decimal from "decimal.js-light";
import { InputNumber } from "antd";

function PercentageWeightIdentifier({ weight, updateWeight }) {
    const boundWeight = useMemo(() => weight.times(100).toDecimalPlaces(1).toNumber(), [weight]);

    const color = "primary";
    const computedWeight = useMemo(() => boundWeight?.toFixed(1), [boundWeight]);

    const [oldWeight, setOldWeight] = useState(weight);
    const [editMode, setEditMode] = useState(false);

    const inputBox = useRef(null);

    function turnOnEditMode() {
        setOldWeight(new Decimal(weight));
        setEditMode(true);
        inputBox.current?.focus();
    }

    function cancelEditMode() {
        updateWeight(oldWeight);
        setEditMode(false);
    }

    const handleKeyUp = (e) => {
        if (e.key === "Enter") {
            setEditMode(false);
        }
    };

    return (
        <>
            <span className={`nowrap ${color}`} onDoubleClick={turnOnEditMode}>
                <span className={editMode ? "hidden" : ""}>{computedWeight}%</span>
                <InputNumber
                    value={boundWeight}
                    onChange={(v) => updateWeight(new Decimal(v).div(100))}
                    size="small"
                    min={0}
                    max={100}
                    step={0.1}
                    onBlur={() => setEditMode(false)}
                    onKeyUp={handleKeyUp}
                    ref={inputBox}
                    className={`edit ${editMode ? "" : "hidden"}`}
                />
            </span>
        </>
    );
}

export default PercentageWeightIdentifier;
