import * as React from 'react';

import { Form, Slider, Radio, Switch } from "antd";
import BasePanel from './BasePanel';

function EraserPanel(props) {

    const [penRadius, setPenRadius] = React.useState(8);
    const [blurSize, setBlurSize] = React.useState(0);

    const [erase, setErase] = React.useState("rect-eraser");
    const [snapping, setSnapping] = React.useState(true);

    const changeTool = () => {
        if (props.tool !== "eraser") {
            return;
        }

        window.PSMan?.getInstance().toolManager().use(erase);
        let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
        console.log("CHANGE TOOL ... ", props.tool, props.subTool, erase, cfg);


        if (erase === "pen-eraser") {
            if (cfg) {
                setPenRadius(cfg.radius)
                setBlurSize(cfg.blur_size)
            }
        } else if (erase === "rect-eraser") {
            if (cfg) {
                setSnapping(cfg.snapping);
                setBlurSize(cfg.blur_size)
            }
        }
    }

    React.useEffect(() => {
        if (props.tool === "eraser") {
            changeTool()

            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(eraser)>>>>>>:: ", nm, obj);
    
                if (nm == "tool::pen-eraser-config-update") {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    console.log("config: ", config);
                    setBlurSize(config.blur_size);
                    setPenRadius(config.radius);
                }
                if (nm == "tool::rect-eraser-config-update") {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    console.log("config: ", config);
                    setSnapping(config.snapping);
                    setBlurSize(config.blur_size);
                }
            }
        }
    }, [props.tool])


    React.useEffect(() => {
        if (props.tool === "eraser") {
            changeTool()
        }
    }, [props.subTool])


    React.useEffect(() => {
        changeTool()
    }, [erase]);

    // React.useEffect(() => {
    //     window.PSMan?.getInstance().toolManager().current()?.setConfig({ radius: penRadius });
    // }, [penRadius]);
    const updateRadius = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ radius: val });
        setPenRadius(val);
    }

    const updateSnapping = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ snapping: val });
        setSnapping(val);
    }

    const updateBlur = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ blur_size: val });
        setBlurSize(val);
    }

    // const updateBlockRadius = (val) => {
    //     window.PSMan?.getInstance().toolManager().current()?.setConfig({ radius: val });
    // }

    // React.useEffect(() => {
    //     window.PSMan?.getInstance().toolManager().current()?.setConfig({ radius: blockRadius });
    // }, [blockRadius]);


    return (
        <BasePanel className={props.className} title={"橡皮擦:"}>
            <Form size="small" labelCol={{ span: 8 }} labelAlign="left">
                <Form.Item>
                    <Radio.Group onChange={(e) => setErase(e.target.value)} value={erase}>
                        <Radio value={"rect-eraser"}>选框</Radio>
                        <Radio value={"pen-eraser"}>画笔</Radio>
                        {/* <Radio value={"block-eraser"}>块</Radio> */}
                    </Radio.Group>
                </Form.Item>

                <div style={{ paddingTop: "20px" }} />

                <Form.Item label="对齐到网格" className={erase === "pen-eraser" ? "hidden" : ""}>
                    <Switch checked={snapping} onChange={updateSnapping} />
                </Form.Item>

                <Form.Item label="大小" className={erase === "pen-eraser" ? "" : "hidden"}>
                    <Slider value={penRadius} min={8} max={40} onChange={updateRadius} />
                </Form.Item>

                <Form.Item label="模糊">
                    <Slider value={blurSize} min={0} max={32} onChange={updateBlur} />
                </Form.Item>

            </Form>
        </BasePanel>
    );
}





export default EraserPanel;