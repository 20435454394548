import "./style/DreamBuilder.scss"

import { Button, Divider, Input, Layout, List, Modal, Select, Space } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useMemo, useRef, useState } from "react";
import { PlusSquareOutlined, EditOutlined, SaveOutlined, DeleteOutlined, ImportOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useCkptStore } from "./store/ckpt";
import CkptSelect from "./CkptSelect";
import CkptItem from "./CkptItem";
import LoraSelect from "./LoraSelect";
import PromptBuilder from "../prompt-builder/PromptBuilder";
import SelectedLoraItem from "./SelectedLoraItem";
import _ from "lodash"
import { useHistoryStore } from "./store/history";
import { useTagStore } from "../prompt-builder/store/tags";
import { EventRegister } from "react-native-event-listeners";
import Fooocus from "./Fooocus/Fooocus";


function DreamBuilder({
    open,
    setOpen,
    ckptTitle,
    positiveTags,
    setPositiveTags,
    negativeTags,
    setNegativeTags,
    updateDreamPrompt,
}) {
    // ckpt
    const ckptStore = useCkptStore();
    const [newCkptTitle, setNewCkptTitle] = useState(ckptTitle);
    const ckpt = useMemo(() => ckptStore.getCkpt(newCkptTitle), [newCkptTitle, ckptStore]);
    const currentCkptTags = useMemo(() => ckpt.config?.trigger ?? [], [ckpt]);
    const [newCkptTags, setNewCkptTags] = useState([]);
    const [ckptSelectOpen, setCkptSelectOpen] = useState(false);

    const addCkptTag = (t) => {
        if (newCkptTags.includes(t)) {
            // delCkptTag(t);
            return;
        }
        setNewCkptTags([...newCkptTags, t]);
    };

    const delCkptTag = (t) => {
        const idx = newCkptTags.indexOf(t);
        if (idx === -1) {
            return;
        }
        newCkptTags.splice(idx, 1);
        setNewCkptTags([...newCkptTags]);
    };

    // lora

    const [newLoras, setNewLoras] = useState([]);
    const [loraSelectOpen, setLoraSelectOpen] = useState(false);

    const selectedLoras = useMemo(() => newLoras.map((l) => l.title), [newLoras]);

    const addNewLora = (lora) => {
        if (selectedLoras.includes(lora.title)) {
            return;
        }
        const add =  {...lora, tags: lora.config?.trigger?.length > 0 ? [lora.config.trigger[0]] : []}
        setNewLoras([...newLoras, add]);
    };

    const delNewLora = (lora) => {
        const i = newLoras.findIndex((n) => n.title === lora.title);
        if (i === -1) {
            return;
        }
        newLoras.splice(i, 1);
        setNewLoras([...newLoras]);
    };

    const addLoraTag = (lora, tag) => {
        if (lora.tags.includes(tag)) {
            return;
        }
        lora.tags.push(tag);
        setNewLoras([...newLoras]);
    };

    const delLoraTag = (lora, tag) => {
        const idx = lora.tags.indexOf(tag);
        if (idx === -1) {
            return;
        }
        lora.tags.splice(idx, 1);
        setNewLoras([...newLoras]);
    };

    useEffect(() => {
        const tags = ckpt.config?.trigger?.length > 0 ? [ckpt.config.trigger[0]] : [];
        setNewCkptTags(tags);
        // check sd version
        if (newLoras.length > 0) {
            const loras = newLoras.filter((l) => l.sdVersion === ckpt.sdVersion);
            setNewLoras(loras);
        }
    }, [ckpt]);

    // tags

    const [newPositiveTags, setNewPositiveTags] = useState(positiveTags);
    const [newNegativeTags, setNewNegativeTags] = useState(negativeTags);
    const [promptBuilderOpen, setPromptBuilderOpen] = useState(false);

    // ---

    const [modelLoading, setModelLoading] = useState(false)

    const prevData = useRef({
        ckptTags: [],
        loras: [],
    })

    const handleClose = () => {
        setModelLoading(false)
    };

    const handleOpenChange = (o) => {
        setNewCkptTitle(ckptTitle);
        setNewPositiveTags(positiveTags)
        setNewNegativeTags(negativeTags)
        setNewCkptTags(_.cloneDeep(prevData.current.ckptTags))
        setNewLoras(_.cloneDeep(prevData.current.loras));
        setPtab(0)
    };

    useEffect(() => {
        if (modelLoading) {
            setModelLoading(false)
            sendPrompt()
        }
    }, [ckptTitle])

    const sendPrompt = () => {
        setPositiveTags(newPositiveTags);
        setNegativeTags(newNegativeTags);

        const loraTags = [];
        const loraText = [];
        newLoras.forEach((l) => {
            loraTags.push(...l.tags);
            loraText.push(`<lora:${l.name}:${l.weight}>`);
        });

        const tags = [...newCkptTags, ...loraTags, ...loraText];
        const prompt = tags.length > 0 ? `, ${tags.join(", ")}` : "";

        updateDreamPrompt({
            positive: newPositiveTags + prompt,
            negative: "nsfw, nude, nake, " + newNegativeTags,
        });

        prevData.current.ckptTags = _.cloneDeep(newCkptTags);
        prevData.current.loras = _.cloneDeep(newLoras);

        setOpen(false);
    }

    const handleConfirm = () => {
        if (newCkptTitle !== ckptTitle) {
            setModelLoading(true);
            window.PSMan.getInstance().toolManager().current().sendAction("set-model", newCkptTitle);
            return
        }
        sendPrompt()
    };

    const historyStore = useHistoryStore();
    const [ptab, setPtab] = useState(0)
    const ptabs = ["提示信息", "历史提示"]
    const [openSaveModal, setOpenSaveModal] = useState(false)
    const hisotryName = useRef(null)

    const { toCt } = useTagStore()

    const promptTextarea = useRef(null)

    useEffect(() => {
        if (open && promptTextarea.current) {
            setTimeout(() => {
                promptTextarea.current.focus()
            }, 0)
        }
    }, [open])

    useEffect(() => {
        const eventListener = EventRegister.addEventListener("applyPrompt", ({prompt, negative}) => {
            setNewPositiveTags(prompt.replace(/<[^>]+>/, ""))
            setNewNegativeTags(negative)
        })
        return () => {
            EventRegister.removeEventListener(eventListener)
        }
    }, [])

    return (
        <Modal
            maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
            centered
            open={open}
            width={1200}
            onCancel={() => setOpen(false)}
            footer={null}
            afterClose={handleClose}
            afterOpenChange={handleOpenChange}
            closeIcon={<span className="hidden-close"></span>}
            className="no-padding-modal"
        >
            <Layout style={{ background: "none" }}>
                <Sider style={{ background: "none", marginRight: "1px" }}>
                    <div className="dr-title">基础风格 </div>
                    <div className="side-ckpt">
                        <CkptItem ckpt={ckpt} onImgClick={() => setCkptSelectOpen(true)} changing={modelLoading} />
                        <div className="side-ckpt-select" onClick={() => setCkptSelectOpen(true)}>
                            <Select
                                open={false}
                                value={ckpt?.title}
                                style={{ width: "100%" }}
                                size="small"
                                defaultValue={"请选择模型"}
                            ></Select>
                        </div>
                    </div>
                    <div className="side-ckpt-tags">
                        <div className="dr-title">触发参考词</div>
                        <div className="side-ckpt-tags-wrapper no-bg-scrollbar">
                            <div className="side-ckpt-tags-list">
                                {currentCkptTags.map(
                                    (t, i) =>
                                        t !== "nsfw" && (
                                            <span
                                                onClick={() => addCkptTag(t)}
                                                key={i}
                                                className={`ckpt-tag ${newCkptTags.includes(t) ? "active" : ""}`}
                                            >
                                                {toCt(t)}
                                            </span>
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </Sider>
                <Layout style={{ background: "none" }}>
                    <Header style={{ background: "none", padding: 0, height: 229, lineHeight: "unset" }}>
                        <div className="dr-title">
                            <span onClick={() => setLoraSelectOpen(true)}>
                                画笔风格
                                <Button type="text" size="small">
                                    <PlusSquareOutlined />
                                </Button>
                            </span>
                        </div>
                        <div className="top-lora-wrapper no-bg-scrollbar">
                            <div className="top-lora-list">
                                {newLoras.map((item, i) => (
                                    <div key={i} className="top-lora-item">
                                        <SelectedLoraItem
                                            newLoras={newLoras}
                                            setNewLoras={setNewLoras}
                                            delNewLora={delNewLora}
                                            item={item}
                                        />
                                        {item.config?.trigger?.length > 0 && (
                                            <div className="top-lora-item-tags no-bg-scrollbar">
                                                {item.config?.trigger.map((t, i) => (
                                                    <span
                                                        onClick={() => addLoraTag(item, t)}
                                                        key={i}
                                                        className={`lora-tag`}
                                                    >
                                                        {toCt(t)}
                                                    </span>
                                                ))}
                                                {/* {item.training_dataset_tags?.slice(0, 10).map((t, i) => (
                                                    <span
                                                        onClick={() => addLoraTag(item, t[0])}
                                                        key={i}
                                                        className={`lora-tag`}
                                                    >
                                                        {toCt(t[0])}
                                                    </span>
                                                ))} */}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="top-lora-item add-lora" onClick={() => setLoraSelectOpen(true)}>
                                    <PlusSquareOutlined />
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Layout style={{ background: "none", marginTop: 1 }}>
                        <Content style={{ background: "none", padding: 0, marginRight: 1 }}>
                            <div className="dr-title">
                                <Space>
                                    {ptabs.map((t, i) => (
                                        <span
                                            className={`ptab ${i === ptab ? "active" : ""}`}
                                            key={i}
                                            onClick={() => setPtab(i)}
                                        >
                                            {t}
                                        </span>
                                    ))}
                                </Space>
                                <Space>
                                    <Fooocus />
                                    <Divider type="vertical" />
                                    <Button
                                        onClick={() => {
                                            setPtab(0);
                                            setPromptBuilderOpen(true);
                                        }}
                                        type="text"
                                        size="small"
                                    >
                                        <EditOutlined />
                                        编辑
                                    </Button>
                                    <Button onClick={() => setOpenSaveModal(true)} type="text" size="small">
                                        <SaveOutlined />
                                        保存
                                    </Button>
                                </Space>
                            </div>
                            <div className="dr-main no-bg-scrollbar">
                                {ptab === 0 && (
                                    <div style={{ height: "100%" }}>
                                        <TextArea
                                            ref={promptTextarea}
                                            onChange={(e) => setNewPositiveTags(e.target.value)}
                                            value={newPositiveTags}
                                            placeholder="提示词"
                                        ></TextArea>
                                        <TextArea
                                            onChange={(e) => setNewNegativeTags(e.target.value)}
                                            value={newNegativeTags}
                                            placeholder="反向提示词"
                                        ></TextArea>
                                    </div>
                                )}
                                {ptab === 1 && (
                                    <div style={{ background: "#363636", margin: "8px 0 0px 8px" }}>
                                        <List
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={historyStore.historyList}
                                            renderItem={(item) => (
                                                <List.Item
                                                    key={item.name}
                                                    actions={[
                                                        <Button
                                                            onClick={() => historyStore.delHistory(item.name)}
                                                            type="text"
                                                            size="small"
                                                        >
                                                            <DeleteOutlined /> 删除
                                                        </Button>,
                                                        <Button
                                                            onClick={() => {
                                                                setNewPositiveTags(item.positive);
                                                                setNewNegativeTags(item.negative);
                                                                setPtab(0);
                                                            }}
                                                            type="text"
                                                            size="small"
                                                        >
                                                            <ImportOutlined /> 导入
                                                        </Button>,
                                                        <span>{item.time ? item.time : "-"}</span>,
                                                    ]}
                                                >
                                                    <List.Item.Meta title={item.name} />
                                                    <div style={{ marginBottom: "10px" }}>提示词：{item.positive}</div>
                                                    <div>反向提示词：{item.negative}</div>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="dr-footer">
                                <Button disabled={modelLoading} onClick={handleConfirm} type="primary">
                                    确定
                                </Button>
                                <Button type="default" className="cancel-btn" onClick={() => setOpen(false)}>
                                    取消
                                </Button>
                            </div>
                        </Content>
                        <Sider style={{ background: "none" }}>
                            <div className="dr-title">已选触发参考词</div>
                            <div className="dr-tags-wrapper no-bg-scrollbar">
                                <div>
                                    {newCkptTags.map((t, i) => (
                                        <span
                                            onClick={() => delCkptTag(t)}
                                            key={i}
                                            className={`ckpt-tag ${newCkptTags.includes(t) ? "active" : ""}`}
                                        >
                                            {toCt(t)}
                                        </span>
                                    ))}
                                </div>
                                <div>
                                    {newLoras.map((l) =>
                                        l.tags.map((t, i) => (
                                            <span key={i} onClick={() => delLoraTag(l, t)} className={`lora-tag`}>
                                                {toCt(t)}
                                            </span>
                                        ))
                                    )}
                                </div>
                            </div>
                        </Sider>
                    </Layout>
                </Layout>
            </Layout>
            <CkptSelect
                open={ckptSelectOpen}
                setOpen={setCkptSelectOpen}
                newCkpt={newCkptTitle}
                setNewCkpt={setNewCkptTitle}
            />
            <LoraSelect
                open={loraSelectOpen}
                setOpen={setLoraSelectOpen}
                addNewLora={addNewLora}
                delNewLora={delNewLora}
                selectedLoras={selectedLoras}
                ckpt={ckpt}
            />
            <PromptBuilder
                positiveTags={newPositiveTags}
                negativeTags={newNegativeTags}
                open={promptBuilderOpen}
                updateTag={(o) => {
                    setNewPositiveTags(o.positiveTags);
                    setNewNegativeTags(o.negativeTags);
                }}
                setOpen={setPromptBuilderOpen}
            ></PromptBuilder>
            <Modal
                title="保存提示"
                okText="确认"
                cancelText="取消"
                maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
                centered
                onCancel={() => setOpenSaveModal(false)}
                open={openSaveModal}
                onOk={() => {
                    const name = hisotryName.current.input.value.trim();
                    if (name) {
                        historyStore.addHistory(name, newPositiveTags, newNegativeTags);
                        setOpenSaveModal(false);
                    }
                }}
                afterOpenChange={(o) => {
                    if (o) hisotryName.current.focus();
                }}
            >
                <Input placeholder="输入名称：" ref={hisotryName} autoFocus />
            </Modal>
        </Modal>
    );
}

export default DreamBuilder;
