import { Button, Cascader, Form, Input, InputNumber, Modal, Popconfirm, Select, Space, Tag, Upload, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useMemo, useState } from "react";
import { UploadOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useCkptStore } from "./store/ckpt";
import { useLoraStore } from "./store/lora";
import { mkCategoryHierarchy } from "../../utils/util";
import { useConfigStore } from "./store/configs";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function ModelEdit({ open, onCancel, model, type = "lora" }) {
    const [messageApi, contextHolder] = message.useMessage();

    const { baseModelSelect } = useConfigStore();
    const ckptStore = useCkptStore();
    const loraStore = useLoraStore();
    const load = type === "lora" ? loraStore.load : ckptStore.load;
    const categorySize = type === "lora" ? loraStore.categorySize : ckptStore.categorySize;
    const categoryList = type === "lora" ? loraStore.categoryList : ckptStore.categoryList; 

    const categoryHierarchy = mkCategoryHierarchy(categoryList, categorySize, true);

    const [alias, setAlias] = useState(model.config?.alias ?? "");
    const [baseModel, setBaseModel] = useState(model.sdVersion)
    const [category, setCategory] = useState(model.config?.category ? model.config?.category.join(", ") : "");
    const catList = useMemo(() => (category.trim() === "" ? [] : category.split(",").map((o) => o.trim())), [category]);
    const [description, setDescription] = useState(model.config?.description ?? "");
    const [trigger, setTrigger] = useState(model.config?.trigger ? model.config?.trigger.join(", ") : "");
    const [weight, setWeight] = useState(model.weight ?? 0.7);
    const [images, setImages] = useState(model.images ?? []);
    const [preview, setPreview] = useState(model.preview ?? "");

    const [uploading, setUploading] = useState(false);
    const [previewUploading, setPreviewUploading] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    const [saving, setSaving] = useState(false);

    const beforePreviewUpload = async (file) => {
        const base64 = await getBase64(file);
        setPreviewUploading(true);

        fetch(`${process.env.REACT_APP_API_URL}/tu/model/preview`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ img: base64, type: type, name: model.name }),
        })
            .then(async (res) => {
                const resp = await res.json();
                if (res.status !== 200) {
                    console.log(res.status, res.statusText, resp);
                    messageApi.open({
                        type: "error",
                        content: res.statusText,
                    });
                    return;
                }
                setPreview(resp.url);
                load();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreviewUploading(false);
            });

        return false;
    };

    const beforeUpload = async (file) => {
        const base64 = await getBase64(file);
        setUploading(true);

        fetch(`${process.env.REACT_APP_API_URL}/tu/model/image`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ img: base64, type: type, name: model.name }),
        })
            .then(async (res) => {
                const resp = await res.json();
                if (res.status !== 200) {
                    console.log(res.status, res.statusText, resp);
                    messageApi.open({
                        type: "error",
                        content: res.statusText,
                    });
                    return;
                }
                setImages([...images, resp]);
                load();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setUploading(false);
            });

        return false;
    };

    const delImage = (img) => {
        const params = img.url.split("?")[1];
        if (!params) {
            return;
        }
        fetch(`${process.env.REACT_APP_API_URL}/tu/model/image?${params}`, {
            method: "DELETE",
        })
            .then(async (res) => {
                const resp = await res.json();
                if (res.status !== 200) {
                    console.log(res.status, res.statusText, resp);
                    messageApi.open({
                        type: "error",
                        content: res.statusText,
                    });
                    return;
                }
                const idx = images.indexOf(img);
                images.splice(idx, 1);
                setImages([...images]);
                load();
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const saveConfig = () => {
        setSaving(true);

        const data = {
            name: model.name,
            type: type,
            base_model: baseModel.trim(),
            alias: alias.trim(),
            category: catList,
            description: description,
            trigger: trigger.trim() === "" ? [] : trigger.split(",").map((o) => o.trim()),
            weight: type === "lora" ? weight : null,
            images: images.map((o) => ({
                filename: new URLSearchParams(o.url.split("?")[1]).get("filename"),
                meta: o.meta,
            })),
        };

        fetch(`${process.env.REACT_APP_API_URL}/tu/model/config`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(async (res) => {
                const resp = await res.json();
                if (res.status !== 200) {
                    console.log(res.status, res.statusText, resp);
                    messageApi.open({
                        type: "error",
                        content: res.statusText,
                    });
                    return;
                }
                load();
                onCancel();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const updateMeta = (img, meta) => {
        img.meta = meta;
        setImages([...images]);
    };

    const onCatChange = (value) => {
        setCategory(value ? value.join(",") : "")
    }

    const toggleTrigger = (value) => {
        const ts = trigger.split(",").map((o) => o.trim()).filter((o) => o !== "")
        const idx = ts.indexOf(value)
        if (idx === -1) {
            ts.push(value)
        } else {
            ts.splice(idx, 1)
        }
        setTrigger(ts.join(", "))
    }

    return (
        <>
            {contextHolder}
            <Modal
                centered
                title={model.name}
                maskStyle={{ background: "rgba(0, 0, 0, 0.5" }}
                width={860}
                open={open}
                onCancel={onCancel}
                okText="确定"
                okButtonProps={{ loading: saving }}
                cancelText="取消"
                destroyOnClose={true}
                onOk={saveConfig}
            >
                <Form labelCol={{ span: 3 }}>
                    <Form.Item label="封面图">
                        {preview && (
                            <div className="model-edit-image edit-preview">
                                <img
                                    onClick={() => {
                                        setPreviewImage(preview);
                                        setPreviewOpen(true);
                                    }}
                                    crossOrigin="anonymous"
                                    alt={"preview"}
                                    src={`${process.env.REACT_APP_API_URL}${preview}`}
                                />
                            </div>
                        )}

                        <Space>
                            <Upload
                                accept=".jpg, .jpeg, .png"
                                fileList={[]}
                                beforeUpload={beforePreviewUpload}
                                name="preview"
                            >
                                <Button
                                    size="small"
                                    style={{ marginTop: 5 }}
                                    loading={previewUploading}
                                    icon={<UploadOutlined />}
                                >
                                    更换封面图
                                </Button>
                            </Upload>
                        </Space>
                    </Form.Item>
                    <Form.Item label="名称">
                        <Input onChange={(e) => setAlias(e.target.value)} value={alias}></Input>
                    </Form.Item>
                    <Form.Item label="基础版本">
                        <Select onChange={setBaseModel} defaultValue={baseModel} options={baseModelSelect}></Select>
                    </Form.Item>
                    <Form.Item label="分类">
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Cascader
                                changeOnSelect
                                value={catList}
                                options={categoryHierarchy}
                                onChange={onCatChange}
                            ></Cascader>
                            <Input onChange={(e) => setCategory(e.target.value)} value={category}></Input>
                        </Space>
                    </Form.Item>
                    <Form.Item label="描述">
                        <TextArea
                            rows={6}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                        ></TextArea>
                    </Form.Item>
                    {model.training_dataset_tags?.length > 0 && (
                        <Form.Item label="训练集标签">
                            <Space size={[0, 8]} wrap>
                                {model.training_dataset_tags.slice(0, 20).map((t, i) => (
                                    <Tag onClick={() => toggleTrigger(t[0])} color="blue" key={i} style={{cursor: "pointer"}}>
                                        {t[0]} {t[1]}
                                    </Tag>
                                ))}
                            </Space>
                        </Form.Item>
                    )}
                    <Form.Item label="触发词">
                        <TextArea rows={2} onChange={(e) => setTrigger(e.target.value)} value={trigger}></TextArea>
                    </Form.Item>
                    {type === "lora" && (
                        <Form.Item label="默认权重">
                            <InputNumber autoFocus min={0} max={1} step={0.01} value={weight} onChange={setWeight} />
                        </Form.Item>
                    )}
                    <Form.Item label="效果图">
                        {images.map((o, i) => (
                            <div key={i} style={{ margin: "5px 0", width: "100%" }}>
                                <Space>
                                    <div className="model-edit-image">
                                        <div className="edit-image-btns">
                                            <EyeOutlined
                                                onClick={() => {
                                                    setPreviewImage(o.url);
                                                    setPreviewOpen(true);
                                                }}
                                                className="edit-image-preview"
                                            />
                                            <Popconfirm
                                                description="确认删除?"
                                                okText="确定"
                                                cancelText="取消"
                                                onConfirm={() => delImage(o)}
                                            >
                                                <DeleteOutlined onClick={() => ""} className="edit-image-del" />
                                            </Popconfirm>
                                        </div>
                                        <img
                                            crossOrigin="anonymous"
                                            alt={i}
                                            src={`${process.env.REACT_APP_API_URL}${o.url}`}
                                        />
                                    </div>
                                    <TextArea
                                        style={{ width: 600, height: 100 }}
                                        value={o.meta}
                                        onChange={(e) => updateMeta(o, e.target.value)}
                                    ></TextArea>
                                </Space>
                            </div>
                        ))}
                        <Modal
                            style={{ maxWidth: "90vw", maxHeight: "90vh" }}
                            width={"auto"}
                            centered
                            open={previewOpen}
                            title="预览"
                            footer={null}
                            onCancel={() => setPreviewOpen(false)}
                        >
                            <img
                                crossOrigin="anonymous"
                                alt="preview"
                                style={{
                                    maxWidth: "80vw",
                                    maxHeight: "80vh",
                                }}
                                src={`${process.env.REACT_APP_API_URL}${previewImage}`}
                            />
                        </Modal>
                        <Upload accept=".jpg, .jpeg, .png" fileList={[]} beforeUpload={beforeUpload} name="file">
                            <Button style={{ marginTop: 5 }} loading={uploading} icon={<UploadOutlined />}>
                                上传效果图
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ModelEdit;
