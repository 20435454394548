import {
    CommentOutlined,
    SettingOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    EditOutlined,
    FontColorsOutlined,
    MacCommandOutlined,
    PictureOutlined,
    MoreOutlined,
    ZoomInOutlined,
    StarOutlined,
    CloseOutlined,
} from "@ant-design/icons";

import { Dropdown } from "antd";

import { ReactComponent as FilterIcon } from "../components/icons/filters.svg";
import { ReactComponent as MoveIcon } from "../components/icons/move.svg";
import { ReactComponent as FillIcon } from "../components/icons/fill.svg";
import { ReactComponent as ImageIcon } from "../components/icons/image.svg";
import { ReactComponent as EraserIcon } from "../components/icons/eraser.svg";
import { ReactComponent as BeautyIcon } from "../components/icons/beauty.svg";
import { ReactComponent as FaceIcon } from "../components/icons/face.svg"
import { ReactComponent as AdjustmentIcon } from "../components/icons/adjustment.svg";
import { ReactComponent as SelectionIcon } from "../components/icons/selection.svg";
import { ReactComponent as UpsalerIcon } from "../components/icons/upscaler.svg";
import { downloadImageResource } from "../utils/download.js";
import { datetimeString } from "../utils/util.js";

import "./MainPanel.css";
import Draggable from "react-draggable";
import { useEffect, useRef, useState } from "react";
import _ from "lodash"
import { EventRegister } from "react-native-event-listeners";

import ActionPanel from "./ActionPanel";
import ConfigPanel from "./ConfigPanel";
import HistoryPanel from "./HistoryPanel";
import LayerPanel from "./LayerPanel";
import ResourcesPanel from "./ResourcesPanel";
import ImageDreamPanel from "./ImageDreamPanel";
import TextDreamPanel from "./TextDreamPanel";
import OutpaintPanel from "./OutpaintPanel";
import FaceSwapPanel from "./FaceSwapPanel";
import ColorBlockPanel from "./ColorBlockPanel";
import ImagePanel from "./ImagePanel";
import FilterPanel from "./FilterPanel";
import TextPanel from "./TextPanel";
import EraserPanel from "./EraserPanel";
import PenPanel from "./PenPanel";
import MaskToolPanel from "./MaskToolPanel";
import MaskPanel from "./MaskPanel";
import ShapePanel from "./ShapePanel";
import FillPanel from "./FillPanel";
import ZoomPanel from "./ZoomPanel";
import MovePanel from "./MovePanel";
import BeautyPanel from "./BeautyPanel";
import AdjustmentPanel from "./AdjustmentPanel";
import ResizePopupPanel from "./ResizePopupPanel";
import UpscalerPanel from "./UpscalerPanel";


function MainPanel({ curTool, setCurTool }) {

    const [expandTool, setExpandTool] = useState(true)
    const [showTipsTool, setShowTipsTool] = useState(null)

    const [configPanelOpen, setConfigPanelOpen] = useState(false)
    const [configPanelData, setConfigPanelData] = useState(null)

    const [resizePanelOpen, setResizePanelOpen] = useState(false)
    const [resizePanelData, setResizePanelData] = useState(null)

    const defaultTool = "none";
    // const [tool, setTool] = useState(defaultTool);
    const [subTool, setSubTool] = useState("");

    useEffect(() => {
        console.log("MainPanel::curTool::", curTool);
        if (curTool === "pen" || curTool === "text" || curTool === "image" || curTool === "shape" || curTool === "canvas-zoom" || curTool === "layer-move") {

            setCurTool(curTool);
            // clearTimeout(tTips);
            activeTipsTool = curTool;
            // setShowTipsTool(null);
            // document.getElementById("canvas").focus();
        } else if (curTool === "none") {
            setCurTool("none");
            activeTipsTool = "";
        } else if (curTool === "") {
            setCurTool("none");
            // clearTimeout(tTips);
            activeTipsTool = "";
            // setShowTipsTool(null);
            // document.getElementById("canvas").focus();
        } else if (curTool === "layer-transform") {
            setCurTool("none");
            // clearTimeout(tTips);
            activeTipsTool = "";
            // setShowTipsTool(null);
            // document.getElementById("canvas").focus();
        } else if (curTool.endsWith("-eraser")) {
            setCurTool("eraser");
            setSubTool(curTool);
            activeTipsTool = curTool;

        } else if (curTool.endsWith("-fill")) {
            setCurTool("fill");
            setSubTool(curTool);
            activeTipsTool = curTool;

        } else if (curTool.startsWith("adjustment-")) {
            setCurTool("adjustment");
            setSubTool(curTool);
            activeTipsTool = curTool;

        } else if (curTool.endsWith("-mask")) {
            setCurTool("mask");
            setSubTool(curTool);
            activeTipsTool = curTool;

        } else {
            setCurTool(curTool);
            activeTipsTool = curTool;
        }

        clearTimeout(tTips);
        setShowTipsTool(null);
        document.getElementById("canvas").focus();

    }, [curTool]);

    let activeTipsTool = null;
    let tTips = null;

    const handleMouseEnter = (key) => {
        clearTimeout(tTips)
        if (showTipsTool != null) {
            setShowTipsTool(key);
            return;
        }
    }


    const handleMouseMove = (key) => {
        clearTimeout(tTips);
        if (showTipsTool || activeTipsTool === key) return;
        tTips = setTimeout(() => {
            setShowTipsTool(key);
        }, 500);
    }

    const handleMouseLeave = () => {
        clearTimeout(tTips);
        setShowTipsTool(null);
        activeTipsTool = null;
    }

    const handleToolClick = (key) => {
        console.log("MainPanel::handleToolClick::", key);

        setCurTool(key);
        clearTimeout(tTips);
        activeTipsTool = key;
        setShowTipsTool(null);

        if (key == "config") {
            window.PSMan?.getInstance().toolManager().reset();
            let cfg = window.PSMan?.getInstance().getConfig();
            // console.log("ZZZZZ", cfg);
            setConfigPanelOpen(true);
            setConfigPanelData(cfg);
        } else if (key == "none") {
            window.PSMan?.getInstance().toolManager().reset();
            console.log("toolManager reset");
        } else if (!["eraser", "mask"].includes(key)) {
            window.PSMan?.getInstance().toolManager().use(key);
        }

        document.getElementById("canvas").focus();
    }



    const tools = [
        { label: "XX", icon: <CloseOutlined />, key: "none" },
        { label: "移动", icon: <MoveIcon />, key: "layer-move", panel: MovePanel },
        { label: "Zoom", icon: <ZoomInOutlined />, key: "canvas-zoom", panel: ZoomPanel },
        // { label: "选区", icon: <ExpandOutlined />, key: "1" },
        // { label: "填充", icon: <StopFilled />, key: "color-fill" },
        { label: "填充", icon: <FillIcon />, key: "fill", panel: FillPanel },
        { label: "选区", icon: <SelectionIcon />, key: "mask", panel: MaskToolPanel },
        { label: "画笔", icon: <EditOutlined />, key: "pen", panel: PenPanel },
        { label: "橡皮", icon: <EraserIcon />, key: "eraser", panel: EraserPanel },
        { label: "文字", icon: <FontColorsOutlined />, key: "text", panel: TextPanel },
        // { label: "色块", icon: <BorderOutlined />, key: "color-block", panel: ColorBlockPanel },
        { label: "形状", icon: <StarOutlined />, key: "shape", panel: ShapePanel },
        { label: "图片", icon: <ImageIcon />, key: "image", panel: ImagePanel },
        { label: "滤镜", icon: <FilterIcon />, key: "filter", panel: FilterPanel },
        { devider: true },
        { label: "文生图", icon: <CommentOutlined />, key: "text2image", panel: TextDreamPanel },
        // { label: "图生图", icon: <MacCommandOutlined />, key: "image2image", panel: ImageDreamPanel },
        { label: "Outpaint", icon: <MacCommandOutlined />, key: "outpaint", panel: OutpaintPanel },
        { label: "Upscalar", icon: <UpsalerIcon />, key: "upscaler", panel: UpscalerPanel},

        { label: "换脸", icon: <FaceIcon />, key: "face-swap", panel: FaceSwapPanel },
        { label: "美颜", icon: <BeautyIcon />, key: "beauty", panel: BeautyPanel },
        { label: "调整", icon: <AdjustmentIcon />, key: "adjustment", panel: AdjustmentPanel },
        // { label: "亮度/对比对", icon: <BeautyIcon />, key: "brightness-contrast", panel: BrightnessContrastPanel },
        // { label: "色相/饱和度", icon: <BeautyIcon />, key: "hue-saturation", panel: HueSaturationPanel },
        // { label: "自然饱和度", icon: <BeautyIcon />, key: "vibrance", panel: VibrancePanel },

        // { label: "滤镜", icon: <ThunderboltOutlined />, key: "3" },
        // { label: "混合模式", icon: <MergeCellsOutlined />, key: "5" },
        // { label: "描边", icon: <BorderOutlined />, key: "stroke", panel: StrokePanel },
        // { label: "叠加", icon: <HddOutlined />, key: "overlay", panel: OverlayPanel },
        // { label: "阴影", icon: <TeamOutlined />, key: "8" },
        { devider: true },
        { label: "设置", icon: <SettingOutlined />, key: "config" },
    ];
    const toolBtns = tools.map((item, index) => {
        if (item.devider) {
            return <div key={index} className="tool-devider"></div>;
        }
        return (
            <div
                key={index}
                onMouseEnter={() => handleMouseEnter(item.key)}
                onMouseLeave={handleMouseLeave}
                className={"tool-item " + (curTool === item.key ? "active" : "")}
            >
                <div
                    onMouseMove={() => handleMouseMove(item.key)}
                    onClick={() => handleToolClick(item.key)}
                    className="tool-item-content"
                >
                    <div className="tool-label">{item.label}</div>
                    <div className="tool-icon">{item.icon}</div>
                </div>
                <div className={`tool-tips ${showTipsTool === item.key ? "active" : ""}`}>
                    {item.label}
                    <div className="tool-tips-help">使用方法</div>
                </div>
            </div>
        );
    });

    const toolPanels = tools.map((item, index) => {
        if (item.devider || item.panel === undefined) {
            return ""
        }
        return (
            <div key={index} className={`${curTool === item.key ? "" : "hidden"}`}>
                <item.panel tool={curTool} subTool={subTool} />
            </div>
        )
    })


    const [tab, setTab] = useState(0)

    const topPanels = [
        { label: "操作", panel: <ActionPanel /> },
        { label: "资源", panel: <ResourcesPanel /> },
        { label: "图层", panel: <LayerPanel /> },
        { label: "历史", panel: <HistoryPanel /> },
        { label: "选区", panel: <MaskPanel /> },
    ]

    const tabs = topPanels.map((value, index) => {
        return <div key={index} onClick={() => setTab(index)} className={`top-panel-tab ${index === tab ? 'active' : ''}`}>{value.label}</div>
    });

    const topPanelItems = topPanels.map((value, index) => {
        return <div key={index} className={`top-panel-item ${index === tab ? "active" : ""}`}>
            {value.panel}
        </div>;
    })

    const topPanel = useRef(null)

    const [maxToolHeight, setMaxToolHeight] = useState(0);

    const handleResizer = _.debounce(() => {
        let topPanelH = 0;
        if (topPanel.current?.getBoundingClientRect()) {
            const rect = topPanel.current?.getBoundingClientRect();
            topPanelH = window.innerHeight - rect.y - rect.height;
            setMaxToolHeight(topPanelH);
        }
    }, 150)

    useEffect(() => {
        const eventListener = EventRegister.addEventListener("resizer", handleResizer);
        window.addEventListener("resize", handleResizer)
        return () => {
            EventRegister.removeEventListener(eventListener);
            window.removeEventListener("resize", handleResizer);
        }
    }, [])

    useEffect(() => {
        handleResizer()
    }, [curTool, tab])

    const moreItems = [
        {
            key: "1",
            label: "调整画布大小",
        },
        {
            key: "2",
            label: "保存图片到本地",
        },
        // {
        //     key: "3",
        //     label: "Item 3",
        // },
    ];

    const handleMoreClick = ({ key }) => {
        console.log(`ooo Click on item ${key}`);

        if (key == "1") {
            setResizePanelOpen(true);
            let cfg = window.PSMan?.getInstance().getConfig();
            console.log(cfg)
            setResizePanelData(cfg.s);
        } else if (key == "2") {

            let suffix = datetimeString();
            let oname = "canvas-" + suffix + ".png";
            // const ac = window.PSMan.getInstance();
            window.PSMan?.getInstance().encodeImage((ok, img) => {
                downloadImageResource(oname, img);
            });
        }

    }

    return (
        <Draggable bounds="body" handle=".top-panel-bar" onDrag={handleResizer}>
            <div className="main-panel">
                <div className={`toolbar ${expandTool ? "expanded" : ""}`}>
                    <div className="tool-expand" onClick={() => setExpandTool(!expandTool)}>
                        <span className="tool-expand-btn">
                            {expandTool ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                        </span>
                    </div>
                    <div className="tool-items">{toolBtns}</div>
                </div>
                <div className="main-panel-wrapper">
                    <div className="top-panel-bar">
                        <Dropdown menu={{ items: moreItems, onClick: handleMoreClick }} trigger={["click"]}>
                            <span className="more">
                                <MoreOutlined />
                            </span>
                        </Dropdown>
                    </div>
                    <div ref={topPanel} className="top-panel">
                        <div className="top-panel-tabs">{tabs}</div>
                        <div className="top-panel-items">{topPanelItems}</div>
                    </div>
                    <div
                        className="tool-panel no-bg-scrollbar"
                        style={{ maxHeight: maxToolHeight, overflowY: "scroll" }}
                    >
                        {toolPanels}
                    </div>
                </div>
                <ConfigPanel data={configPanelData} open={configPanelOpen} setOpen={setConfigPanelOpen} />
                <ResizePopupPanel data={resizePanelData} open={resizePanelOpen} setOpen={setResizePanelOpen} />

            </div>
        </Draggable>
    );
}

export default MainPanel;
