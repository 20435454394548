import { FileImageOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useLoraStore } from "./store/lora";
import { Checkbox } from "antd";
import { useMemo, useState } from "react";
import ModelView from "./ModelView";

function LoraItem({ lora, addNewLora, delNewLora, selectedLoras, ckpt }) {
    const loraStore = useLoraStore();
    const checked = useMemo(() => selectedLoras.includes(lora.title), [lora, selectedLoras]);

    const disabled = ckpt.sdVersion !== lora.sdVersion

    const handleCheck = () => {
        if (disabled) {
            return;
        }
        if (checked) {
            delNewLora(lora)
        } else {
            addNewLora(lora)
        }
    };

    const [viewOpen, setViewOpen] = useState(false);
    const handleClick = (e) => {
        e.stopPropagation();
        setViewOpen(true);
    };

    return (
        <div className={`model-item ${disabled ? "disable" : "checkable"}`}>
            {lora.sdVersion && <div className="base-model-version">{lora.sdVersion}</div>}
            <InfoCircleOutlined style={{ bottom: 40 }} onClick={handleClick} className="info-btn" />
            <ModelView model={lora} open={viewOpen} onCancel={() => setViewOpen(false)}></ModelView>
            {lora.thumb && (
                <img
                    onClick={handleCheck}
                    crossOrigin="anonymous"
                    alt={lora.name}
                    src={`${loraStore.API_URL}${lora.thumb}`}
                />
            )}
            {!lora.thumb && (
                <div onClick={handleCheck} className="no-image">
                    <FileImageOutlined />
                </div>
            )}
            <div onClick={handleCheck} title={lora.alias} className="model-item-title">
                {!disabled && <Checkbox checked={checked} style={{ marginRight: 5 }}></Checkbox>}
                {lora.alias}
            </div>
        </div>
    );
}

export default LoraItem;
