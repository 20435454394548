import * as React from "react";
import { Form, Select, ColorPicker, Row, Col, Slider, InputNumber, Space, Radio } from "antd";
import { useState } from "react";
import { blendModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleInnerShadow({ config, setConfig }) {

    const [blendMode, setBlendMode] = useState(config?.layer_styles.inner_shadow["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.inner_shadow["opacity"] ?? 100);
    const [color, setColor] = useState(config ? rgbaToHex(config?.layer_styles.inner_shadow["color"]) : "#ff00ffff");
    const [degree, setDegree] = useState(config?.layer_styles.inner_shadow["degree"] ?? 0);
    const [distance, setDistance] = useState(config?.layer_styles.inner_shadow["distance"] ?? 1);
    const [choke, setChoke] = useState(config?.layer_styles.inner_shadow["choke"] ?? 0);
    const [size, setSize] = useState(config?.layer_styles.inner_shadow["size"] ?? 0);

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.inner_shadow = { ...cfg.layer_styles.inner_shadow, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const color_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setColor(newValue.toHexString());
        updateConfig({ color: hexToRGB(newValue.toHexString()) });
    };

    const dgeree_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setDegree(newValue);
        updateConfig({ degree: newValue });
    };

    const distance_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setDistance(newValue);
        updateConfig({ distance: newValue });
    };

    const choke_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setChoke(newValue);
        updateConfig({ choke: newValue });
    };

    const size_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSize(newValue);
        updateConfig({ size: newValue });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 60, marginLeft: 10 }}
                                value={opacity}
                                onChange={opacity_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="颜色">
                <ColorPicker value={color} onChange={color_onChange} />
            </Form.Item>

            <Form.Item label="角度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={359} value={degree} onChange={dgeree_onChange} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={359}
                                style={{ width: 60, marginLeft: 10 }}
                                value={degree}
                                onChange={dgeree_onChange}
                            />
                            度
                        </Space>
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="距离">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={500} value={distance} onChange={distance_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={500}
                            style={{ width: 60, marginLeft: 10 }}
                            value={distance}
                            onChange={distance_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="阻塞">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={choke} onChange={choke_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={100}
                            style={{ width: 60, marginLeft: 10 }}
                            value={choke}
                            onChange={choke_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <Form.Item label="大小">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} value={size} onChange={size_onChange} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={250}
                            style={{ width: 60, marginLeft: 10 }}
                            value={size}
                            onChange={size_onChange}
                        />
                    </Col>
                </Row>
            </Form.Item>

        </Form>
    );
}

export default LayerStyleInnerShadow;
