import { Button, Checkbox, Form, Input, InputNumber, Modal, Slider, Space, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FunctionOutlined, ReloadOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import { debounce } from "lodash";
import styled from "styled-components";

const setConfig = debounce((config) => {
    window.PSMan?.getInstance().toolManager().current().setConfig({ fooocus: config });
}, 300);

const Styles = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    gap: 10px 16px;
    height: 600px;
    overflow: scroll;
`;

const StyleCheckbox = styled(Checkbox)`
    align-items: center;
`;

const defaultSharpness = 0.2;
const defaultSelections = ["Fooocus Enhance", "Fooocus Sharp"];
const expansionMethod = "Qwen-14B-Chat";

function Fooocus() {
    const [enabled, setEnabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const [styles, setStyles] = useState([]);
    const [sharpness, setSharpness] = useState(defaultSharpness);
    const [styleSelections, setStyleSelections] = useState([...defaultSelections]);

    useEffect(() => {
        setConfig({
            enabled: enabled,
            expansion_method: expansionMethod,
            sharpness: sharpness,
            style_selections: styleSelections,
        });
    }, [enabled, sharpness, styleSelections]);

    useEffect(() => {
        const loadStyle = async () => {
            const { default: cnData } = await import("./styles.cn.json");
            const { default: stylesData } = await import("./styles.json");
            setStyles(
                stylesData.map((s) => ({
                    label: cnData[s] ?? s,
                    value: s,
                }))
            );
        };
        loadStyle();
    }, []);

    const filteredStyles = useMemo(() => {
        const r = query ? new RegExp(`(${query.trim().replace(/\s+/g, "|")})`, "gi") : null;
        return styles
            .map((s) => {
                let score = 0;
                if (query) {
                    const v = s.value.toLowerCase();
                    const k = s.label.toLowerCase();
                    if (v === query) score += 100;
                    if (k === query) score += 100;
                    const mv = v.match(r);
                    if (mv !== null) score += mv.length * 10;
                    const mk = k.match(r);
                    if (mk !== null) score += mk.length * 10;
                    if (v.startsWith("fooocus")) score += 1;
                }
                return { ...s, score };
            })
            .sort((a, b) => b.score - a.score);
    }, [styles, query]);

    const reset = () => {
        setSharpness(defaultSharpness);
        setStyleSelections([...defaultSelections]);
        setQuery("");
    };

    return (
        <>
            <Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)}>
                Fooocus
            </Checkbox>
            <Button
                icon={<FunctionOutlined />}
                disabled={!enabled}
                size="small"
                type="text"
                onClick={() => setOpen(true)}
            >
                风格
            </Button>
            <Modal
                maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
                centered
                open={open}
                width={1150}
                onCancel={() => setOpen(false)}
                title="Fooocus"
                footer={
                    <Space>
                        <Tooltip title="重置" mouseEnterDelay={0.75}>
                            <Button onClick={reset} type="text" icon={<ReloadOutlined />}></Button>
                        </Tooltip>
                        <Button onClick={() => setOpen(false)} type="primary">
                            确定
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical" size="small" style={{ padding: "0 10px" }}>
                    <FormItem>
                        <Space>
                            <span>Image Sharpness</span>
                            <Slider
                                style={{ width: 300 }}
                                value={sharpness}
                                onChange={(v) => setSharpness(v)}
                                min={0}
                                max={30}
                                step={0.001}
                            />
                            <InputNumber
                                value={sharpness}
                                min={0}
                                max={30}
                                step={0.001}
                                onChange={(v) => setSharpness(v)}
                                style={{ width: 80 }}
                            />
                        </Space>
                    </FormItem>
                    <FormItem
                        label={
                            <Space>
                                <span>选择风格</span>
                                <Input
                                    placeholder="风格搜索"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                                />
                            </Space>
                        }
                    >
                        <Checkbox.Group value={styleSelections} onChange={(s) => setStyleSelections(s)}>
                            <Styles className="no-bg-scrollbar">
                                {filteredStyles.map((s) => (
                                    <StyleCheckbox key={s.label} value={s.value}>
                                        {s.label}
                                    </StyleCheckbox>
                                ))}
                            </Styles>
                        </Checkbox.Group>
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}

export default Fooocus;
