import { FileImageOutlined, LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useCkptStore } from "./store/ckpt";
import { Checkbox } from "antd";
import { useMemo, useState } from "react";
import ModelView from "./ModelView";

function CkptItem({ ckpt, changing = false, showTitle = false, checkable = false, newCkpt, setNewCkpt = null, onImgClick = null}) {
    const ckptStore = useCkptStore();
    const checked = useMemo(() => ckpt.title === newCkpt, [ckpt, newCkpt]);

    const handleCheck = (e) => {
        if (!checkable && typeof (onImgClick) === 'function') {
            onImgClick()
            return
        }
        if (!checkable || typeof setNewCkpt !== "function") return;
        if (checked) {
            // setNewCkpt("");
        } else {
            setNewCkpt(ckpt.title);
        }
    }

    const [viewOpen, setViewOpen] = useState(false)
    const handleInfoClick = (e) => {
        e.stopPropagation();
        setViewOpen(true)
    }

    return (
        <div className={`model-item ${checkable ? "checkable" : ""}`}>
            {ckpt.sdVersion && <div className="base-model-version">{ckpt.sdVersion}</div>}
            {changing && (
                <div className="model-changing">
                    <LoadingOutlined />
                </div>
            )}
            {ckpt.name && (
                <InfoCircleOutlined
                    style={{ bottom: showTitle ? 40 : 10 }}
                    onClick={handleInfoClick}
                    className="info-btn"
                />
            )}
            <ModelView type="ckpt" model={ckpt} open={viewOpen} onCancel={() => setViewOpen(false)}></ModelView>
            {ckpt.thumb && (
                <img
                    onClick={handleCheck}
                    crossOrigin="anonymous"
                    alt={ckpt.name}
                    src={`${ckptStore.API_URL}${ckpt.thumb}`}
                />
            )}
            {!ckpt.thumb && (
                <div onClick={handleCheck} className="no-image">
                    <FileImageOutlined />
                </div>
            )}
            {showTitle && (
                <div onClick={handleCheck} title={ckpt.alias} className="model-item-title">
                    {checkable && <Checkbox checked={checked} style={{ marginRight: 5 }}></Checkbox>}
                    {ckpt.alias}
                </div>
            )}
        </div>
    );
}

export default CkptItem;
