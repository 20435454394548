import "./style/ModelSelect.scss";

import { Button, Layout, Menu, Modal, Space } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useMemo, useState } from "react";
import { useCkptStore } from "./store/ckpt";
import CkptItem from "./CkptItem";
import { mkCategoryHierarchy, mkMenuDefaultOptions } from "../../utils/util";
import Empty from "../Empty";
import { ReloadOutlined } from "@ant-design/icons";

function CkptSelect({ open, setOpen, newCkpt, setNewCkpt }) {
    const ckptStore = useCkptStore();

    const categoryHierarchy = mkCategoryHierarchy(
        ckptStore.categoryList,
        ckptStore.categorySize,
        false,
        ckptStore.categoryTotalSize
    );
    const defaultMenuOptions = mkMenuDefaultOptions(categoryHierarchy);

    const [category, setCategory] = useState(
        defaultMenuOptions.activeKey ? defaultMenuOptions.activeKey.split("/") : []
    );

    const handleCategoryChange = ({ key }) => {
        setCategory(key.split("/"));
    };

    const items = useMemo(() => ckptStore.searchCategory(category), [category, ckptStore]);

    const modelSortFn = (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
        } else {
            return 0;
        }
    }

    return (
        <Modal
            maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
            centered
            open={open}
            width={1150}
            onCancel={() => setOpen(false)}
            title={
                <Space>
                    基础风格
                    <span>{ckptStore.ckpts.length}</span>
                    <Button type="text" icon={<ReloadOutlined />} onClick={ckptStore.refresh}></Button>
                </Space>
            }
            cancelButtonProps={{ className: "hidden" }}
            okText="确定"
            onOk={() => setOpen(false)}
        >
            <Layout className="model-select" style={{ background: "none" }}>
                <Sider style={{ background: "none", marginRight: "1px" }}>
                    <div className="no-bg-scrollbar category-menu">
                        <Menu
                            onClick={handleCategoryChange}
                            style={{ border: "none" }}
                            mode="inline"
                            items={categoryHierarchy}
                            defaultSelectedKeys={defaultMenuOptions.selectedKeys}
                            defaultOpenKeys={defaultMenuOptions.openKeys}
                        ></Menu>
                    </div>
                </Sider>
                <Content>
                    <div className="main-title">{category.join(" > ")}</div>
                    <div className="model-list no-bg-scrollbar">
                        {!items.length && <Empty />}
                        {items.sort(modelSortFn).map((item, i) => (
                            <CkptItem
                                setNewCkpt={setNewCkpt}
                                newCkpt={newCkpt}
                                key={item.name}
                                ckpt={item}
                                showTitle
                                checkable
                            />
                        ))}
                    </div>
                </Content>
            </Layout>
        </Modal>
    );
}

export default CkptSelect;
