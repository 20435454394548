import "./LayerStylePanel.css";

import { Button, Checkbox, Layout, Menu, Modal, Space } from "antd";
import { useRef, useState, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import LayerStyleBlend from "./layerstyles/LayerStyleBlend";
import LayerStyleStroke from "./layerstyles/LayerStyleStroke";
import LayerStyleOverlayColor from "./layerstyles/LayerStyleOverlayColor";
import LayerStyleOverlayGradient from "./layerstyles/LayerStyleOverlayGradient";
import LayerStyleDropShadow from "./layerstyles/LayerStyleDropShadow";
import LayerStyleInnerShadow from "./layerstyles/LayerStyleInnerShadow";
import LayerStyleInnerGlow from "./layerstyles/LayerStyleInnerGlow";
import LayerStyleOuterGlow from "./layerstyles/LayerStyleOuterGlow";
import LayerStyleSatin from "./layerstyles/LayerStyleSatin";
import _ from "lodash"


import Draggable from "react-draggable";

function LayerStylePanel({ open, setOpen, data, originalConfig = {} }) {

    const MIX = "mix";
    const STROKE = "storke";
    const INNER_GLOW = "innerglow";
    const OUTER_GLOW = "outerglow";
    const SATIN = "satin";
    const OVERLAY_COLOR = "overlaycolor";
    const OVERLAY_GRADIENT = "overlaygradient";
    const DROP_SHADOW = "dropshadow";
    const INNER_SHADOW = "innershadow";

    const [config, setConfig] = useState(_.cloneDeep(originalConfig));

    const parseCheckedKeys = () => {
        const keys = [];
        originalConfig.layer_styles?.stroke.enable && keys.push(STROKE);
        originalConfig.layer_styles?.inner_glow.enable && keys.push(INNER_GLOW);
        originalConfig.layer_styles?.satin.enable && keys.push(SATIN);
        originalConfig.layer_styles?.overlay_color.enable && keys.push(OVERLAY_COLOR);
        originalConfig.layer_styles?.overlay_gradient.enable && keys.push(OVERLAY_GRADIENT);
        originalConfig.layer_styles?.outer_glow.enable && keys.push(OUTER_GLOW);
        originalConfig.layer_styles?.drop_shadow.enable && keys.push(DROP_SHADOW);
        originalConfig.layer_styles?.inner_shadow.enable && keys.push(INNER_SHADOW);
        
        return keys;
    };

    const [checkedKeys, setCheckedKeys] = useState(parseCheckedKeys());

    useEffect(() => {
        if (open) {
            if (data) {
                window.PSMan.getInstance().layerManager().layer(data.id).setConfig(config);
            }
        }
    }, [config]);

    const styleItems = [
        {
            title: "混合选项",
            key: MIX,
            panel: <LayerStyleBlend config={config} setConfig={setConfig} />,
            disableCheck: true,
        },
        {
            title: "投影",
            key: DROP_SHADOW,
            panel: <LayerStyleDropShadow config={config} setConfig={setConfig} />,
        },
        {
            title: "内阴影",
            key: INNER_SHADOW,
            panel: <LayerStyleInnerShadow config={config} setConfig={setConfig} />,
        },
        {
            title: "外发光",
            key: OUTER_GLOW,
            panel: <LayerStyleOuterGlow config={config} setConfig={setConfig} />,
        },
        {
            title: "内发光",
            key: INNER_GLOW,
            panel: <LayerStyleInnerGlow config={config} setConfig={setConfig} />,
        },
        {
            title: "光泽",
            key: SATIN,
            panel: <LayerStyleSatin config={config} setConfig={setConfig} />,
        },
        {
            title: "颜色叠加",
            key: OVERLAY_COLOR,
            panel: <LayerStyleOverlayColor config={config} setConfig={setConfig} />,
        },
        {
            title: "渐变叠加",
            key: OVERLAY_GRADIENT,
            panel: <LayerStyleOverlayGradient config={config} setConfig={setConfig} />,
        },
        {
            title: "描边",
            key: STROKE,
            panel: <LayerStyleStroke config={config} setConfig={setConfig} />,
        },
    ];

    const menuItems = styleItems.map((o, i) => {
        return {
            label: (
                <Space key={i}>
                    {!o.disableCheck && <Checkbox onClick={(e) => e.stopPropagation()} value={o.key} />}
                    {o.title}
                </Space>
            ),
            key: o.key,
        };
    });

    const defaultOpenKeys = [styleItems[0]?.key];

    const handleChecked = (keys) => {
        setCheckedKeys(keys)
        let cfg = Object.assign({}, config);
        if (cfg.hasOwnProperty("layer_styles")) {
            cfg.layer_styles.stroke.enable = keys.includes(STROKE);
            cfg.layer_styles.inner_glow.enable = keys.includes(INNER_GLOW);
            cfg.layer_styles.satin.enable = keys.includes(SATIN);
            cfg.layer_styles.overlay_color.enable = keys.includes(OVERLAY_COLOR);
            cfg.layer_styles.overlay_gradient.enable = keys.includes(OVERLAY_GRADIENT);
            cfg.layer_styles.outer_glow.enable = keys.includes(OUTER_GLOW);
            cfg.layer_styles.drop_shadow.enable = keys.includes(DROP_SHADOW);
            cfg.layer_styles.inner_shadow.enable = keys.includes(INNER_SHADOW);
            setConfig(cfg);
        }
    }

    const [activeStyle, setActiveStyle] = useState(styleItems[0]?.key);

    const onSelect = ({ key }) => {
        setActiveStyle(key);
    };

    const handleConfirm = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        window.PSMan.getInstance().layerManager().layer(data.id).setConfig(originalConfig);
        setOpen(false)
    };

    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const draggleRef = useRef(null);

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal
            maskStyle={{ background: "none" }}
            centered
            open={open}
            width={800}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<span className="hidden-close"></span>}
            className="no-padding-modal"
            maskClosable={false}
            destroyOnClose
            title={
                <div
                    className="layer-style-panel-title"
                    style={{
                        width: "100%",
                        cursor: "move",
                        textAlign: "center",
                        background: "#424242",
                        padding: 5,
                        borderRadius: "8px 8px 0 0",
                    }}
                >
                    图层样式 - {data?.name} - {data?.id}
                </div>
            }
            modalRender={(modal) => (
                <Draggable
                    handle=".layer-style-panel-title"
                    bounds={bounds}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <Layout style={{ background: "none", padding: 10 }}>
                <Sider
                    style={{
                        background: "none",
                        marginRight: 16,
                        paddingRight: 10,
                        borderInlineEnd: "1px solid rgba(253, 253, 253, 0.12)",
                    }}
                >
                    <Checkbox.Group style={{ display: "block" }} value={checkedKeys} onChange={handleChecked}>
                        <Menu
                            style={{ background: "none", border: "none" }}
                            itemProp={{ style: "color: red" }}
                            defaultSelectedKeys={defaultOpenKeys}
                            onSelect={onSelect}
                            items={menuItems}
                            onClick={onSelect}
                        />
                    </Checkbox.Group>
                </Sider>
                <Content className="layer-style-content" style={{ background: "none" }}>
                    <div className="no-bg-scrollbar layer-style-options">
                        {styleItems.map(
                            (o, i) =>
                                config &&
                                activeStyle === o.key && (
                                    <div key={i}>
                                        <h3>{o.title}</h3>
                                        {o.panel}
                                    </div>
                                )
                        )}
                    </div>
                    <div className="layer-style-btns">
                        <Button onClick={handleCancel}>取消</Button>
                        <Button type="primary" onClick={handleConfirm}>
                            确定
                        </Button>
                    </div>
                </Content>
            </Layout>
        </Modal>
    );
}

export default LayerStylePanel;
