import { Button, Form, Slider, Select, Space,Radio } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";

const SelectiveColorsMode = [
    { label: "红色", value: 0 },
    { label: "黄色", value: 1 },
    { label: "绿色", value: 2 },
    { label: "青色", value: 3 },
    { label: "蓝色", value: 4 },
    { label: "洋红色", value: 5 },
    { label: "白色", value: 6 },
    { label: "中性色", value: 7 },
    { label: "黑色", value: 8 },
];

const SelectiveMethodMode = [
    { label: "相对", value: 0 },
    { label: "绝对", value: 1 }
];

function SelectiveColorPanel(props) {

    const [color, setColor] = useState(SelectiveColorsMode[0].value);
    const [cyan, setCyan] = useState(0);
    const [magenta, setMagenta] = useState(0);
    const [yellow, setYellow] = useState(0);
    const [black, setBlack] = useState(0);
    const [method, setMethod] = useState(SelectiveMethodMode[1].value);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-selective-color") {

            console.log("SelectiveColorPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("SelectiveColorPanel: init config:", cfg);
                setColor(cfg.color);
                setCyan(cfg.cyan);
                setMagenta(cfg.magenta);
                setYellow(cfg.yellow);
                setBlack(cfg.black);
                setMethod(cfg.method);
            }
        }
    }, [props]);

    const color_onChange = (idx) => {
        setColor(idx);
        window.PSMan?.getInstance().toolManager().current().setConfig({ color: idx });
    };

    const cyan_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCyan(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ cyan: newValue });
    };
    const magenta_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMagenta(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ magenta: newValue });
    };
    const yellow_onChange = (newValue) => {
        if (newValue == null) { return; }
        setYellow(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ yellow: newValue });
    };
    const black_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBlack(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ black: newValue });
    };

    const method_onChange = (e) => {
        if (e == null) {
            return;
        }
        setMethod(e.target.value);
        window.PSMan?.getInstance().toolManager().current().setConfig({ method: e.target.value });
    };


    const reset_onClick = () => {
        setColor(SelectiveColorsMode[0].value);
        setCyan(0);
        setMagenta(0);
        setYellow(0);
        setBlack(0);
        setMethod(SelectiveMethodMode[1].value);

        window.PSMan?.getInstance().toolManager().current().setConfig({ 
            color: SelectiveColorsMode[0].value, 
            cyan: 0,
            magenta: 0, 
            yellow: 0,
            black: 0,
            method: SelectiveMethodMode[1].value });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        props.onApply();
    }

    return (
        <>
            <Form.Item label="颜色">
                <Select value={color} options={SelectiveColorsMode} onChange={color_onChange}></Select>
            </Form.Item>

            <FormItem label="青色">
                <Slider min={-100} max={100} value={cyan} onChange={cyan_onChange} />
            </FormItem>
            <FormItem label="洋红">
                <Slider min={-100} max={100} value={magenta} onChange={magenta_onChange} />
            </FormItem>
            <FormItem label="黄色">
                <Slider min={-100} max={100} value={yellow} onChange={yellow_onChange} />
            </FormItem>
            <FormItem label="黑色">
                <Slider min={-100} max={100} value={black} onChange={black_onChange} />
            </FormItem>

            <Form.Item>
                <Radio.Group onChange={method_onChange} value={method}>
                    <Radio value={SelectiveMethodMode[0].value}>相对</Radio>
                    <Radio value={SelectiveMethodMode[1].value}>绝对</Radio>
                </Radio.Group>
            </Form.Item>


            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default SelectiveColorPanel;