import { Button, Form, Slider, Select, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { HueRangeModes } from "../../utils/defs";


function HueSaturationPanel(props) {

    const [hueRange, setHueRange] = useState(HueRangeModes[0].value);
    const [hue, setHue] = useState(0);
    const [saturation, setSaturation] = useState(0);
    const [brightness, setBrightness] = useState(0);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-hue-saturation") {

            console.log("HueSaturationPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("HueSaturationPanel: init config:", cfg);
                setHue(cfg.hue);
                setSaturation(cfg.saturation);
                setBrightness(cfg.brightness);
            }
        }
    }, [props]);

    const hueRange_onChange = (idx) => {
        setHueRange(idx);
        window.PSMan?.getInstance().toolManager().current().setConfig({ range: idx });
    };

    const hue_onChange = (newValue) => {
        if (newValue == null) { return; }
        setHue(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ hue: newValue });
    };

    const saturation_onChange = (newValue) => {
        if (newValue == null) { return; }
        setSaturation(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ saturation: newValue });
    };

    const brightness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBrightness(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ brightness: newValue });
    };

    const reset_onClick = () => {
        setHue(0);
        setSaturation(0);
        setBrightness(0);
        window.PSMan?.getInstance().toolManager().current().setConfig({ hue: 0, saturation: 0, brightness: 0 });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setHue(0);
        setSaturation(0);
        setBrightness(0);
        props.onApply();
    }


    return (
        <>
            <Form.Item label="范围">
                <Select value={hueRange} options={HueRangeModes} onChange={hueRange_onChange}></Select>
            </Form.Item>

            <FormItem label="色相">
                <Slider min={-180} max={180} value={hue} onChange={hue_onChange} />
            </FormItem>
            <FormItem label="饱和度">
                <Slider min={-100} max={100} value={saturation} onChange={saturation_onChange} />
            </FormItem>
            <FormItem label="明度">
                <Slider min={-100} max={100} value={brightness} onChange={brightness_onChange} />
            </FormItem>
            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default HueSaturationPanel;