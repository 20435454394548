import { Button, Dropdown, Form, Radio, Select, Slider, Space } from "antd";
import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import "./BasePanel.css";
import BrightnessContrastPanel from "./adjust/BrightnessContrastPanel";
import ChannelMixerPanel from "./adjust/ChannelMixerPanel";
import PosterizePanel from "./adjust/PosterizePanel";
import ThresholdPanel from "./adjust/ThresholdPanel";
import GradientMapPanel from "./adjust/GradientMapPanel";
import HueSaturationPanel from "./adjust/HueSaturationPanel";
import InvertPanel from "./adjust/InvertPanel";
import VibrancePanel from "./adjust/VibrancePanel";
import ExposurePanel from "./adjust/ExposurePanel";
import ColorBalancePanel from "./adjust/ColorBalancePanel";
import BlackWhitePanel from "./adjust/BlackWhitePanel";
import SelectiveColorPanel from "./adjust/SelectiveColorPanel";

import DepthBlurPanel from "./adjust/DepthBlurPanel";

import BlurPanel from "./BlurPanel";



function AdjustmentPanel(props) {

    const adjustmentOptions = [
        { key: 0, label: "无", value: "adjustment-none" },
        { key: 1, label: "亮度对比度", value: "adjustment-brightness-contrast" },
        { key: 2, label: "通道混合器", value: "adjustment-channel-mixer" },
        { key: 3, label: "色相饱和度", value: "adjustment-hue-saturation" },
        { key: 4, label: "曝光度", value: "adjustment-exposure" },
        { key: 5, label: "色彩平衡", value: "adjustment-color-balance" },
        { key: 6, label: "色调分离", value: "adjustment-posterize" },
        { key: 7, label: "黑白", value: "adjustment-black-white" },
        { key: 8, label: "阈值", value: "adjustment-threshold" },
        { key: 9, label: "渐变映射", value: "adjustment-gradient-map" },
        { key: 10, label: "可选颜色", value: "adjustment-selective-color" },

        { key: 11, label: "反向", value: "adjustment-invert" },
        { key: 12, label: "自然饱和度", value: "adjustment-vibrance" },
        { key: 13, label: "模糊", value: "adjustment-blur" },
        { key: 14, label: "深度模糊", value: "adjustment-depth-blur" },
    ]

    const [adjustType, setAdjustType] = useState("none");
    const [adjustTypename, setAdjustTypename] = useState("无");

    useEffect(() => {
        if (props.tool === "adjustment") {
            changeTool();

            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(adjustment)>>>>>>:: ", nm, obj);
                // if (nm.startsWith("tool::adjustment")) {
                // }
            }
            // console.log("ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ\nzZZZZZZZZZZZZZZZZZZZZZZZZZZZZz\nZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ");
            // setAdjustType("adjustment-none");
            // setAdjustTypename("无");

        }
    }, [props]);

    useEffect(() => {
        changeTool()
    }, [adjustType]);

    const changeTool = () => {
        if (props.tool !== "adjustment") {
            return;
        }
        // if (adjustType === "adjustment-none") {
        //     // window.PSMan?.getInstance().toolManager().reset();
        // } else {
        window.PSMan?.getInstance().toolManager().use(adjustType);
        // }
        // let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
        // console.log(cfg);
    }

    const handleMenuClick = ({ item, key }) => {
        console.log("FilterPanel:: handleMenuClick", item.props);
        let toolname = item.props.value;
        setAdjustType(toolname);
        setAdjustTypename(adjustmentOptions[key].label);

        // window.PSMan?.getInstance().toolManager().use(toolname);

    }

    const onApply = () => {
        // FIXME 延迟，足够时间调用draw()/done()
        setTimeout(() => {
            setAdjustType("adjustment-none");
            setAdjustTypename("无");
        }, 200);
    }


    return (<BasePanel className={props.className} title={"调整:"}>
        <Form size="middle" labelCol={{ span: 6 }} labelAlign="left" >
            {/* <Divider orientation="left" plain> 美肤 </Divider> */}

            <Form.Item label="选择">
                <Dropdown
                    // onOpenChange={(open) => open}
                    menu={{ items: adjustmentOptions, onClick: handleMenuClick }}
                >
                    <Button>
                        <Space>
                            {adjustTypename}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </Form.Item>

            {adjustType === "adjustment-brightness-contrast" && (
                <BrightnessContrastPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-channel-mixer" && (
                <ChannelMixerPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-hue-saturation" && (
                <HueSaturationPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-posterize" && (
                <PosterizePanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-threshold" && (
                <ThresholdPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-gradient-map" && (
                <GradientMapPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-invert" && (
                <InvertPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-vibrance" && (
                <VibrancePanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-exposure" && (
                <ExposurePanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-color-balance" && (
                <ColorBalancePanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-selective-color" && (
                <SelectiveColorPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-black-white" && (
                <BlackWhitePanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-blur" && (
                <BlurPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}
            {adjustType === "adjustment-depth-blur" && (
                <DepthBlurPanel tool="adjustment" subTool={adjustType} onApply={onApply} />
            )}

        </Form>
    </BasePanel>
    );
}

export default AdjustmentPanel;