import { Empty as AntdEmpty, Space } from "antd";

function Empty() {
    return (
        <div style={{display: "inline-flex", height: "100%", justifyContent: "center", alignItems: "center", width: "100%"}}>
            <AntdEmpty image={AntdEmpty.PRESENTED_IMAGE_SIMPLE} />
        </div>
    )
}

export default Empty