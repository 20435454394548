import "./style/CartItem.scss";

import { useEffect, useMemo, useState } from "react"
import LiteralWeightIdentifier from "./LiteralWeightIdentifier"
import PercentageWeightIdentifier from "./PercentageWeightIdentifier"
import Decimal from "decimal.js-light";
import { Tooltip, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { useCartStore } from "./store/cart";
import { useSettingsStore } from "./store/setting";
import { useSearchStore } from "./store/search";

function CartItem({ data, direction }) {
    const cartStore = useCartStore();
    const settingsStore = useSettingsStore();
    const { setSearch } = useSearchStore();

    const [item, setItem] = useState(data);

    const updateWeight = (v) => {
        data.weight = v;
        setItem({ ...data });
        cartStore.setter(direction)([...cartStore[direction]]);
    };

    const editingChildWeight = useMemo(() => {
        if (data.parent?.type === "editing") {
            const idx = data.parent.children.findIndex((child) => child === data);
            if (idx === 0) {
                return new Decimal(data.parent.breakpoint);
            } else {
                return new Decimal(1).minus(data.parent.breakpoint);
            }
        } else {
            return new Decimal(0);
        }
    }, [data]);

    const updateEditingChildWeight = (v) => {
        if (data.parent?.type === "editing" && v !== null) {
            const idx = data.parent.children.findIndex((child) => child === data);
            if (idx === 0) {
                data.parent.breakpoint = v;
            } else {
                data.parent.breakpoint = new Decimal(1).minus(v);
            }
        }
    };

    function deleteFrom(direction, item) {
        if (item.type !== "null") {
            cartStore.removeCartItem(direction, item);
        }
    }

    function sendTo(direction, data) {
        const revDirection = direction === "positive" ? "negative" : "positive";
        cartStore.removeCartItem(revDirection, data);
        cartStore.appendCartItem(direction, data);
    }

    function adjustLiteralWeight(delta) {
        if (data.type !== "null") {
            if (settingsStore.useFixedMultiplier) {
                data.weight = data.weight.add(0.05 * delta);
            } else {
                data.weight = data.weight.times(Math.pow(settingsStore.newEmphasis ? 1.1 : 1.05, delta));
            }
        }
        setItem({ ...data });
        cartStore.setter(direction)([...cartStore[direction]]);
    }

    function performSearch() {
        if (data.type === "tag" || data.type === "embedding") {
            setSearch(data.name);
        }
    }

    return (
        <div className="flex">
            <div className="tag-label">
                <span className="tag-label-text" onDoubleClick={performSearch}>
                    {data.label}
                </span>
                {data.type !== "null" && (
                    <LiteralWeightIdentifier
                        weight={item.weight}
                        updateWeight={updateWeight}
                        className="weight-identifier"
                    />
                )}
                {data.parent?.type === "editing" && (
                    <PercentageWeightIdentifier
                        className="weight-identifier"
                        weight={editingChildWeight}
                        updateWeight={updateEditingChildWeight}
                    />
                )}
            </div>
            <div className="tag-button">
                {/* <Tooltip
                    v-if="['tag', 'embedding'].includes(data.type)"
                    content="创建混合组"
                    mouseEnterDelay={0.75}>
                    <Button
                        link
                        type="primary"
                        @click.stop="() => (data.type === 'tag' || data.type === 'embedding') &&
                            cartStore.createMixtureFromTag(direction, data as CartItemSimple)">
                        <FontAwesomeIcon :icon="faBlender" />
                    </Button>
                </Tooltip>

                <Tooltip
                    v-if="
                        (data.type === 'editing' ||
                            data.type === 'alternate' ||
                            data.type === 'group') &&
                        cartStore.isMixtureSwitchable(data)
                    "
                    content="切换混合方式"
                    mouseEnterDelay={0.75}>
                    <Button
                        link
                        type="primary"
                        @click.stop="
                            ;(data.type === 'editing' ||
                                data.type === 'alternate' ||
                                data.type === 'group') &&
                                cartStore.switchMixtureType(direction, data)
                        ">
                        <FontAwesomeIcon :icon="faRepeat" />
                    </Button>
                </Tooltip> */}

                {/* <template v-if="data.type === 'editing'">
                    <Tooltip content="断点后移" mouseEnterDelay={0.75}>
                        <Button
                            link
                            type="primary"
                            @click.stop="adjustEditingWeight(1)">
                            <FontAwesomeIcon :icon="faScaleUnbalanced" />
                        </Button>
                    </Tooltip>
                    <Tooltip content="断点前移" mouseEnterDelay={0.75}>
                        <Button
                            link
                            type="primary"
                            @click.stop="adjustEditingWeight(-1)">
                            <FontAwesomeIcon :icon="faScaleUnbalancedFlip" />
                        </Button>
                    </Tooltip>
                </template> */}

                {data.type !== "null" && (
                    <>
                        <Tooltip title="提高权重" mouseEnterDelay={0.75}>
                            <Button type="link" onClick={() => adjustLiteralWeight(1)}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Tooltip>
                        <Tooltip title="降低权重" mouseEnterDelay={0.75}>
                            <Button type="link" onClick={() => adjustLiteralWeight(-1)}>
                                <FontAwesomeIcon icon={faMinus} />
                            </Button>
                        </Tooltip>
                    </>
                )}

                {data.parent === null && (
                    <>
                        {direction === "positive" && (
                            <Tooltip title="转为反向" mouseEnterDelay={0.75}>
                                <Button type="link" onClick={() => sendTo("negative", data)}>
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </Button>
                            </Tooltip>
                        )}
                        {direction === "negative" && (
                            <Tooltip title="转为正向" mouseEnterDelay={0.75}>
                                <Button type="link" onClick={() => sendTo("positive", data)}>
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </Button>
                            </Tooltip>
                        )}
                    </>
                )}
                {!(data.type === "null") && (
                    <Tooltip title="删除" mouseEnterDelay={0.75}>
                        <Button danger type="link" onClick={() => data.type !== "null" && deleteFrom(direction, data)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}

export default CartItem