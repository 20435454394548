import "./style/Cart.scss"
import { Button } from "antd";
import CartTree from "./CartTree";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useCartStore } from "./store/cart";
import { DeleteOutlined } from "@ant-design/icons";

function Cart({ close, updateTag }) {
    const cartStore = useCartStore();
    const { confirm } = Modal;
    const clearDialog = () => {
        confirm({
            title: "清空",
            icon: <ExclamationCircleFilled />,
            content: "确定要清空吗？",
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                cartStore.clear();
            },
            onCancel() {},
        });
    };

    const confirmResult = () => {
        updateTag({
            positiveTags: cartStore.positiveToString,
            negativeTags: cartStore.negativeToString,
        });
        close();
    };

    return (
        <>
            <div className="cart-container">
                <div className="scrollable no-bg-scrollbar">
                    <div className="subcart-container cart-positive-container">
                        <h1>提示词</h1>
                        <CartTree direction="positive" />
                    </div>
                    <div className="subcart-container cart-negative-container">
                        <h1>反向提示词</h1>
                        <CartTree direction="negative" />
                    </div>
                </div>
                <div className="btn-block mb-bottom">
                    <Button type="primary" className="btn" size="large" onClick={confirmResult}>
                        确定
                    </Button>
                    <Button
                        danger
                        type="primary"
                        style={{ flex: 0, marginLeft: 10 }}
                        className="btn"
                        size="large"
                        onClick={clearDialog}
                    >
                        <DeleteOutlined />
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Cart