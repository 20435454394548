import * as React from "react";
import { Form, Select, ColorPicker, InputNumber, Slider, Row, Col, Radio, Space } from "antd";
import { useState } from "react";
import { blendModes, gradientModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleOverlayGradient({ config, setConfig }) {

    const [blendMode, setBlendMode] = useState(config?.layer_styles.overlay_gradient["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.overlay_gradient["opacity"] ?? 100);
    const [gradMode, setGradMode] = useState(config?.layer_styles.overlay_gradient["grad_mode"] ?? 0);
    const [gradStartColor, setGradStartColor] = useState(
        config ? rgbaToHex(config?.layer_styles.overlay_gradient["grad_start_color"]) : "#ffff00ff"
    );
    const [gradEndColor, setGradEndColor] = useState(
        config ? rgbaToHex(config?.layer_styles.overlay_gradient["grad_end_color"]) : "#ff00ffff"
    );
    const [gradScale, setGradScale] = useState(config?.layer_styles.overlay_gradient["grad_scale"] ?? 100);
    const [gradDegree, setGradDegree] = useState(config?.layer_styles.overlay_gradient["grad_degree"] ?? 0);

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.overlay_gradient = { ...cfg.layer_styles.overlay_gradient, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const gradMode_onChange = (idx) => {
        setGradMode(idx);
        updateConfig({ grad_mode: idx });
    };

    const gradStartColor_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradStartColor(newValue.toHexString());
        updateConfig({ grad_start_color: hexToRGB(newValue.toHexString()) });
    };

    const gradEndColor_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradEndColor(newValue.toHexString());
        updateConfig({ grad_end_color: hexToRGB(newValue.toHexString()) });
    };

    const gradScale_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradScale(newValue);
        updateConfig({ grad_scale: newValue });
    };

    const gradDegree_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradDegree(newValue);
        updateConfig({ grad_degree: newValue });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">

            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: 60, marginLeft: 10 }}
                                value={opacity}
                                onChange={opacity_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>


            <Form.Item label="颜色">
                <Space>
                    <ColorPicker value={gradStartColor} onChange={gradStartColor_onChange} />
                    <div
                        style={{
                            width: "240px",
                            height: "20px",
                            background: `linear-gradient(to right, ${gradStartColor}, ${gradEndColor})`,
                        }}
                    ></div>
                    <ColorPicker value={gradEndColor} onChange={gradEndColor_onChange} />
                </Space>
            </Form.Item>
            <Form.Item label={"样式"}>
                <Select value={gradMode} options={gradientModes} onChange={gradMode_onChange}></Select>
            </Form.Item>
            <Form.Item label="角度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={359} onChange={gradDegree_onChange} value={gradDegree} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={0}
                                max={359}
                                style={{ width: 60, marginLeft: 10 }}
                                value={gradDegree}
                                onChange={gradDegree_onChange}
                            />
                            度
                        </Space>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item label="缩放">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={10} max={150} onChange={gradScale_onChange} value={gradScale} />
                    </Col>
                    <Col span={8}>
                        <Space>
                            <InputNumber
                                min={10}
                                max={150}
                                style={{ width: 60, marginLeft: 10 }}
                                value={gradScale}
                                onChange={gradScale_onChange}
                            />
                            %
                        </Space>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
}

export default LayerStyleOverlayGradient;
