import { Button, Form, Slider, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";


function DepthBlurPanel(props) {

    const [blurStrength, setBlurStrength] = useState(10);
    const [focusDistance, setFocusDistance] = useState(20);
    const [fog, setFog] = useState(20);
    const [edgeFog, setEdgeFog] = useState(20);
    // const [gamma, setGamma] = useState(1);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-depth-blur") {

            console.log("DepthBlurPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("DepthBlurPanel: init config:", cfg);
                setBlurStrength(cfg.blur_strength);
                setFocusDistance(cfg.focus_distance);
                setFog(cfg.fog);
                setEdgeFog(cfg.edge_fog);

            }
        }
    }, [props]);

    const blurStrength_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBlurStrength(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ blur_strength: newValue });
    };
    
    const focusDistance_onChange = (newValue) => {
        if (newValue == null) { return; }
        setFocusDistance(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ focus_distance: newValue });
    };

    const fog_onChange = (newValue) => {
        if (newValue == null) { return; }
        setFog(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ fog: newValue });
    };

    const edgeFog_onChange = (newValue) => {
        if (newValue == null) { return; }
        setEdgeFog(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ edge_fog: newValue });
    };
    


    const reset_onClick = () => {
        setBlurStrength(0);
        setFocusDistance(0);
        setFog(0);

        window.PSMan?.getInstance().toolManager().current().setConfig({ blur_strength: 0});
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setBlurStrength(0);
        setFocusDistance(0);
        setFog(0);
        props.onApply();
    }


    return (
        <>
            <FormItem label="模糊强度">
                <Slider min={0} max={100} step={1} value={blurStrength} onChange={blurStrength_onChange} />
            </FormItem>
            
            <FormItem label="焦距">
                <Slider min={0} max={100} step={1} value={focusDistance} onChange={focusDistance_onChange} />
            </FormItem>
            <FormItem label="雾化">
                <Slider min={5} max={100} step={1} value={fog} onChange={fog_onChange} />
            </FormItem>

            <FormItem label="边界雾化">
                <Slider min={1} max={50} step={1} value={edgeFog} onChange={edgeFog_onChange} />
            </FormItem>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default DepthBlurPanel;