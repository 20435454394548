import { InputNumber, Slider } from "antd";
import { useLoraStore } from "./store/lora";
import { FileImageOutlined, DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ModelView from "./ModelView";

function SelectedLoraItem({item, newLoras, setNewLoras, delNewLora }) {
    const loraStore = useLoraStore()

    const [showSlider, setShowSlider] = useState(false)
    const [showInput, setShowInput] = useState(false)

    const [weight, setWeight] = useState(item.weight)

    const handleWeightChange = (v) => {
        item.weight = v
        setNewLoras([...newLoras])
    }

    useEffect(() => {
        setWeight(item.weight)
    }, [item])

    let delayClick = null 
    const handleWeightClick = (e) => {
        if (!delayClick) {
            delayClick = _.debounce(() => {
                setShowSlider(true)
            }, 200);
        }
        if (e.detail === 1) {
            delayClick(e)
        } else {
            delayClick.cancel()
        }
        if (e.detail === 2) {
            setShowInput(true)
        }
    }

    const [viewOpen, setViewOpen] = useState(false)
    const handleClick = (e) => {
        e.stopPropagation()
        setViewOpen(true)
    }

    const refhs = useRef(null)
    const hideSlider = () => {
        refhs.current = setTimeout(() => setShowSlider(false), 1000)
    }

    const refhi = useRef(null)
    const hideInput = () => {
        refhi.current = setTimeout(() => setShowInput(false), 1000)
    }

    return (
        <div className="selected-lora-item">
            <div title={item.alias} className="lora-title">
                {item.alias}
            </div>
            {item.thumb && <img crossOrigin="anonymous" alt={item.name} src={`${loraStore.API_URL}${item.thumb}`} />}
            {!item.thumb && (
                <div className="no-image">
                    <FileImageOutlined />
                </div>
            )}
            <div className="lora-weight">
                {showSlider && (
                    <div
                        style={{ width: "100%" }}
                        onMouseLeave={hideSlider}
                        onMouseEnter={() => clearTimeout(refhs.current)}
                    >
                        <Slider
                            onChange={setWeight}
                            onAfterChange={handleWeightChange}
                            value={weight}
                            min={0}
                            max={1}
                            step={0.01}
                            style={{ width: "90%" }}
                        />
                    </div>
                )}
                {showInput && (
                    <div
                        style={{ width: "100%" }}
                        onMouseLeave={hideInput}
                        onMouseEnter={() => clearTimeout(refhi.current)}
                    >
                        <InputNumber
                            autoFocus
                            min={0}
                            max={1}
                            step={0.01}
                            height={25}
                            size="small"
                            style={{ width: "100%" }}
                            value={weight}
                            onChange={(v) => {
                                setWeight(v);
                                handleWeightChange(v);
                            }}
                        />
                    </div>
                )}
                {!(showSlider || showInput) && (
                    <>
                        <span>效果强度:</span>
                        {}
                        {!showInput && (
                            <span onClick={handleWeightClick} className="lora-weight-span">
                                {weight}
                            </span>
                        )}
                    </>
                )}
            </div>
            {/* <Popconfirm description="确认删除?" okText="确定" cancelText="取消" onConfirm={() => delNewLora(item)}> */}
            <InfoCircleOutlined onClick={handleClick} className="info-btn" />
            <ModelView model={item} open={viewOpen} onCancel={() => setViewOpen(false)}></ModelView>
            <DeleteOutlined onClick={() => delNewLora(item)} className="lora-del" />
            {/* </Popconfirm> */}
        </div>
    );
}

export default SelectedLoraItem;