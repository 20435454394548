import * as React from 'react';

import { Form, Select, Button, ColorPicker, Space, Row } from "antd";
import BasePanel from './BasePanel';

function MovePanel(props) {

    // const [penRadius, setPenRadius] = React.useState(10);
    // const [blockRadius, setBlockRadius] = React.useState(10);
    // const [fillType, setFillType] = React.useState("color-fill");
    // const [mode, setMode] = React.useState("zoom-in");

    // const [color, setColor] = React.useState("#ff000000");
    // const [gradMode, setGradMode] = React.useState(0);
    // const [gradStartColor, setGradStartColor] = React.useState("#ffff0000");
    // const [gradEndColor, setGradEndColor] = React.useState("#0000ff00");

    // const gmodeList = [
    //     { label: "线性", value: 0, name: "color-fill" },
    //     { label: "径向", value: 1, name: "gradient-fill" },
    //     // { label: "矩形", value: 2 },
    // ];


    React.useEffect(() => {
        if (props.tool === "layer-move") {
            // changeTool()
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(layer-move)>>>>>>:: ", nm, obj);

                if (nm == "tool::layer-move-config-update") {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    console.log("ssssssssssssss", config);
                    // setBlockRadius(config.radius);
                    // setMode(config.mode);
                }
            }
        }
    }, [props.tool])

    // React.useEffect(() => {
    //     changeTool()
    // }, [fillType]);



    const updateMode = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ mode: val });
        // setMode(val);
    }
    const onaction_click = (type) => {
        window.PSMan?.getInstance().toolManager().current()?.sendAction(type, null);
    }

    return (
        <BasePanel className={props.className} title={"对齐:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item >
                    <Space>
                        <span>水平:</span>
                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-left")} >L
                        </Button>

                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-hcenter")} >+
                        </Button>

                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-right")} >R
                        </Button>

                    </Space>
                </Form.Item>
                <Form.Item >
                    <Space>
                        <span>垂直:</span>
                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-top")} >T
                        </Button>

                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-vcenter")} >+
                        </Button>

                        <Button
                            // disabled={actionState < 1}
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => onaction_click("align-bottom")} >B
                        </Button>

                    </Space>

                </Form.Item>
            </Form>
        </BasePanel>
    );
}





export default MovePanel;