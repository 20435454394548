import { Button, Form, Slider, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";


function ExposurePanel(props) {

    const [exposure, setExposure] = useState(0);
    const [offset, setOffset] = useState(0);
    const [gamma, setGamma] = useState(1);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-exposure") {

            console.log("ExposurePanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("ExposurePanel: init config:", cfg);
                setExposure(cfg.exposure);
                setOffset(cfg.offset);
                setGamma(cfg.gamma);
            }
        }
    }, [props]);

    const exposure_onChange = (newValue) => {
        if (newValue == null) { return; }
        setExposure(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ exposure: newValue });
    };
    const offset_onChange = (newValue) => {
        if (newValue == null) { return; }
        setOffset(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ offset: newValue });
    };
    const gamma_onChange = (newValue) => {
        if (newValue == null) { return; }
        setGamma(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ gamma: newValue });
    };

    const reset_onClick = () => {
        setExposure(0);
        setOffset(0);
        setGamma(1);
        window.PSMan?.getInstance().toolManager().current().setConfig({ exposure: 0, offset: 0, gamma: 1 });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setExposure(0);
        setOffset(0);
        setGamma(1);
        props.onApply();
    }


    return (
        <>
            <FormItem label="曝光度">
                <Slider min={-20.00} max={20.00} step={0.01} value={exposure} onChange={exposure_onChange} />
            </FormItem>
            <FormItem label="位移">
                <Slider min={-0.5} max={0.5} step={0.0001} value={offset} onChange={offset_onChange} />
            </FormItem>
            <FormItem label="灰度系数校正">
                <Slider min={0.01} max={9.99} step={0.01} value={gamma} onChange={gamma_onChange} />
            </FormItem>
            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default ExposurePanel;