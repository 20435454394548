import "./style/TagShow.scss";

import { Input } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import TagItem from "./TagItem";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { useTagStore } from "./store/tags";
import { SearchOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";

function TagShow({ category }) {
    const [paginationSize, setPaginationSize] =  useState(30)

    const [search, setSearch] = useState("");

    const { searchCategory } = useTagStore()
    
    const filteredTags = useMemo(() => {
       return searchCategory(category, search);
    }, [searchCategory, category, search]);

    const paginatedTags = useMemo(() =>
        filteredTags.slice(0, paginationSize), [paginationSize, filteredTags]
    )

    const paginatedLength = useMemo(() => paginatedTags.length, [paginatedTags]);

    const loadMore = () => {
        setPaginationSize(paginationSize + 30)
    }

    const tagItems = paginatedTags.map((meta, index) => {
        return <TagItem key={index} meta={meta} />;
    });

    useEffect(() => {
        setPaginationSize(30)
        scrollRef.current.scrollTo({ top: 0 });
    }, [category, search])

    const scrollRef = useRef(null)

    return (
        <div>
            <div className="main-title">{category.join(" > ")}</div>
            <div>
                <Input
                    prefix={<SearchOutlined />}
                    placeholder="搜索"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    allowClear
                ></Input>
            </div>
            <div className="tag-wrapper no-bg-scrollbar" id="tagWrapper" ref={scrollRef}>
                <InfiniteScroll
                    dataLength={paginatedLength}
                    next={loadMore}
                    hasMore={true}
                    scrollableTarget="tagWrapper"
                >
                    <ResponsiveMasonry columnsCountBreakPoints={{ 512: 1, 1280: 2, 1600: 3, 1920: 4, 2160: 5 }}>
                        <Masonry gutter="10px">{tagItems}</Masonry>
                    </ResponsiveMasonry>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default TagShow;
