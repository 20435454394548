import "./Resizer.scss";
import React, { createRef } from "react";
import { EventRegister } from "react-native-event-listeners";

function Resizer({
    children,
    maxHeight,
    minHeight,
    style,
    color = "#5c5c5c",
    resizerClassName = '',
    wrapper = <></>,
    className,
    onClick,
    onContextMenu,
    disabled,
}) {
    const child = React.Children.only(children);

    let ref = child.ref;
    if (child.ref === null) {
        ref = createRef();
    }

    const onMouseDown = (e) => {
        e.stopPropagation();
        const el = ref.current;
        const rect = el?.getBoundingClientRect();
        if (!rect) {
            return;
        }
        const startY = e.pageY;

        const resize = (e) => {
            const max = maxHeight ?? window.innerHeight;
            const min = minHeight ?? 0;
            let height = rect.height + (e.pageY - startY);
            if (height > max) {
                height = max;
            } else if (height < min) {
                height = min;
            }
            el.style.height = `${height}px`;
            EventRegister.emit("resizer");
        };

        const stopResize = () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResize);
        };

        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
    };

    return React.cloneElement(wrapper, { onClick, onContextMenu, disabled, className }, [
        React.cloneElement(child, { ref, key: "e" }),
        <div
            key="r"
            className={`resizer ${resizerClassName}`}
            style={Object.assign({ "--resizer-color": color }, style)}
            onMouseDown={onMouseDown}
        ></div>,
    ]);
}

export default Resizer;
