import "./style/PromptBuilder.scss";

import { Input, Layout, Menu, Modal } from "antd";
import { useMemo, useState } from "react";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import TagSearchShow from "./TagSearchShow";
import TagShow from "./TagShow";
import { useTagStore } from "./store/tags";
import { SearchOutlined } from "@ant-design/icons";
import Cart from "./Cart";
import { useCartStore } from "./store/cart";
import { useSearchStore } from "./store/search";
import { mkCategoryHierarchy } from "../../utils/util";
import Empty from "../Empty";
import _ from "lodash"


function PromptBuilder({ open, setOpen, positiveTags, negativeTags, updateTag }) {

    const cartStore = useCartStore();
    const {search, setSearch} = useSearchStore();

    const [selectCategory, setSelectCategory] = useState([]);

    const { categoryList, categorySize } = useTagStore();

    const categoryHierarchy = useMemo(() => mkCategoryHierarchy(categoryList, categorySize), [categoryList, categorySize]);

    const handleOpenChange = (o) => {
        if (o) {
            const positiveTagsValue = _.uniq(positiveTags.replaceAll(/\r\n|\r|\n/g, " ").split(",").map((t) => t.trim()))
            const negativeTagsValue = _.uniq(negativeTags.replaceAll(/\r\n|\r|\n/g, " ").split(",").map((t) => t.trim()))
            cartStore.importClassic("positive", _.difference(positiveTagsValue, negativeTagsValue).join(","));
            cartStore.importClassic("negative", negativeTagsValue.join(","));
        }
    }

    const handleCategoryChange = ({ key }) => {
        setSelectCategory(key.split("/"));
        setSearch("")
    };

    const handleClose = () => {
        setSearch("")
        // setSelectCategory([])

        cartStore.clear();
    }

    return (
        <Modal
            maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
            centered
            open={open}
            width={"90%"}
            onCancel={() => setOpen(false)}
            footer={null}
            afterClose={handleClose}
            afterOpenChange={handleOpenChange}
            maskClosable={false}
        >
            <Layout style={{ background: "none" }}>
                <Header style={{ background: "none", padding: "5px 0 0 0" }}>
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="搜索"
                        onChange={(e) => setSearch(e.target.value)}
                        allowClear
                    ></Input>
                </Header>
                <Layout hasSider style={{ background: "none" }}>
                    <Sider style={{ background: "none", borderRight: "1px solid #303030" }}>
                        <div className="no-bg-scrollbar category-menu">
                            <Menu
                                onClick={handleCategoryChange}
                                style={{ border: "none" }}
                                mode="inline"
                                items={categoryHierarchy}
                            ></Menu>
                        </div>
                    </Sider>
                    <Content style={{ padding: "0 20px" }}>
                        {search && <TagSearchShow search={search} />}
                        {!search && <>
                            {selectCategory.length > 0 && <TagShow category={selectCategory} />}
                            {selectCategory.length === 0 && <Empty />}
                        </>}
                    </Content>
                    <Sider style={{ background: "none", borderLeft: "1px solid #303030" }} width={375}>
                        <Cart close={() => setOpen(false)} updateTag={updateTag} />
                    </Sider>
                </Layout>
            </Layout>
        </Modal>
    );
}

export default PromptBuilder;
