import * as React from 'react';

import { ColorPicker, Checkbox, Select, Col, Row, Image } from 'antd';
import { Form, Slider } from "antd";
import BasePanel from './BasePanel';
import { hexToRGB } from "../utils/util"


function FaceSwapPanel(props) {

    // console.log("PenPanel::init............................ : " + window.PSMan);

    const defaultImagePath = "请选择人脸图片";
    const defaultImageData = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAg1JREFUWIXtljlLA0EUx6cQtbMQz87CqxAsPBCM2sSj8r6ClfgBTCzsLT2i30CDpvdLWFoJHsQLFLwtxMoD/T8zIS/LzGx2dq30Dz8IeefM7LxdIf5lp3LQCQbBEAjJ/35VtWAZpMCXhpT0qQuycAXYAh+Gwk4+QULG+lIY3Hso7OQB9NoWnwRvPopnoBxTXouHDcVfLJvIeyfo3HTb/gyawK1FE3Qclfk0kDAkiUmfZpnQaxPbbsXp+nxqgvdAAfOla3ngsQHKXW9qYEUTeAmqFP7FYAm8emhizdSAasicghpTEFQGFsG+0O9ghjNdkgqF8y4odSnuVAnoEOkRPS7SN4oWkGR5VbspupjDoUwQpBZY/m6VwzBzmNEkaQPthiIme4TlH3FrIKKwzzL7nIV9mtlHVQ2EmENMYd9k9oSFPSpcjqCSOagGRgu4Ateg1cLOB1y1qgHSqXS4E7lDx68oV2Z8n5scV0W2S89vMIMmWN64yZHGZGaQnICiAIoXgmORHcUNbgHbItvtRgANxFm+ZD4B9DA+sqCoj+LzLA/lVE5AlfrAOwteF96Og7adr5xyDXiI/1HE0QSdIz1MpttBNpr/R47iusnqqn6RexwEXSe607S9UxL6vQVuHL5PwmLlTtG57Qj316zzw4MeOO3AsRFdHzrXc0PhC+nTGGRhlWhlPWBM0iMCXu3f0TfZ0jklyrfhUAAAAABJRU5ErkJggg==";
    const [faceImagePath, setFaceImagePath] = React.useState(defaultImagePath);
    const [imageList, setImageList] = React.useState([]);

    const [imageData, setImageData] = React.useState("");

    const [upscalerName, setUpscalerName] = React.useState("");
    const [upscalerScale, setUpscalerScale] = React.useState(1);


    const upscalerNames = [
        { label: "-", value: "" },
        { label: "Lanczos", value: "Lanczos" },
        // { label: "屏幕", value: "" },
    ];




    React.useEffect(() => {
        if (props.tool == "face-swap") {

            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(faceswap)>>>>>>:: ", nm, obj);
                if (nm == "tool::face-swap-config-update") {
                    let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
                    if (cfg != null) {
                        setUpscalerName(cfg.upscaler_name);
                        setUpscalerScale(cfg.upscalar_scale);
                    }
                }
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                setUpscalerName(cfg.upscaler_name);
                setUpscalerScale(cfg.upscalar_scale);
                // console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg);
            }
        }

    }, [props.tool])


    const upscalerName_onChange = (nm) => {
        console.log("FaceSwapPanel::upscalerName_onChange", nm);
        setUpscalerName(nm);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscaler_name: nm });
    }
    const updateUpscalerScale = (val) => {
        setUpscalerScale(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscalar_scale: val });
    }


    const updateImagePath = (idx) => {
        console.log(idx);
        let imp = imageList[idx].path;
        setFaceImagePath(imp);

        let openRequest = window.indexedDB.open("/psman", 21);
        openRequest.onerror = function (event) {
            console.log("打开IndexedDB('/psman', 21) 失败!!", event);
        };
        openRequest.onsuccess = function () {
            let db = openRequest.result;
            // 继续使用 db 对象处理数据库

            var transaction = db.transaction(["FILE_DATA"]);
            var objectStore = transaction.objectStore("FILE_DATA");
            var request = objectStore.get(imp);
            request.onerror = function (event) {
                console.log("Unable to retrieve daa from database!");
                // return "";
            };
            request.onsuccess = function (event) {
                var imgFile = request.result;
                // console.log(imgFile)
                let blob = new Blob([imgFile.contents], { type: "image/png" });
                var imgURL = window.URL.createObjectURL(blob);
                // console.log("xxxxxxxxxxxxx", imgURL);
                setImageData(imgURL);
                //    return imgURL;
            };
        };

        window.PSMan?.getInstance().toolManager().current()?.setConfig({ image_path: imp });
    }

    const handleRefreshImage = () => {
        let flist = window.PSMan.getInstance().resourceManager().list("/psman");
        console.log(flist);

        // flist.filter
        flist = flist.filter((item) => (item.path.endsWith('.png') || item.path.endsWith('.jpg')));

        let fmap = flist.map((item, idx) => ({ key: idx, value: idx, label: item.name, path: item.path }));
        setImageList(fmap);
        console.log(fmap);
        if (fmap.length > 0 && faceImagePath == defaultImagePath) {
            console.log("dddddddddddddddd", faceImagePath);
            setFaceImagePath(fmap[0].label);
        }

        // if (flist.length > 0) {
        //     updateFont(flist[0].path)
        // }
    };


    return (
        <BasePanel className={props.className} title={"换脸:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">

                {/* <Checkbox checked={amask} onChange={updateAMask}>同时更新蒙版</Checkbox> */}

                <Form.Item label="放大倍数">
                    <Slider value={upscalerScale} min={1} max={8} onChange={updateUpscalerScale} />
                </Form.Item>
                <Form.Item label="放大算法">
                    <Select value={upscalerName} options={upscalerNames} onChange={upscalerName_onChange}></Select>
                </Form.Item>

                <Form.Item className="ant-form-item" >
                    <Row align="middle" >
                        <Col span={6}> 人脸图片：</Col>
                        <Col span={18}>
                            <Select style={{ width: 225 }} options={imageList} value={faceImagePath} onFocus={handleRefreshImage}
                                onChange={updateImagePath} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item label="预览">
                    <Image
                        width={200}
                        src={imageData}
                        fallback={defaultImageData}
                    />
                </Form.Item>

            </Form>
        </BasePanel>
    );
}





export default FaceSwapPanel;