import { Button, Form, Slider, Space, Switch } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";


function InvertPanel(props) {

    const [enable, setEnable] = useState(true);

    const enable_onChanged = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ enable: val });
        setEnable(val);
    }

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-invert") {

            console.log("InvertPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("InvertPanel: init config:", cfg);
                setEnable(cfg.enable);
            }
        }
    }, [props]);

    const reset_onClick = () => {
        setEnable(false);
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setEnable(false);
        props.onApply();
    }


    return (
        <>
            <Form.Item label="启用">
                <Switch checked={enable} onChange={enable_onChanged} />
            </Form.Item>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default InvertPanel;