import styled from "styled-components";

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 10em;
  background: papayawhip;
`;


const Home = () => {
    
    return (<Wrapper>
        <Title>
          ..PSMan/Demo..
        </Title>
      </Wrapper>)
  };
  
  export default Home;