import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";

const HISTORY_LIST = "DREAM_PROMPT_HISTORY"

function useHistory() {

    const [histories, setHistories] = useState(new Map())
    
    const addHistory = (name, positive, negative) => {
        histories.set(name, {positive, negative, time: (new Date()).toLocaleString()})
        setHistories(new Map(histories))
        save()
    }

    const delHistory = (name) => {
        histories.delete(name)
        setHistories(new Map(histories))
        save()
    }

    const save = () => {
        localStorage.setItem(HISTORY_LIST, JSON.stringify(Object.fromEntries(histories)))
    }

    useEffect(() => {
        const local = localStorage.getItem(HISTORY_LIST);
        if (local) {
            setHistories(new Map(Object.entries(JSON.parse(local))))
        }
    }, [])

    const historyList = useMemo(() => {
        const items = [];
        histories.forEach((v, k) => {
            items.push({
                ...v,
                name: k,
            });
        });
        items.sort((a, b) => {
            if (a.time < b.time) return 1
            if (a.time > b.time) return -1
            return 0
        })
        return items
    }, [histories]);

    return {
        historyList,
        addHistory,
        delHistory
    }
}

export const [useHistoryStore] = createGlobalStore(useHistory);