import * as React from 'react';

import { Form, Button, Radio, ColorPicker, Space } from "antd";
import BasePanel from './BasePanel';

function ZoomPanel(props) {

    // const [penRadius, setPenRadius] = React.useState(10);
    // const [blockRadius, setBlockRadius] = React.useState(10);
    // const [fillType, setFillType] = React.useState("color-fill");
    const [mode, setMode] = React.useState("zoom-in");

    // const [color, setColor] = React.useState("#ff000000");
    // const [gradMode, setGradMode] = React.useState(0);
    // const [gradStartColor, setGradStartColor] = React.useState("#ffff0000");
    // const [gradEndColor, setGradEndColor] = React.useState("#0000ff00");

    // const gmodeList = [
    //     { label: "线性", value: 0, name: "color-fill" },
    //     { label: "径向", value: 1, name: "gradient-fill" },
    //     // { label: "矩形", value: 2 },
    // ];


    React.useEffect(() => {
        if (props.tool === "canvas-zoom") {
            // changeTool()
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(canvas-zoom)>>>>>>:: ", nm, obj);

                if (nm == "tool::canvas-zoom-config-update") {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    console.log("ssssssssssssss", config);
                    // setBlockRadius(config.radius);
                    setMode(config.mode);
                }
            }
        }
    }, [props.tool])

    // React.useEffect(() => {
    //     changeTool()
    // }, [fillType]);

    const reset_onClick = (e) => {
        window.PSMan?.getInstance().toolManager().current()?.sendAction("reset", null);
    }

    const updateMode = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ mode: val });
        setMode(val);
    }

    return (
        <BasePanel className={props.className} title={"缩放:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item>
                    <Space>
                        <Radio.Group onChange={(e) => updateMode(e.target.value)} value={mode}>
                            <Radio value={"zoom-in"}>放大</Radio>
                            <Radio value={"zoom-out"}>缩小</Radio>
                        </Radio.Group>
                        <Button size="small" onClick={reset_onClick}>重置</Button>
                    </Space>
                </Form.Item>
            </Form>
        </BasePanel>
    );
}





export default ZoomPanel;