import { Button, Input, Layout, Menu, Modal, Select, Space, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import "./style.scss";
import _ from "lodash";
import { useControlNetStore } from "./store";
import { PlusOutlined, DeleteOutlined, EnterOutlined, SaveOutlined } from "@ant-design/icons";
import Unit from "./Unit";
import Empty from "../../components/Empty";

const defaultUnit = {
    type: "All",
    layer_id: -1,
    layer_name: "xxxx",
    module: "none",
    model: "None",
    weight: 1,
    resize_mode: 2,
    processor_res: 512,
    threshold_a: 64,
    threshold_b: 64,
    guidance_start: 0,
    guidance_end: 1,
    control_mode: 0,
    pixel_perfect: true,
    
};

function ControlNetPanel({ open, setOpen, reflayerId, originalUnits = [], onChange, ckptTitle }) {
    const store = useControlNetStore();

    const sdVersion = useRef(null);

    // const [layers, setLayers] = useState([{ label: "当前图层", value: -1 }]);

    const [curlayer, setCurlayer] = useState(null);

    useEffect(() => {
        if (open) {

            let ss =window.PSMan.getInstance()
            .layerManager()
            .state() ;
            console.log(" =================\n", ss, reflayerId);

            if (reflayerId < 0) {
                
                let clid = ss.cur;
                let cls = ss.layers.filter((l) => l.id == clid);
                setCurlayer([clid, cls[0].name]);

                console.log("ControlNetPanel :: CURLAYER ", reflayerId, [clid, cls[0].name]);

            } else {

                let cls = ss.layers.filter((l) => l.id == reflayerId);
                setCurlayer([reflayerId, cls[0].name]); 

                console.log("ControlNetPanel :: CURLAYER ", [reflayerId, cls[0].name]);
            }
            //setLayers(layers);
        } else {
            setPreset('');
        }
    }, [open]);

    useEffect(() => {
        const version = ckptTitle.toLowerCase().indexOf("xl") === -1 ? "" : "xl";
        if (sdVersion.current != null && version !== sdVersion.current) {
            setUnits([_.cloneDeep(defaultUnit)]);
            onChange([]);
        }
        sdVersion.current = version;
        store.setSdVersion(version);
    }, [ckptTitle]);

    const parseCheckedKeys = () => {
        return [];
    };

    // const [config, setConfig] = useState(_.cloneDeep(originalConfig));
    const [units, setUnits] = useState([_.cloneDeep(defaultUnit)]);
    const [checkedKeys, setCheckedKeys] = useState(parseCheckedKeys());

    useEffect(() => {
        setUnits(originalUnits.length > 0 ? [...originalUnits] : [_.cloneDeep(defaultUnit)]);
    }, [originalUnits]);

    const menuItems = useMemo(() => {
        let items = units.map((o, i) => {
            return {
                label: (
                    <Space key={i}>
                        {/* <Checkbox onClick={(e) => e.stopPropagation()} value={i} /> */}
                        <span>
                            ControlNet {i} {o.type === "All" ? "" : o.type}
                        </span>
                    </Space>
                ),
                key: i,
            };
        });
        return items;
    }, [units]);

    const handleChecked = (keys) => {
        console.log("ooo keys", keys);
        setCheckedKeys(keys);
    };

    const [activeTab, setActiveTab] = useState("0");
    const unitsCount = useRef(originalUnits.length);

    const unitIdx = useMemo(() => {
        return parseInt(activeTab);
    }, [activeTab]);

    useEffect(() => {
        const active = parseInt(activeTab);
        if (active >= units.length || units.length > unitsCount.current) {
            setActiveTab(`${units.length - 1}`);
        } else if (active < 0 && units.length > 0) {
            setActiveTab("0");
        }
        unitsCount.current = units.length;
    }, [units, activeTab]);

    const onSelect = ({ key }) => {
        setActiveTab(key);
    };

    const handleConfirm = () => {
        onChange(units);
        setOpen(false);
    };

    const handleCancel = () => {
        setUnits(originalUnits.length > 0 ? [...originalUnits] : [_.cloneDeep(defaultUnit)]);
        setOpen(false);
    };

    const addUnit = () => {
        setUnits([...units, _.cloneDeep(defaultUnit)]);
    };

    const removeUnit = () => {
        units.splice(unitIdx, 1);
        setUnits([...units]);
    };

    const updateUnit = (unit, index) => {
        if (units[index]) {
            units[index] = unit;
            setUnits([...units]);
        }
    };

    // preset

    const [preset, setPreset] = useState("");
    const [newPreset, setNewPreset] = useState("");

    const presetName = useRef(null);

    const [openSaveModal, setOpenSaveModal] = useState(false);

    const savePreset = () => {
        if (preset !== "") {
            setNewPreset(preset);
        } else {
            setNewPreset("");
        }
        setOpenSaveModal(true);
    };

    const removePreset = () => {
        if (preset === "") {
            return;
        }
        store.removePreset(preset);
        setPreset("");
    };

    const applyPreset = () => {
        if (!units[unitIdx]) {
            return;
        }
        const newUnit = preset === "" ? defaultUnit : store.getPreset(preset);
        const unit = Object.assign(units[unitIdx], newUnit);
        updateUnit(unit, unitIdx);
    };

    // drag

    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const draggleRef = useRef(null);

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal
            maskStyle={{ background: "none" }}
            centered
            open={open}
            width={800}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<span className="hidden-close"></span>}
            className="no-padding-modal control-net-modal"
            maskClosable={false}
            title={
                <div
                    className="layer-style-panel-title"
                    style={{
                        width: "100%",
                        cursor: "move",
                        textAlign: "center",
                        background: "#424242",
                        padding: 5,
                        borderRadius: "8px 8px 0 0",
                    }}
                >
                    ControlNet
                </div>
            }
            modalRender={(modal) => (
                <Draggable
                    handle=".layer-style-panel-title"
                    bounds={bounds}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <Layout style={{ background: "none", padding: 10 }}>
                <Sider
                    style={{
                        background: "none",
                        marginRight: 16,
                        paddingRight: 10,
                        paddingBottom: 30,
                        borderInlineEnd: "1px solid rgba(253, 253, 253, 0.12)",
                    }}
                >
                    {/* <Checkbox.Group style={{ display: "block" }} value={checkedKeys} onChange={handleChecked}> */}
                    <Menu
                        style={{ background: "none", border: "none" }}
                        itemProp={{ style: "color: red" }}
                        selectedKeys={[activeTab]}
                        onSelect={onSelect}
                        items={menuItems}
                        onClick={onSelect}
                    />
                    {/* </Checkbox.Group> */}

                    <div className="cn-actions">
                        <Tooltip title="添加 ControlNet" mouseEnterDelay={0.75}>
                            <Button
                                disabled={store.unitCount <= units.length}
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={addUnit}
                            />
                        </Tooltip>
                        <Tooltip title="删除 ControlNet" mouseEnterDelay={0.75}>
                            <Button
                                disabled={isNaN(unitIdx)}
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={removeUnit}
                            />
                        </Tooltip>
                    </div>
                </Sider>
                <Content className="layer-style-content" style={{ background: "none" }}>
                    <div className="no-bg-scrollbar layer-style-options" style={{ minHeight: 600 }}>
                        {units.map(
                            (o, i) =>
                                unitIdx === i && (
                                    <Unit key={i} curlayer={curlayer} unit={o} index={i} updateUnit={updateUnit} />
                                )
                        )}
                        {units.length === 0 && <Empty />}
                    </div>
                    <div className="layer-style-btns">
                        <div>
                            {units.length > 0 && (
                                <Space>
                                    <span>预设:</span>
                                    <Select
                                        onChange={(v) => setPreset(v)}
                                        value={preset}
                                        style={{ minWidth: 100 }}
                                        options={store.presetsOptions}
                                    />
                                    <Space className="preset-btns">
                                        <Tooltip title="应用此预设" mouseEnterDelay={0.75}>
                                            <Button onClick={applyPreset} type="text" icon={<EnterOutlined />}></Button>
                                        </Tooltip>
                                        <Tooltip title="删除预设" mouseEnterDelay={0.75}>
                                            <Button
                                                onClick={removePreset}
                                                disabled={preset === ''}
                                                type="text"
                                                icon={<DeleteOutlined />}
                                            ></Button>
                                        </Tooltip>
                                        <Tooltip title="保存当前预设" mouseEnterDelay={0.75}>
                                            <Button onClick={savePreset} type="text" icon={<SaveOutlined />}></Button>
                                        </Tooltip>
                                    </Space>
                                </Space>
                            )}
                        </div>
                        <div>
                            <Button onClick={handleCancel}>取消</Button>
                            <Button type="primary" onClick={handleConfirm}>
                                确定
                            </Button>
                        </div>
                    </div>
                </Content>
            </Layout>
            <Modal
                title="保存预设"
                okText="确认"
                cancelText="取消"
                maskStyle={{ background: "rgba(0, 0, 0, 0.8" }}
                centered
                onCancel={() => setOpenSaveModal(false)}
                open={openSaveModal}
                onOk={() => {
                    const name = presetName.current.input.value.trim();
                    if (name && units[unitIdx]) {
                        store.savePreset(name, units[unitIdx]);
                        setOpenSaveModal(false);
                        setPreset(name);
                    }
                }}
                afterOpenChange={(o) => {
                    if (o) presetName.current.focus();
                }}
            >
                <Input
                    placeholder="输入名称："
                    value={newPreset}
                    onChange={(e) => setNewPreset(e.target.value)}
                    ref={presetName}
                    autoFocus
                />
            </Modal>
        </Modal>
    );
}

export default ControlNetPanel;
