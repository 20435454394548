import { Button, Form, Slider, Select, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { ChannelModes } from "../../utils/defs";


function ChannelMixerPanel(props) {

    const [channel, setChannel] = useState(ChannelModes[0].value);
    const [intensityR, setIntensityR] = useState(100);
    const [intensityG, setIntensityG] = useState(0);
    const [intensityB, setIntensityB] = useState(0);
    const [intensityConst, setIntensityConst] = useState(0);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-channel-mixer") {

            console.log("ChannelMixerPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("ChannelMixerPanel: init config:", cfg);
                setChannel(cfg.channel);
                setIntensityR(cfg.r);
                setIntensityG(cfg.g);
                setIntensityB(cfg.b);
                setIntensityConst(cfg.a);
            }
        }
    }, [props]);

    const channel_onChange = (idx) => {
        setChannel(idx);
        window.PSMan?.getInstance().toolManager().current().setConfig({ channel: idx });
    };

    const intensityR_onChange = (newValue) => {
        if (newValue == null) { return; }
        setIntensityR(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ r: newValue });
    };
    const intensityG_onChange = (newValue) => {
        if (newValue == null) { return; }
        setIntensityG(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ g: newValue });
    };
    const intensityB_onChange = (newValue) => {
        if (newValue == null) { return; }
        setIntensityB(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ b: newValue });
    };
    const intensityConst_onChange = (newValue) => {
        if (newValue == null) { return; }
        setIntensityConst(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ a: newValue });
    };

    const reset_onClick = () => {
        setIntensityConst(0);
        setIntensityR(0);
        setIntensityG(0);
        setIntensityB(0);
        if (channel == ChannelModes[0].value) {
            setIntensityR(100);
            window.PSMan?.getInstance().toolManager().current().setConfig({ r: 100, g: 0, b: 0, a: 0 });
        }
        else if (channel == ChannelModes[1].value) {
            setIntensityG(100);
            window.PSMan?.getInstance().toolManager().current().setConfig({ r: 0, g: 100, b: 0, a: 0 });
        }
        else if (channel == ChannelModes[2].value) {
            setIntensityB(100);
            window.PSMan?.getInstance().toolManager().current().setConfig({ r: 0, g: 0, b: 100, a: 0 });
        }
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setChannel(0);
        setIntensityR(100);
        setIntensityG(0);
        setIntensityB(0);
        setIntensityConst(0);
        props.onApply();
    }


    return (
        <>
            <Form.Item label="输出通道">
                <Select value={channel} options={ChannelModes} onChange={channel_onChange}></Select>
            </Form.Item>

            <FormItem label="红色">
                <Slider min={-200} max={200} value={intensityR} onChange={intensityR_onChange} />
            </FormItem>
            <FormItem label="绿色">
                <Slider min={-200} max={200} value={intensityG} onChange={intensityG_onChange} />
            </FormItem>
            <FormItem label="蓝色">
                <Slider min={-200} max={200} value={intensityB} onChange={intensityB_onChange} />
            </FormItem>
            <FormItem label="常数">
                <Slider min={-200} max={200} value={intensityConst} onChange={intensityConst_onChange} />
            </FormItem>
            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default ChannelMixerPanel;