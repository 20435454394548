import { Button, Form, Slider, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";

function BrightnessContrastPanel(props) {

    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    // console.log("BrightnessContrastPanel ....... ", props);

    useEffect(() => {
        // console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYY \nBrightnessContrastPanel ....... ");

        if (props.tool === "adjustment" && props.subTool === "adjustment-brightness-contrast") {
            console.log("BrightnessContrastPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            // console.log("PSMan_onToolEvents(brightness-contrast)>>>>>>:: ", nm, obj);
            // }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("BrightnessContrastPanel: init config:", cfg);
                setBrightness(cfg.brightness);
                setContrast(cfg.contrast);
            }
        }
    }, [props]);


    const brightness_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBrightness(newValue);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ brightness: newValue });
    };

    const contrast_onChange = (newValue) => {
        if (newValue == null) { return; }
        setContrast(newValue);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ contrast: newValue });
    };

    const reset_onClick = () => {
        setBrightness(0);
        setContrast(0);
        window.PSMan?.getInstance().toolManager().current().setConfig({ brightness: 0, contrast: 0 });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setBrightness(0);
        setContrast(0);
        props.onApply();
    }


    return (
        // <Form size="middle" labelCol={{ span: 6 }} labelAlign="left" >
        <>
            <FormItem label="亮度">
                <Slider min={-100} max={100} value={brightness} onChange={brightness_onChange} />
            </FormItem>
            <FormItem label="对比度">
                <Slider min={-100} max={100} value={contrast} onChange={contrast_onChange} />
            </FormItem>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
            {/* </Form > */}
        </>
    );
}

export default BrightnessContrastPanel;