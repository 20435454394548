import { Button, Form, Slider, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";


function GradientMapPanel(props) {

    const DEFAULT_THRESHOLD = 127;
    const [threshold, setThreshold] = useState(DEFAULT_THRESHOLD);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-gradient-map") {

            console.log("GradientMapPanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("GradientMapPanel: init config:", cfg);
                setThreshold(cfg.threshold);
            }
        }
    }, [props]);

    const threshold_onChange = (newValue) => {
        if (newValue == null) { return; }
        setThreshold(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ threshold: newValue });
    };

    const reset_onClick = () => {
        setThreshold(DEFAULT_THRESHOLD);
        window.PSMan?.getInstance().toolManager().current().setConfig({ threshold: DEFAULT_THRESHOLD });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setThreshold(DEFAULT_THRESHOLD);
        props.onApply();
    }


    return (
        <>
            <FormItem label="UI未实现 请参考 PhotoShop->调整->渐变映射">
                <Slider min={1} max={255} value={threshold} onChange={threshold_onChange} />
            </FormItem>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default GradientMapPanel;