


const makeDb = (db) => {

    if (!db.objectStoreNames.contains("config")) {
        var storeOS = db.createObjectStore("config");
        console.log(" ... + ", storeOS);
    }
};

function loadConfig(cb) {
    let db;
    var openRequest = indexedDB.open("psman_config", 1);

    openRequest.onupgradeneeded = (e) => {
        var db = e.target.result;
        console.log("onRet : ", 0);
        makeDb(db);
    };
    openRequest.onsuccess = (e) => {
        var db = e.target.result;
        // console.log("onRet : ", bv);
        // makeDb(db);
        var transaction = db.transaction(["config"], "readonly");
        var store = transaction.objectStore("config");

        var request = store.get("canvas");

        request.onsuccess = function () {
            var result = request.result;
            console.log(result);
            cb(result);
        };

    };

    openRequest.onerror = function (e) {
        console.log("onerror!");
        console.dir(e);
    }
}



function saveConfig(data, cb) {

    let db;
    var openRequest = indexedDB.open("psman_config", 1);

    openRequest.onupgradeneeded = (e) => {
        var db = e.target.result;
        console.log("onRet : ", 1);
        makeDb(db);
    };
    openRequest.onsuccess = (e) => {
        var db = e.target.result;
        // console.log("onRet : ", bv);
        // makeDb(db);
        var transaction = db.transaction(["config"], "readwrite");
        var store = transaction.objectStore("config");

        var request = store.put(data, "canvas");

        request.onerror = function (e) {
            console.log("Error", e.target.error.name);
        }
        request.onsuccess = function (e) {
            console.log("Woot! Did it");
            cb(data);
        }
    };

    openRequest.onerror = function (e) {
        console.log("onerror!");
        console.dir(e);
    }






}


export { loadConfig, saveConfig }