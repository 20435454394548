import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";

function detectVersion(name) {
    return name.toLowerCase().indexOf("xl") === -1 ? "" : "xl";
}

function useControlNet() {
    const API_URL = process.env.REACT_APP_API_URL;

    const [sdVersion, setSdVersion] = useState("");

    const [settingsConfig, setSettingsConfig] = useState({});
    const [controlTypesConfig, setControlTypesConfig] = useState({});
    const [modelListConfig, setModelListConfig] = useState({});
    const [moduleListConfig, setModuleListConfig] = useState({});

    const [presets, setPresets] = useState({});

    const modelVersions = useMemo(() => {
        if (!modelListConfig["model_list"]) {
            return {};
        }
        const versions = {};
        modelListConfig["model_list"].forEach((m) => {
            versions[m] = detectVersion(m);
        });
        return versions;
    }, [modelListConfig]);

    const unitCount = useMemo(() => {
        return settingsConfig["control_net_unit_count"] ?? 3;
    }, [settingsConfig]);

    const controlTypes = useMemo(() => {
        if (!controlTypesConfig["control_types"]) {
            return {};
        }
        const types = controlTypesConfig["control_types"];
        let filteredTypes = {};
        Object.keys(types).forEach((k) => {
            const { default_model, default_option, model_list, module_list } = types[k];

            const filteredModelList = model_list.filter((x) => x === "None" || modelVersions[x] === sdVersion);

            let defaultModel = "None";
            if (filteredModelList.includes(default_model)) {
                defaultModel = default_model;
            } else if (filteredModelList.length > 1) {
                defaultModel = filteredModelList[1];
                for (const m of filteredModelList) {
                    if (m.split("[")[0].includes("11")) {
                        defaultModel = m;
                        break;
                    }
                }
            }

            const filteredModuleList =
                k === "IP-Adapter"
                    ? module_list.filter((x) => x === "none" || detectVersion(x) === sdVersion)
                    : module_list;

            let defaultOption = "none";
            if (filteredModuleList.includes(default_option)) {
                defaultOption = default_option;
            } else if (filteredModuleList.length > 1) {
                defaultOption = filteredModuleList[1];
            }

            filteredTypes[k] = {
                default_model: defaultModel,
                default_option: defaultOption,
                model_list: filteredModelList,
                module_list: filteredModuleList,
            };
        });
        return filteredTypes;
    }, [controlTypesConfig, modelVersions, sdVersion]);

    const moduleDetail = useMemo(() => {
        return moduleListConfig["module_detail"] ?? {};
    }, [moduleListConfig]);

    const controlTypeOptions = useMemo(() => {
        return Object.keys(controlTypes).map((k) => ({
            value: k,
            label: k,
        }));
    }, [controlTypes]);

    const loadConfig = async () => {
        const [settingsResp, controlTypesResp, modelListResp, moduleListResp] = await Promise.all([
            fetch(`${API_URL}/controlnet/settings`),
            fetch(`${API_URL}/controlnet/control_types`),
            fetch(`${API_URL}/controlnet/model_list`),
            fetch(`${API_URL}/controlnet/module_list?alias_names=true`),
        ]);
        const [settingsJson, controlTypesJson, modelListJson, moduleListJson] = await Promise.all([
            settingsResp.json(),
            controlTypesResp.json(),
            modelListResp.json(),
            moduleListResp.json(),
        ]);
        setSettingsConfig(settingsJson);
        setControlTypesConfig(controlTypesJson);
        setModelListConfig(modelListJson);
        setModuleListConfig(moduleListJson);
        loadPresets();
    };

    useEffect(() => {
        loadConfig();
    }, []);

    const loadPresets = () => {
        const rowData = localStorage.getItem("CN_PRESETS");
        if (rowData) {
            setPresets(JSON.parse(rowData));
        }
    };

    const getPreset = (name) => {
        return presets[name] ?? null;
    };

    const savePreset = (name, unit) => {
        let _unit = Object.assign({}, unit);
        let _presets = Object.assign({}, presets);
        delete _unit["layer_id"];
        _presets[name] = _unit;
        localStorage.setItem("CN_PRESETS", JSON.stringify(_presets));
        setPresets(_presets);
    };

    const removePreset = (name) => {
        if (!presets[name]) {
            return;
        }
        delete presets[name];
        let _presets = Object.assign({}, presets);
        localStorage.setItem("CN_PRESETS", JSON.stringify(_presets));
        setPresets(_presets);
    };

    const presetsOptions = useMemo(() => {
        let options = Object.keys(presets).map((p) => ({ value: p, label: p }));
        options.unshift({ value: '', label: "无" });
        return options;
    }, [presets]);

    return {
        unitCount,
        controlTypes,
        moduleDetail,
        controlTypeOptions,
        setSdVersion,
        savePreset,
        getPreset,
        removePreset,
        presetsOptions,
    };
}

export const [useControlNetStore] = createGlobalStore(useControlNet);
