import * as React from 'react';

import { Select, Col, Row, Image, Space, Button, InputNumber } from 'antd';
import { Form, Slider } from "antd";
import BasePanel from './BasePanel';
import { hexToRGB } from "../utils/util"


function UpscalerPanel(props) {

    // console.log("PenPanel::init............................ : " + window.PSMan);


    const [upscalerScale, setUpscalerScale] = React.useState(1.0);

    const [upscalerList, setUpscalerList] = React.useState([]);
    const [upscalerMap, setUpscalerMap] = React.useState({});


    const [upscaler1, setUpscaler1] = React.useState("-");
    const [upscaler2, setUpscaler2] = React.useState("-");

    const [upscaler2Vis, setUpscaler2Vis] = React.useState(0.0);

    const [codeFormerVis, setCodeFormerVis] = React.useState(0.0);
    const [codeFormerWeight, setCodeFormerWeight] = React.useState(0.0);


    React.useEffect(() => {
        let lmap = new Map(upscalerList.map((obj) => [obj.value, obj]));
        console.log("xxxxxxxxxxxxxxxxxx :UpscalerMap ", lmap);
        setUpscalerMap(lmap);
    },
        [upscalerList]);

    React.useEffect(() => {
        if (props.tool == "upscaler") {

            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(upscaler)>>>>>>:: ", nm, obj);
                // if (nm == "tool::upscaler-config-update") {
                //     let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
                //     if (cfg != null) {
                //         // setUpscalerName(cfg.upscaler_name);
                //         // setUpscalerScale(cfg.upscalar_scale);

                //         setUpscalerScale(cfg.scale);
                //         setUpscaler2Vis(cfg.upscaler2_vis);
                //         setCodeFormerVis(cfg.codeformer_vis);
                //         setCodeFormerWeight(cfg.codeformer_weight);
                //         var tar = upscalerMap.get(cfg.upscaler1);
                //         if (typeof tar !== "undefined") {
                //             // console.log("============= 0000000000000000000000000000000000000", cfg.upscaler1, tar);
                //             setUpscaler1(tar.value);
                //         }

                //         var tar = upscalerMap.get(cfg.upscaler2);
                //         if (typeof tar !== "undefined") {
                //             // console.log("============= 0000000000000000000000000000000000000", cfg.upscaler2, tar);
                //             setUpscaler2(tar.value);
                //         }

                //     }
                // } else 
                if (nm === "tool::upscaler-list") {
                    let l = obj.map((item, idx) => ({ key: idx, value: item.name, label: item.model_name || item.name }));
                    setUpscalerList(l);
                    // console.log(l);
                    // setUpscaler1(l[0].value);
                    // setUpscaler2(l[0].value);
                }
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg, upscalerList, upscalerMap);

                // setUpscalerName(cfg.upscaler_name);
                setUpscalerScale(cfg.scale);
                setUpscaler2Vis(cfg.upscaler2_vis);
                setCodeFormerVis(cfg.codeformer_vis);
                setCodeFormerWeight(cfg.codeformer_weight);

                var tar = upscalerMap.get(cfg.upscaler1);
                if (typeof tar !== "undefined") {
                    console.log("============= 0000000000000000000000000000000000000", cfg.upscaler1, tar);
                    setUpscaler1(tar.value);
                }

                var tar = upscalerMap.get(cfg.upscaler2);
                if (typeof tar !== "undefined") {
                    console.log("============= 0000000000000000000000000000000000000", cfg.upscaler2, tar);
                    setUpscaler2(tar.value);
                }


            }
        }

    }, [props.tool])

    const updateUpscalerScale = (val) => {
        setUpscalerScale(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ scale: val });
    }
    const upscalerName_onChange = (idx, nm) => {
        // let us = upscalerList[nm];
        console.log("FaceSwapPanel::upscalerName_onChange", nm, idx);

        let tar = upscalerMap.get(nm);
        if (idx == 1) {
            setUpscaler1(tar.value);
            window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscaler1: tar.value });
        } else {
            // setUpscaler2(us);
            setUpscaler2(tar.value);
            window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscaler2: tar.value });
        }
        // setUpscalerName(nm);
        // window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscaler_name: nm });
    }
    const updateUpscaler2Vis = (val) => {
        setUpscaler2Vis(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ upscaler2_vis: val });
    }

    const updateCodeFormerVis = (val) => {
        setCodeFormerVis(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ codeformer_vis: val });
    }
    const updateCodeFormerWeight = (val) => {
        setCodeFormerWeight(val);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ codeformer_weight: val });
    }



    const preview_onClick = () => {
        console.log("FilterPanel:: preview_onClick");
        // setFilter(null)
        window.PSMan.getInstance().toolManager().current().sendAction("preview", {});
    };

    const cancal_onClick = () => {
        console.log("FilterPanel:: cancal_onClick");
        // setFilter(null)
        window.PSMan.getInstance().toolManager().current().sendAction("cancel", {});
    };

    const apply_onClick = () => {
        console.log("UpscalerPanel:: apply_onClick");
        // setFilter(null)
        window.PSMan.getInstance().toolManager().current().sendAction("apply", {});
    };



    return (
        <BasePanel className={props.className} title={"Upscaler:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">



                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={8}> 缩放系数:</Col>
                        <Col span={8}>
                            <Slider min={1} max={4} step={0.1}
                                value={upscalerScale} onChange={updateUpscalerScale} />
                        </Col>
                        <Col span={4}>
                            <InputNumber min={1} max={4} step={0.1} precision={2}
                                value={upscalerScale} onChange={updateUpscalerScale} />
                        </Col>
                    </Row>

                </Form.Item>

                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={8}> Upscaler 1:</Col>
                        <Col span={16}>
                            <Select value={upscaler1} options={upscalerList} onChange={(val) => upscalerName_onChange(1, val)}></Select>
                        </Col>
                    </Row>

                </Form.Item>

                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={10}> Upscaler 2:</Col>
                        <Col span={14}>
                            <Select value={upscaler2} options={upscalerList} onChange={(val) => upscalerName_onChange(2, val)}></Select>
                        </Col>
                    </Row>

                </Form.Item>

                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={10}> Upscaler 2 可见度:</Col>
                        <Col span={14}>
                            <Slider value={upscaler2Vis} min={0} max={1} step={0.05} onChange={updateUpscaler2Vis} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={12}> CodeFormer可见度:</Col>
                        <Col span={12}>
                            <Slider value={codeFormerVis} min={0} max={1} step={0.05} onChange={updateCodeFormerVis} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item className="ant-form-item">
                    <Row align="middle">
                        <Col span={12}> CodeFormer权重:</Col>
                        <Col span={12}>
                            <Slider value={codeFormerWeight} min={0} max={1} step={0.05} onChange={updateCodeFormerWeight} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item>
                    <Space style={{ width: "100%", justifyContent: "flex-end" }}>

                        <Button size="small" onClick={preview_onClick}>
                            预览
                        </Button>
                        <Button size="small" onClick={cancal_onClick}>
                            取消
                        </Button>
                        <Button size="small" onClick={apply_onClick} type="primary">
                            应用
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
        </BasePanel>
    );
}





export default UpscalerPanel;