import { createGlobalStore } from "hox";
import { useEffect, useMemo, useState } from "react";
import { searchCache } from "../../../utils/cache";
import { useSettingsStore } from "./setting";
import { group } from "../../../utils/util";

function useTag() {
    const settings = useSettingsStore();

    const [ct, setCt] = useState({});
    const [tags, setTags] = useState([]);
    const [tagsPostCount, setTagsPostCount] = useState({});

    const tagsByCategory = useMemo(() => {
        return group(
            tags.filter((t) => !t.restricted),
            (t) => t.category.join("/")
        );
    }, [tags]);

    const categorySize = useMemo(() => {
        const size = {};
        for (const tag of tags) {
            const key = tag.category.join("/");
            size[key] = (size[key] ?? 0) + 1;
        }
        return size
    }, [tags])

    const categoryList = useMemo(() => {
        const dedup = {};
        const filtered = tags
            .filter((t) => !t.restricted)
            .map(({ category }) => category.join("/"))
            .filter((e) => !(dedup[e] = e in dedup))
            .sort()
            .map((e) => e.split("/"));
        return filtered
    }, [tags]);

    const searchCategory = (category, query) => {
        if (!tagsByCategory[category.join("/")]) return [];

        if (query === "")
            return tagsByCategory[category.join("/")].sort(
                (k1, k2) => (tagsPostCount[k2.key] ?? 0) - (tagsPostCount[k1.key] ?? 0)
            );

        const lcQuery = query.toLowerCase();
        const normalizedLcQuery = lcQuery
            .split(/_|\s/)
            .filter((n) => !!n)
            .sort((a, b) => b.length - a.length);
        const cacheKey = JSON.stringify(["ByCategory", category, normalizedLcQuery]);
        const cached = searchCache.get(cacheKey);
        if (cached) return cached;
        const result = normalizedLcQuery
            .reduce(
                (a, q) =>
                    a
                        .map((meta) => {
                            let score = meta.score;
                            if (meta.key.toLowerCase() === lcQuery) score += 300;
                            if (meta.key.toLowerCase().includes(q)) score += 100;
                            if (meta.name.toLowerCase() === lcQuery) score += 50;
                            if (meta.name.toLowerCase().includes(q)) score += 50;
                            if (meta.alias?.some((a) => a.toLowerCase() === lcQuery)) score += 90;
                            if (meta.alias?.some((a) => a.toLowerCase().includes(q))) score += 70;
                            if (meta.description?.toLowerCase() === q) score += 40;
                            if (meta.description?.toLowerCase().includes(q)) score += 25;
                            return { ...meta, score };
                        })
                        .filter((v) => v.score > 0),
                tags
                    .filter((v) => v.category.join("/") === category.join("/") && !v.restricted)
                    .map((v) => ({ ...v, score: 0 }))
            )
            .sort((va, vb) => vb.score - va.score);
        searchCache.set(cacheKey, result);
        return result;
    };
    
    const allTagsWithAlias = useMemo(() => {
        return Object.fromEntries(
            tags
                .filter((t) => !t.restricted)
                .flatMap((t) => {
                    if (t.alias) {
                        return [[t.key, t], ...t.alias.map((a) => [a, t])]
                    } else {
                        return [[t.key, t]]
                    }
                })
        )
    }, [tags])

    const resolve = (name) => {
        name = name.replaceAll('_', ' ').toLowerCase()
        const meta = allTagsWithAlias[name]
        if (meta) {
            return { name: meta.originalName, meta }
        }
        return null
    }

    useEffect(() => {
        const load = async () => {
            const webpackContext = require.context("../data", true, /\.ya?ml$/);
            const yamlFiles = webpackContext.keys();
            const result = yamlFiles.map((f) => webpackContext(f).default);
            const curTool = {}
            const ptags = result.flatMap((p) => {
                return Object.entries(p.content).map(([k, t]) => {
                    ct[k] = t.name
                    if (t.alias) {
                        for (let a of t.alias) {
                            ct[a] = t.name
                        }
                    }
                    return {
                        ...t,
                        key: k.replaceAll("_", " ").toLowerCase(),
                        category: [...p.category, p.name],
                        restricted: t.restricted || p.restricted,
                    };
                });
            });
            setCt(ct)
            setTags(ptags);
            const { default: count } = await import("../data/danbooru_tag_post_count.json");
            setTagsPostCount(count);
        };
        load();
    }, []);

    const searchAll = (query) => {
        if (query === '') return []
        let result = []

        const lcQuery = query.toLowerCase()
        const normalizedLcQuery = lcQuery
            .split(/_|\s/)
            .filter((n) => !!n)
            .sort((a, b) => b.length - a.length)
        const cacheKey = JSON.stringify(['Global', normalizedLcQuery])
        const cached = searchCache.get(cacheKey)
        if (cached) return cached
        result = normalizedLcQuery
            .reduce(
                (a, q) =>
                    a
                        .map((meta) => {
                            let score = meta.score
                            if (meta.key.toLowerCase() === lcQuery)
                                score += 300
                            if (meta.key.toLowerCase().includes(q))
                                score += 100
                            if (meta.name.toLowerCase() === lcQuery)
                                score += 50
                            if (meta.name.toLowerCase().includes(q))
                                score += 50
                            if (
                                meta.alias?.some(
                                    (a) => a.toLowerCase() === lcQuery
                                )
                            )
                                score += 90
                            if (
                                meta.alias?.some((a) =>
                                    a.toLowerCase().includes(q)
                                )
                            )
                                score += 70
                            if (meta.description?.toLowerCase() === q)
                                score += 40
                            if (meta.description?.toLowerCase().includes(q))
                                score += 25
                            if (
                                meta.category?.some(
                                    (c) => c.toLowerCase() === q
                                )
                            )
                                score += 15
                            if (
                                meta.category?.some((c) =>
                                    c.toLowerCase().includes(q)
                                )
                            )
                                score += 7
                            return { ...meta, score }
                        })
                        .filter(({ score }) => score > 0),
                tags
                    .filter((v) => settings.showRestricted || !v.restricted)
                    .map((t) => ({ ...t, score: 0 }))
            )
            .sort(({ score: a }, { score: b }) => b - a)
        searchCache.set(cacheKey, result)
        return result
    }

    const toCt = (t) => {
        return ct[t] ?? t;
    }

    return {
        toCt,
        tagsPostCount,
        categoryList,
        categorySize,
        searchCategory,
        resolve,
        searchAll
    };
}

export const [useTagStore] = createGlobalStore(useTag)