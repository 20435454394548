import { Form, Select, Image } from "antd";
import React, { useEffect, useState } from "react";
import BasePanel from "./BasePanel";
import { Button as AButton, Space, Switch } from 'antd';
import { ReloadOutlined, UserOutlined } from '@ant-design/icons';
import 'ag-grid-community/styles/ag-theme-alpine.css';


function ImagePanel(props) {

    const [images, setImages] = useState([])
    const [currentImage, setCurrentImage] = useState('')
    const [snapping, setSnapping] = React.useState(false);

    useEffect(() => {
        if (props.tool === "image") {
            // handleRefreshImages();
            // console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(image)>>>>>>:: ", nm, obj);

                if (nm.startsWith("tool::image")) {
                }
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                // console.log("xxxxxxxxxxxxxxxxxx :YYYY ", cfg);
                updateImage(cfg.image);
                setSnapping(cfg.snapping);

            //     //setColor(cfg.color);
            }
            handleRefreshImages();
        }
    }, [props.tool])


    const updateImage = (image) => {
        console.log("imageimageimageimage",image);

        setCurrentImage(image);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({
            image: image
        });
    }

    useEffect(() => {
        window.PSMan?.getInstance().toolManager().current().setConfig({
            snapping: snapping
        });
    }, [snapping])

    const handleRefreshImages = () => {
        let flist = window.PSMan.getInstance().resourceManager().list("/psman");
        flist = flist.filter((item) => (item.path.endsWith('.png') || item.path.endsWith('.jpg') || item.path.endsWith('.jpeg')));

        for (let index = 0; index < flist.length; index++) {
            const file = flist[index];

            file.label = (<ThumbnailComponent file={file} />)
        }

        setImages(flist);
        if (flist.length > 0 && currentImage.length == 0) {
            updateImage(flist[0].path)
        }
    };


    function ThumbnailComponent(props) {

        const [imgURL, setImgURL] = useState();

        useEffect(() => {
            let imp = props.file.path;
            let openRequest = window.indexedDB.open("/psman", 21);
            openRequest.onerror = function (event) {
                console.log("打开IndexedDB('/psman', 21) 失败!!", event);
            };
            openRequest.onsuccess = function () {
                let db = openRequest.result;
                var transaction = db.transaction(["FILE_DATA"]);
                var objectStore = transaction.objectStore("FILE_DATA");

                var request = objectStore.get(imp);
                request.onerror = function (event) {
                    console.log("Unable to retrieve daa from database!");
                    // return "";
                };
                request.onsuccess = function (event) {
                    var imgFile = request.result;
                    let blob = new Blob([imgFile.contents], { type: "image/png" });
                    let urlObj = window.URL.createObjectURL(blob);
                    setImgURL(urlObj);
                };
            };

        }, [props.file.path]);


        return (
            <Space
                style={{ width: "100%" }}
                onClick={() => {
                    updateImage(props.file.path);
                }}
            >
                <Image style={{ objectFit: "contain", height: 36, width: 36 }} src={imgURL} />
                <label> {props.file.name}</label>
            </Space>
        );
    }


    return (
        <BasePanel className={props.className} title={"图片:"}>
            <Form size="middle" labelCol={{ span: 10 }} labelAlign="left">
                <Form.Item>
                    <Space.Compact>
                        <Form.Item noStyle>
                            <Select
                                value={currentImage}
                                onChange={updateImage}
                                options={images}
                                style={{ width: 260 }}
                            ></Select>
                        </Form.Item>

                        <Form.Item>
                            <AButton icon={<ReloadOutlined />} onClick={handleRefreshImages} />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Form.Item label="对齐到网格">
                    <Switch checked={snapping} onChange={setSnapping} />
                </Form.Item>
            </Form>

        </BasePanel>
    );
}

export default ImagePanel;
