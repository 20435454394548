import "./style/LiteralWeightIdentifier.scss";

import { useMemo, useRef, useState } from "react";
import Decimal from "decimal.js-light";
import { InputNumber } from "antd";


function LiteralWeightIdentifier({weight, updateWeight}) {

    const boundWeight = useMemo(() => weight.toDecimalPlaces(3).toNumber(), [weight]);
    const color = useMemo(() => (weight.gte(1) ? 'success' : 'warning'), [weight]);
    const computedWeight = useMemo(() => weight?.toFixed(3), [weight])

    const [oldWeight, setOldWeight] = useState(weight)
    const [editMode, setEditMode] = useState(false)

    const inputBox = useRef(null)

    function turnOnEditMode() {
        setOldWeight(new Decimal(weight))
        setEditMode(true)
        inputBox.current?.focus();
    }

    function cancelEditMode() {
        updateWeight(oldWeight);
        setEditMode(false)
    }

    const handleKeyUp = (e) => {
        if (e.key === "Enter") {
            setEditMode(false)
        }
    }

    return (
        <>
            {boundWeight !== 1 &&
                <span className={`nowrap ${color}`} onDoubleClick={turnOnEditMode}>
                    <span className={editMode ? "hidden" : ""}>x{computedWeight}</span>
                    <InputNumber
                        value={boundWeight}
                        onChange={(v) => updateWeight(new Decimal(v))}
                        size="small"
                        min={0}
                        step={0.001}
                        draggable="false"
                        onBlur={() => setEditMode(false)}
                        onKeyUp={handleKeyUp}
                        ref={inputBox}
                        className={`edit ${editMode ? "" : "hidden"}`}
                    />
                </span>
            }
        </>
    );
}

export default LiteralWeightIdentifier;