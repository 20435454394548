import * as React from "react";

import { Form, Select, ColorPicker, InputNumber, Divider } from "antd";
import BasePanel from "./BasePanel";
import { blendModes } from "../utils/defs"
import { hexToRGB, rgbaToHex } from "../utils/util"


function ShapePanel(props) {

    const MODE_RECTANGLE = 0;
    const MODE_ELLIPSE = 1;
    const MODEL_POLYSTAR = 2;

    const shapeModes = [
        { label: '矩形', value: MODE_RECTANGLE },
        { label: '椭圆', value: MODE_ELLIPSE },
        { label: '多边形', value: MODEL_POLYSTAR }
    ]

    const strokeJoins = [
        { label: "平头", value: 0 },
        { label: "圆头", value: 1 },
        { label: "矩形", value: 2 },
    ];

    const strokePositions = [
        { label: "外部", value: 0 },
        { label: "内部", value: 1 },
        { label: "居中", value: 2 },
    ];


    const defaultShapeMode = MODE_RECTANGLE;
    const defaultStrokeJoin = "平头";

    const [shapeMode, setShapeMode] = React.useState(defaultShapeMode)

    const [width, setWidth] = React.useState(10)
    const [height, setHeight] = React.useState(10)
    const [rotation, setRotation] = React.useState(0)
    const [polystarPoints, setPolystarPoints] = React.useState(6)
    const [polystarOuterRadius, setPolystarOuterRadius] = React.useState(50);
    const [polystarOuterRoundedness, setPolystarOuterRoundedness] = React.useState(0)

    const [fillColor, setFillColor] = React.useState("#ffff00ff");

    const [strokeColor, setStrokeColor] = React.useState("#ff00ffff");
    const [strokeWidth, setStrokeWidth] = React.useState(20)
    const [strokePos, setStrokePos] = React.useState(strokePositions[0]);
    const [strokeJoin, setStrokeJoin] = React.useState(defaultStrokeJoin);

    React.useEffect(() => {
        if (props.tool == "shape") {

            console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(shape)>>>>>>:: ", nm, obj);

                if (nm.startsWith("tool::shape-config-update")) {
                    let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();

                    console.log("shape cfg : ", cfg);

                    setWidth(cfg["size"][0]);
                    setHeight(cfg["size"][1]);
                    setRotation(cfg["rotation"]);

                    setShapeMode(cfg["mode"]);
                    setFillColor(rgbaToHex(cfg["fill_color"]));
                    setStrokeColor(rgbaToHex(cfg["stroke_color"]));
                    setStrokeWidth(cfg["stroke_width"]);
                    setStrokePos(cfg["stroke_pos"]);
                    setStrokeJoin(cfg["stroke_join"]);
                    setPolystarPoints(cfg["polystar_points"]);
                    setPolystarOuterRadius(cfg["polystar_outer_radius"]);
                    setPolystarOuterRoundedness(cfg["polystar_outer_roundedness"]);

                }
            }

            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {

                setWidth(cfg["size"][0]);
                setHeight(cfg["size"][1]);
                setRotation(cfg["rotation"]);

                setShapeMode(cfg["mode"]);
                setFillColor(rgbaToHex(cfg["fill_color"]));
                setStrokeColor(rgbaToHex(cfg["stroke_color"]));
                setStrokeWidth(cfg["stroke_width"]);
                setStrokePos(cfg["stroke_pos"]);
                setStrokeJoin(cfg["stroke_join"]);
                setPolystarPoints(cfg["polystar_points"]);
                setPolystarOuterRadius(cfg["polystar_out_radius"]);
                setPolystarOuterRoundedness(cfg["polystar_outer_roundedness"]);
            }
        }

    }, [props.tool])


    const shapeMode_onChange = (idx) => {
        console.log("ShapePanel::shapeMode_onChange", idx);
        setShapeMode(idx);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ mode: idx });
    }

    const height_onChange = (newValue) => {
        console.log("ShapePanel::height_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setHeight(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ size: [width, newValue] });
    };

    const width_onChange = (newValue) => {
        console.log("ShapePanel::width_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setWidth(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ size: [newValue, height] });
    };

    const polystarPoints_onChange = (newValue) => {
        console.log("ShapePanel::polystarPoints_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setPolystarPoints(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ polystar_points: newValue });
    };

    const polystarOuterRadius_onChange = (newValue) => {
        console.log("ShapePanel::polystarOuterRadius_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setPolystarOuterRadius(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ polystar_outer_radius: newValue });
    };

    const polystarOuterRoundedness_onChange = (newValue) => {
        console.log("ShapePanel::polystarOuterRoundedness_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setPolystarOuterRoundedness(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ polystar_outer_roundedness: newValue });
    };

    const rotation_onChange = (newValue) => {
        console.log("ShapePanel::rotation_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setRotation(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ rotation: newValue });
    };

    const fillColor_onChange = (newValue) => {
        console.log("ShapePanel::fillColor_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setFillColor(newValue.toHexString());
        window.PSMan.getInstance().toolManager().current().setConfig({ fill_color: hexToRGB(newValue.toHexString()) });
    };

    const strokeColor_onChange = (newValue) => {
        console.log("ShapePanel::stroke_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setStrokeColor(newValue.toHexString());
        window.PSMan.getInstance().toolManager().current().setConfig({ stroke_color: hexToRGB(newValue.toHexString()) });

        console.log("ShapePanel::strokeColor_onChange hex", newValue.toHexString());

    };

    const strokeWidth_onChange = (newValue) => {
        console.log("ShapePanel::strokeWidth_onChange", newValue);
        if (newValue == null) {
            return;
        }
        setStrokeWidth(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ stroke_width: newValue });
    };

    const strokePos_onChange = (idx) => {
        setStrokePos(idx);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ stroke_pos: idx });
    };

    const strokeJoin_onChange = (idx) => {
        setStrokeJoin(idx);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ stroke_join: idx });
    }


    return (
        <BasePanel className={props.className} title={"形状:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item label={"类型"}>
                    <Select value={shapeMode} options={shapeModes} onChange={shapeMode_onChange}></Select>
                </Form.Item>
                <Divider orientation="left" plain>
                    参数:
                </Divider>
                <div className={shapeMode === MODEL_POLYSTAR ? "hidden" : ""}>
                    <Form.Item label={"长"}>
                        <InputNumber onChange={height_onChange} min={1} max={100000} value={height} />
                    </Form.Item>
                    <Form.Item label={"宽"}>
                        <InputNumber onChange={width_onChange} min={1} max={100000} value={width} />
                    </Form.Item>

                </div>
                <div className={shapeMode === MODEL_POLYSTAR ? "" : "hidden"}>
                    <Form.Item label={"顶点数"}>
                        <InputNumber onChange={polystarPoints_onChange} min={3} max={100000} value={polystarPoints} />
                    </Form.Item>
                    <Form.Item label={"外径"}>
                        <InputNumber onChange={polystarOuterRadius_onChange} min={1} max={100000} value={polystarOuterRadius} />
                    </Form.Item>
                    <Form.Item label={"外圆度"}>
                        <InputNumber onChange={polystarOuterRoundedness_onChange} min={0} max={100000} value={polystarOuterRoundedness} />
                    </Form.Item>
                </div>

                <Form.Item label={"旋转角度"}>
                    <InputNumber onChange={rotation_onChange} min={0} max={360} value={rotation} />
                </Form.Item>

                <Divider orientation="left" plain>
                    填充:
                </Divider>
                <Form.Item label={"颜色"}>
                    <ColorPicker value={fillColor} onChange={fillColor_onChange} />
                </Form.Item>
                <Divider orientation="left" plain>
                    描边:
                </Divider>
                <Form.Item label="颜色">
                    <ColorPicker value={strokeColor} onChange={strokeColor_onChange} />
                </Form.Item>
                <Form.Item label="宽度">
                    <InputNumber onChange={strokeWidth_onChange} min={0} max={100000} value={strokeWidth} />
                </Form.Item>
                <Form.Item label={"对齐"}>
                    <Select value={strokePos} options={strokePositions} onChange={strokePos_onChange}></Select>
                </Form.Item>
                <Form.Item label="角点">
                    <Select value={strokeJoin} options={strokeJoins} onChange={strokeJoin_onChange}></Select>
                </Form.Item>

                <Form.Item></Form.Item>
            </Form>
        </BasePanel>
    );
}

export default ShapePanel;
