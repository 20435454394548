export const mkCategoryHierarchy = (categoryList, categorySize, cascader = false, categoryTotalSize = null) => {
    const hierarchy = [];
    const catMap = {};
    for (const categorys of categoryList) {
        let key = "";
        let parent = "";
        for (const [i, category] of categorys.entries()) {
            parent = key;
            key = categorys.slice(0, i + 1).join("/");
            const size = parseInt(categorySize?.[key] ?? 0);
            const totalSize = parseInt(categoryTotalSize?.[key] ?? 0);
            const catObj = catMap[key] ?? {
                label:
                    size > 0 && !cascader ? (
                        <div className="category-item">
                            <span className="category-name">{category}</span>
                            {!cascader && <span className="category-size">{size}</span>}
                        </div>
                    ) : (
                        <>
                            {category}
                            {categoryTotalSize && <span className="category-total-size">{totalSize}</span>}
                        </>
                    ),
                value: category,
                key: size > 0 ? key : key + "_p",
                icon: null,
                children: size > 0 ? null : [],
                size: size,
                totalsize: totalSize,
            };
            if (!catMap[key]) {
                catMap[key] = catObj;
                if (i === 0) {
                    hierarchy.push(catObj);
                } else if (parent !== "") {
                    if (catMap[parent].children === null) {
                        catMap[parent].children = cascader ? [] : [
                            {
                                label: (
                                    <div className="category-item">
                                        <span className="category-name">{catMap[parent].value}</span>
                                        {!cascader && <span className="category-size">{catMap[parent].size}</span>}
                                    </div>
                                ),
                                value: catMap[parent].value,
                                key: catMap[parent].key,
                                icon: null,
                                children: null,
                                size: catMap[parent].size,
                            },
                        ];
                        catMap[parent].key = catMap[parent].key + "_p";
                        catMap[parent].label = (
                            <>
                                {catMap[parent].value}
                                {categoryTotalSize && (
                                    <span className="category-total-size">{catMap[parent].totalsize}</span>
                                )}
                            </>
                        );
                    }
                    catMap[parent].children.push(catObj);
                }
            }
        }
    }
    return hierarchy;
};

export const mkMenuDefaultOptions = (hierarchy) => {
    const options = {
        selectedKeys: [],
        openKeys: [],
        activeKey: null
    };
    const findLeaf = (menuItem) => {
        options.selectedKeys.push(menuItem.key);
        if (menuItem.children?.length > 0) {
            options.openKeys.push(menuItem.key);
            return findLeaf(menuItem.children[0]);
        }
        return menuItem.key;
    };
    if (hierarchy.length > 0) {
        options.activeKey = findLeaf(hierarchy[0]);
    }
    return options;
}

export const group = (arr, groupByFn) => {
    const grouped = {};
    arr.forEach((e) => {
        const groupBy = groupByFn(e);
        grouped[groupBy] ||= [];
        grouped[groupBy].push(e);
    });
    return grouped;
};

export function hexToRGB(hex) {
    hex = hex.padEnd(9, "f");
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16),
        a = parseInt(hex.slice(7, 9), 16);
    return [r, g, b, a];
}
export function RGB2hex(rgba) {
    let r = rgba[0];
    let g = rgba[1];
    let b = rgba[2];
    let a = rgba[3];
    if (r > 255 || g > 255 || b > 255 || a > 255)
        throw "Invalid color component";
    return (256 + r).toString(16).substr(1) +((1 << 24) + (g << 16) | (b << 8) | a).toString(16).substr(1);
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

export function rgbaToHex(rgba) {
    return (
        "#" + componentToHex(rgba[0]) + componentToHex(rgba[1]) + componentToHex(rgba[2]) + componentToHex(rgba[3])
    );
}

export const parseGenerationParameters = (x) => {
    const reParam = /s*([\w ]+):\s*("(?:\\"[^,]|\\"|\\|[^\\"])+"|[^,]*)(?:,|$)/g
    const res = {}
    
    let prompt = ""
    let negativePrompt = ""

    let doneWithPrompt = false

    const lines = x.trim().split("\n")
    let lastline = lines.pop()

    if (lastline.matchAll(reParam).length < 3) {
        lines.push(lastline)
        lastline = ""
    }

    for (let line of lines) {
        line = line.trim()
        if (line.startsWith("Negative prompt:")) {
            doneWithPrompt = true
            line = line.substr(16).trim()
        }

        if (doneWithPrompt) {
            negativePrompt += (negativePrompt === "" ? "" : "\n") + line
        } else {
            prompt += (prompt === "" ? "" : "\n") + line
        }
    }

    res["Prompt"] = prompt;
    res["Negative prompt"] = negativePrompt;

    for (let r of lastline.matchAll(reParam)) {
        res[r[1].trim()] = r[2].trim()
    }

    return res
}

export function datetimeString() {

    const date = new Date();
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}-${hour}${minute}${second}`
}