import * as React from 'react';

import { Form, Select, Radio, ColorPicker, Space } from "antd";
import BasePanel from './BasePanel';

function FillPanel(props) {

    // const [penRadius, setPenRadius] = React.useState(10);
    // const [blockRadius, setBlockRadius] = React.useState(10);
    const [fillType, setFillType] = React.useState("color-fill");
    // const [snapping, setSnapping] = React.useState(true);

    const [color, setColor] = React.useState("#ff000000");
    const [gradMode, setGradMode] = React.useState(0);
    const [gradStartColor, setGradStartColor] = React.useState("#ffff0000");
    const [gradEndColor, setGradEndColor] = React.useState("#0000ff00");

    const gmodeList = [
        { label: "线性", value: 0, name: "color-fill" },
        { label: "径向", value: 1, name: "gradient-fill" },
        // { label: "矩形", value: 2 },
    ];


    React.useEffect(() => {
        if (props.tool === "fill") {
            changeTool()

            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(fill)>>>>>>:: ", nm, obj);

                if (nm.startsWith("tool::color-fill-config-update")) {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    // setBlockRadius(config.radius);
                }
                if (nm.startsWith("tool::gradient-fill-config-update")) {
                    let config = window.PSMan.getInstance().toolManager().current().getConfig();
                    console.log("config: ", config);
                    // setPenRadius(config.radius);
                }
            }
        }
    }, [props.tool])

    React.useEffect(() => {
        changeTool()
    }, [fillType]);

    React.useEffect(() => {
        if (props.tool == "fill") {
            changeTool()
        }
    }, [props.subTool]);


    const changeTool = () => {
        if (props.tool !== "fill") {
            return;
        }

        // setFillType(props.subTool);

        window.PSMan?.getInstance().toolManager().use(fillType);
        let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
        console.log(cfg);

        if (fillType === "color-fill") {
            if (cfg) {
                setColor(cfg.color);
                // setPenRadius(cfg.radius)
            }
        } else if (fillType === "gradient-fill") {
            if (cfg) {
                setGradMode(cfg.mode);
                setGradStartColor(cfg.start_color);
                setGradEndColor(cfg.end_color);
            }
        }
    }

    const updateColor = (val) => {
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ color: val });
        setColor(val);
    }

    const gradMode_onChange = (idx) => {
        let cit = gmodeList[idx];
        setGradMode(idx);
        window.PSMan?.getInstance().toolManager().current()?.setConfig({ mode: idx });
    }

    const gradStartColor_onChange = (newValue) => {
        if (newValue == null) { return; }
        setGradStartColor(newValue.toHexString());

        let config = {};
        config.start_color = newValue.toHexString();
        window.PSMan?.getInstance().toolManager().current()?.setConfig(config);
    };

    const gradEndColor_onChange = (newValue) => {
        if (newValue == null) { return; }
        setGradEndColor(newValue.toHexString());

        let config = {};
        config.end_color = newValue.toHexString();
        window.PSMan?.getInstance().toolManager().current()?.setConfig(config);
    };


    return (
        <BasePanel className={props.className} title={"填充:"}>
            <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item>
                    <Radio.Group onChange={(e) => setFillType(e.target.value)} value={fillType}>
                        <Radio value={"color-fill"}>颜色填充</Radio>
                        <Radio value={"gradient-fill"}>渐变填充</Radio>
                    </Radio.Group>
                </Form.Item>


                <div style={{ paddingTop: "20px" }} />
                <Form.Item label="填充选项" />

                <Form.Item className={fillType === "color-fill" ? "" : "hidden"}>
                    <ColorPicker value={color} onChange={(v) => updateColor(v.toHexString())} />
                </Form.Item>

                <Form.Item className={fillType === "gradient-fill" ? "" : "hidden"}>
                    <Select style={{ width: 250 }} value={gradMode} options={gmodeList} onChange={gradMode_onChange}></Select>

                    <Space style={{ paddingTop: "10px" }}>
                        <ColorPicker value={gradStartColor} onChange={gradStartColor_onChange} />
                        <div style={{
                            width: "170px", height: "20px",
                            background: `linear-gradient(to right, ${gradStartColor}, ${gradEndColor})`
                        }} />
                        <ColorPicker value={gradEndColor} onChange={gradEndColor_onChange} />
                    </Space>

                </Form.Item>


            </Form>
        </BasePanel>
    );
}





export default FillPanel;