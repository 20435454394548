import { createGlobalStore } from "hox";
import { useState } from "react";
import _ from "lodash"


function useSearch() {
    const [search, setSearchQuery] = useState("");

    const debouncedSetSearch = _.debounce((query) => {
        setSearchQuery(query);
    }, 300)

    const setSearch = (query) => {
        debouncedSetSearch(query)
    }

    return {
        search,
        setSearch
    };
}

export const [useSearchStore] = createGlobalStore(useSearch);
