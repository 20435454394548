import { useEffect, useMemo, useRef, useState } from "react";
import TagItem from "./TagItem";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTagStore } from "./store/tags";
import InfiniteScroll from "react-infinite-scroll-component";
import Empty from "../Empty";

function TagSearchShow({search}) {
    const tagStore = useTagStore()

    const [paginationSize, setPaginationSize] = useState(30);

    const loadMore = () => {
        setPaginationSize(paginationSize + 30);
    };

    const filteredTags = useMemo(() => tagStore.searchAll(search), [search, tagStore]);

    const combinedResult = useMemo(() => {
        const resultTag = filteredTags.map((n) => ({
            type: "tag",
            data: n,
            key: n.key,
            score: n.score,
        }));
        return resultTag
            .sort(({ score: a }, { score: b }) => b - a);
    }, [filteredTags]);

    const paginatedResult = useMemo(() => combinedResult.slice(0, paginationSize), [combinedResult, paginationSize]);
    const paginatedLength = useMemo(() => paginatedResult.length, [paginatedResult]);

    const tagItems = paginatedResult.map((meta, index) => {
        return <TagItem key={index} meta={meta.data} showCategory />;
    });

    useEffect(() => {
        setPaginationSize(30);
        scrollRef.current.scrollTo({ top: 0 });
    }, [search]);

    const scrollRef = useRef(null);

    return (
        <>
            <div className="main-title">搜索结果</div>
            <div
                style={{ height: "calc(100vh - 245px)" }}
                className="tag-wrapper no-bg-scrollbar"
                id="searchTagWrapper"
                ref={scrollRef}
            >
                {!tagItems.length && <Empty />}
                <InfiniteScroll
                    dataLength={paginatedLength}
                    next={loadMore}
                    hasMore={true}
                    scrollableTarget="searchTagWrapper"
                >
                    <ResponsiveMasonry columnsCountBreakPoints={{ 512: 1, 1280: 2, 1600: 3, 1920: 4, 2160: 5 }}>
                        <Masonry gutter="10px">{tagItems}</Masonry>
                    </ResponsiveMasonry>
                </InfiniteScroll>
            </div>
        </>
    );
}

export default TagSearchShow;
