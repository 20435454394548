import { Button as AButton, Space, Button, Form, Row, InputNumber } from "antd";

import { useRef } from "react";
import { downloadImageResource, downloadJSONResource } from "../utils/download.js";
import { datetimeString } from "../utils/util.js";

import {
    DownloadOutlined, SaveOutlined,
    LeftOutlined, RightOutlined, UpOutlined, DownOutlined, UploadOutlined,
    StepBackwardOutlined, StepForwardOutlined
} from "@ant-design/icons";

function ActionPanel() {

    const handleChangeFile = (e) => {
        let filename = e.target.files[0].name;
        console.log("input file: " + filename);
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function () {
            var content = JSON.parse(reader.result);
            const ac = window.PSMan.getInstance();
            ac.fromJson(content);
        };

        reader.onerror = function () {
            console.log("FileReader.onerror(): " + reader.error);
        };
    };

    const handleSave = (e) => {
        const ac = window.PSMan.getInstance();
        ac.encodeImage((ok, img) => {
            let suffix = datetimeString();
            let oname = "canvas-" + suffix + ".png";
            downloadImageResource(oname, img);
        });
    };

    const handleSaveJson = (e) => {
        const ac = window.PSMan.getInstance();
        ac.toJson((jsn) => {
            let suffix = datetimeString();
            let oname = "canvas-" + suffix + ".json";
            downloadJSONResource(oname, jsn);
        });
    };

    const handleAction = (side, step = 32) => {
        const ac = window.PSMan.getInstance();
        switch (side) {
            case "undo":
                ac.historyManager().undo();
                break;
            case "redo":
                ac.historyManager().redo();
                break;
            default:
        }
    };

    const jsonInput = useRef(null);

    return (
        <div className="panel">

            <Form >
                <Form.Item className="ant-form-item" >

                    <Row >
                        <Space > 草稿：</Space>
                        <Form.Item className="ant-form-item" >
                            <Button type="text" icon={<DownloadOutlined />} onClick={handleSaveJson} > 保存 </Button>
                            <Button type="text" icon={<UploadOutlined />} onClick={() => jsonInput.current?.click()} > 加载 </Button>
                        </Form.Item>
                    </Row>

                    <Row >
                        <Space > 操作：</Space>
                        <Button type="text" icon={<StepBackwardOutlined />} onClick={() => handleAction("undo")}> 撤销 </Button>
                        <Button type="text" icon={<StepForwardOutlined />} onClick={() => handleAction("redo")}> 重做 </Button>
                    </Row>

                    <Row >
                        <Space > 画布：</Space>
                        <Button type="text" icon={<SaveOutlined />} onClick={handleSave} > 保存 </Button>
                    </Row>

                </Form.Item>
            </Form>

            <input type="file" accept=".json" ref={jsonInput} onChange={handleChangeFile} style={{ display: "none" }} />

        </div>
    );
}

export default ActionPanel;
