import { useState, useEffect, useMemo, useCallback, useRef } from "react";
// import React 
// import React from "react";
// import styled from "styled-components";
import { Button as AButton, Radio, Input, Select, Tooltip, Collapse, Button } from 'antd';
import BasePanel from "./BasePanel";
import { Form, Col, InputNumber, Row, Slider, Space } from 'antd';
import { LeftOutlined, RightOutlined, PlusOutlined, MinusOutlined, DownOutlined } from '@ant-design/icons';
import DreamBuilder from "../components/dream-builder/DreamBuilder";
import { DreamPaintTypes, samplerList } from "../utils/defs"
import ControlNetPanel from "./ControlNetPanel/ControlNetPanel";

const { TextArea } = Input;
const { Panel } = Collapse;

function ImageDreamPanel(props) {
    // console.log("ImageDreamPanel::init............................ : " + window.PSMan);



    //const defaultNegPrompt = "glitch, error, text, watermark, bad quality, blurry"
    const defaultNegPrompt = "lowres, error, extra digit, fewer digits, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, curve, irregular, coarse, cheap, defect, misplace, distort"

    const [prompt, setPrompt] = useState("")
    const [negativePrompt, setNegativePrompt] = useState(defaultNegPrompt)
    const [width, setWidth] = useState(512)
    const [height, setHeight] = useState(512)
    const [iter, setIter] = useState(1)                 // 生成批次 [1 - 100]
    const [batchSize, setBatchSize] = useState(4)       // 每批数量 [1 - 8]
    const [cfgScale, setCfgScale] = useState(1.0)       // 提示词相关性(CFG Scale)
    const [seed, setSeed] = useState(1.0)               // 随机种子(seed)
    const [denoising, setDenoising] = useState(0.8)     // 重绘幅度(Denoising) range=[0.0, 1.0], step=0.01
    const [inpaintType, setInpaintType] = useState(DreamPaintTypes[1]) // 蒙版蒙住的内容(Outpaint Type)

    const [models, setModels] = useState([]);
    const [modelTitle, setModelTitle] = useState("");

    // const [resultPanelVisible, setResultPanelVisible] = useState(false);
    const [generatedImages, setGeneratedImages] = useState(0)
    const [curImageIndex, setCurImageIndex] = useState(1)
    const [mode, setMode] = useState(false);
    const [actionState, setActionState] = useState(0);

    const [sampler, setSampler] = useState("DPM++ 2M Karras");
    const [steps, setSteps] = useState(1);

    const defaultConfigs = useRef(null);

    useEffect(() => {
        if (props.tool == "image2image") {
            // console.log("Update window.PSMan_onToolEvents...");
            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(image2image)>>>>>>:: ", nm, obj);

                if (nm.startsWith("tool::sd-models")) {
                    var rowData = obj.map((item, idx) => ({ value: item.title, label: item.title }));
                    setModels(rowData);
                }
                else if (nm === "tool::sd-model-update") {
                    console.log("Model updated successfully!!!");
                    setModelTitle(obj);
                }
                else if (nm === "tool::img2img-state") {
                    setWidth(obj["width"]);
                    setHeight(obj["height"]);

                    setCurImageIndex(obj.index);
                    setGeneratedImages(obj.size);
                    setActionState(obj.action_info);
                    // setResultPanelVisible(true);
                    // setIndexText(curImageIndex.toString() + "/" + generatedImages.toString());
                }
                // else if (nm === "tool::img2img-config-update") {
                //     let config = window.PSMan.getInstance().toolManager().current().getConfig();
                //     setWidth(config.width);
                //     setHeight(config.height);
                //     setMode(config.mask_mode);
                //     setSampler(config.sampler);
                //     setSteps(config.steps);

                //     setCfgScale(config["cfg_scale"]);
                //     setSeed(config["seed"]);
                //     setDenoising(config["denoising"]);
                //     let ss = DreamPaintTypes[config["inpaint_type"]];
                //     // console.log("--------------\n", ss, { ...ss });
                //     setInpaintType(ss);
                //     // setOverMaskPx(config["overmask_px"]);
                //     // setKeepUnmaskedBlur(config["keep_unmask_blur"]);
                // }
            }

            let config = window.PSMan.getInstance().toolManager().current().getConfig();

            console.log("ImageDreamPanel::config: ", config);
            defaultConfigs.current = config;

            // updateDreamPrompt({ positive: prompt, negative: "nsfw, nude, nake, " + negativePrompt });
            updateDreamPrompt({ positive: prompt, negative: negativePrompt });
            setWidth(config["width"]);
            setHeight(config["height"]);
            setIter(config["n_iter"]);
            setSteps(config["steps"]);
            setBatchSize(config["batch_size"]);

            setCfgScale(config["cfg_scale"]);
            setSeed(config["seed"]);

            setDenoising(config["denoising"]);
            setInpaintType(DreamPaintTypes[config["inpaint_type"]]);
            setCurImageIndex(0);


            // window.PSMan.getInstance().toolManager().current().sendAction("sd-state", {});


            // setGeneratedImages(config["info_imgs_count"]);
            // setCurImageIndex(config["info_cur_img_index"]);
        }
        // else {
        //     setResultPanelVisible(false);
        // }

    }, [props.tool])


    const reset_config = () => {

        window.PSMan.getInstance().toolManager().current().sendAction("reset-config", {});
        let config = window.PSMan.getInstance().toolManager().current().getConfig();
        setSampler(config.sampler);
        setIter(config["n_iter"]);
        setBatchSize(config["batch_size"]);
        setSteps(config["steps"]);

        setCfgScale(config["cfg_scale"]);
        setSeed(config["seed"]);

        setDenoising(config["denoising"]);
        // setOutpaintType(DreamPaintTypes[config["outpaint_type"]]);
        // setOverMaskPx(config["overmask_px"]);
        // setKeepUnmaskedBlur(config["keep_unmask_blur"]);
        setCurImageIndex(0);
    }

    // dream params ------------------------------
    const dreamWidth_onChange = (newValue) => {
        if (newValue == null) { return; }
        setWidth(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ width: newValue });
    };

    const dreamHeight_onChange = (newValue) => {
        if (newValue == null) { return; }
        setHeight(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ height: newValue });
    };

    const cfgScale_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCfgScale(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ cfg_scale: newValue });
    };

    const seed_onChange = (newValue) => {
        if (newValue == null) { return; }
        setSeed(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ seed: newValue });
    };

    const denoising_onChange = (newValue) => {
        if (newValue == null) { return; }
        setDenoising(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ denoising: newValue });
    };

    const sampler_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSampler(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ sampler: newValue });
    };

    const steps_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setSteps(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ steps: newValue });
    };

    const iter_onChange = (newValue) => {
        if (newValue == null) { return; }
        setIter(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ n_iter: newValue });
    };

    const batchSize_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBatchSize(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ batch_size: newValue });
    };

    const inpaintType_onChange = (value) => {
        let newValue = DreamPaintTypes[value];
        setInpaintType(newValue);
        window.PSMan.getInstance().toolManager().current().setConfig({ inpaint_type: newValue.value });
    };


    const DreamButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("dream", {});
    }
    // dream result ------------------------------
    const PreviousButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("prev", {});
    }

    const IndexButton_Click = () => {
    }

    const NextButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("next", {});
    }

    const MoreButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("more", {});
    }

    const ApplyButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("apply", {});
    }

    const CancelButton_Click = () => {
        // setResultPanelVisible(false)
        window.PSMan.getInstance().toolManager().current().sendAction("cancel", {});
    }

    const SaveResourcesButton_Click = () => {
        // download current index image .
        window.PSMan.getInstance().toolManager().current().sendAction("resources", {});
    }

    // const DownloadButton_Click = () => {
    //     // add current index image into resources list.
    //     window.PSMan.getInstance().toolManager().current().sendAction("download", {});
    // }

    const openDreamBuilder = (e) => {
        e.target.blur();
        setDrOpen(true);
    };

    // dream builder
    const [drOpen, setDrOpen] = useState(false);

    const updateDreamPrompt = ({ positive, negative }) => {
        window.PSMan?.getInstance().toolManager().current().setConfig({ prompt: positive, negative_prompt: negative });
    };


    const onMaskModeChange = (e) => {
        const val = e.target.value;
        setMode(val);
        // console.log("--------- maskMode: ", val);
        window.PSMan.getInstance().toolManager().current().setConfig({ mask_mode: val });
    };


    // ControlNet
    const [cnOpen, setCnOpen] = useState(false)
    const [cnUnits, setCnUnits] = useState([])
    const openCnPanel = () => {
        setCnOpen(true)
    }
    const handleCnChange = (units) => {
        setCnUnits(units)
        console.log("ooo cnchange", units)
    }

    return (
        <BasePanel className={props.className} title={"图生图:"}>

            <Form size="small" labelAlign="left">

                <Form.Item align="middle" className="ant-form-item">
                    <Row> 模型： </Row>
                    <Select open={false} onClick={openDreamBuilder} style={{ width: 300 }} options={models}
                        defaultValue={"请选择模型"} value={modelTitle} />
                </Form.Item>

                <Form.Item align="middle" className="ant-form-item">
                    <Row> 提示词： </Row>
                    <TextArea placeholder="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} onClick={openDreamBuilder} />
                </Form.Item>

                {/* <Form.Item align="middle" className="ant-form-item">
                    <Collapse size="small" bordered={false}>
                        <Panel header="反向提示词:" key="1">
                            <TextArea placeholder="Negative Prompt" value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)} onClick={openDreamBuilder} />
                        </Panel>
                    </Collapse>
                </Form.Item> */}


                <Form.Item align="middle" className="ant-form-item">
                    <Row align="middle" >
                        <Col span={4}>
                            <label>宽高：</label>
                        </Col>
                        <Col span={4}>
                            <Space>
                                <InputNumber readOnly min={128} max={1024} style={{ margin: "0 10px", width: 64 }}
                                    value={width} onChange={dreamWidth_onChange} />
                                x
                                <InputNumber readOnly min={128} max={1024} style={{ margin: "0 10px", width: 64 }}
                                    value={height} onChange={dreamHeight_onChange} />
                            </Space>
                        </Col>
                    </Row>
                </Form.Item>


                <Collapse size="small" bordered={false}>
                    <Panel header="高级参数:" key="1">

                        <Form.Item>
                            <AButton
                                type="primary"
                                onClick={() => reset_config()}>重置参数</AButton>
                        </Form.Item>

                        {/* <Form.Item>
                            <Row align="middle" >
                                <Radio.Group onChange={onMaskModeChange} value={mode}>
                                    <Radio value={true}>重绘非蒙版区</Radio>
                                    <Radio value={false}>重绘蒙版区</Radio>
                                </Radio.Group>
                            </Row>
                        </Form.Item> */}

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={12}> 采样方法(Sampler):</Col>
                                <Col span={12}>
                                    <Select value={sampler} options={samplerList} onChange={sampler_onChange}></Select>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={12}>
                                    <label>采样迭代步数(Steps):</label>
                                </Col>
                                <Col span={8}>
                                    <Slider min={1} max={150}
                                        value={steps} onChange={steps_onChange} />
                                </Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={150} step={1} style={{ margin: "0 6px", width: 42 }} 
                                        value={steps} onChange={steps_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        {/* <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={16}> 生成批次(Iterations):</Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={100} style={{ width: 64 }}
                                        value={iter} onChange={iter_onChange} />
                                </Col>
                            </Row>
                        </Form.Item> */}

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={16}> 每批数量(Batch Size): </Col>
                                <Col span={4}>
                                    <InputNumber min={1} max={8} style={{ width: 64 }}
                                        value={batchSize} onChange={batchSize_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 提示词相关性(CFG Scale):</Col>
                                <Col span={8}>
                                    <InputNumber min={1.0} max={30.0} step={0.5} style={{ width: 100 }} precision={1}
                                        value={cfgScale} onChange={cfgScale_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 随机种子(seed): </Col>
                                <Col span={8}>
                                    <InputNumber min={-100} max={100} style={{ width: 100 }}
                                        value={seed} onChange={seed_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row align="middle" >
                                <Col span={15}> 重绘幅度(Denoising): </Col>
                                <Col span={8}>
                                    <InputNumber min={0.0} max={1.0} step={0.01} style={{ width: 100 }} precision={2}
                                        value={denoising} onChange={denoising_onChange} />
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item className="ant-form-item" >
                            <Row> 蒙版蒙住的内容(Inpaint Type)： </Row>
                            <Select style={{ width: 300 }} options={DreamPaintTypes} value={inpaintType.label}
                                onChange={inpaintType_onChange} />
                        </Form.Item>
                    </Panel>
                </Collapse>

                <Form.Item label="ControlNet">
                    <Button disabled={!modelTitle} onClick={openCnPanel}>{cnUnits.length ?? 0}</Button>
                </Form.Item>


                <Form.Item id="resultPanel" style={{ paddingTop: 5 }} >
                    {actionState == 1 && (
                        <Space size={6} >

                            <Tooltip title="生成">
                                <AButton id="MoreButton"
                                    // disabled={actionState < 1}
                                    style={{ padding: 0, width: '30px', height: '34px' }}
                                    icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => DreamButton_Click()} />
                            </Tooltip>

                            <Tooltip title="取消">
                                <AButton id="CancelButton"
                                    // disabled={!resultPanelVisible}
                                    style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                    onClick={() => CancelButton_Click()}> N </AButton>
                            </Tooltip>
                        </Space>

                    )}

                    {actionState == 2 && (
                        <Space size={6} >
                            <AButton id="PreviousButton"
                                style={{ padding: 0, width: '30px', height: '34px' }}
                                icon={<LeftOutlined style={{ fontSize: '20px' }} />}
                                onClick={() => PreviousButton_Click()} />

                            <AButton id="IndexButton"
                                style={{ padding: 0, width: '50px', height: '34px', fontSize: '20px' }}
                                onClick={() => IndexButton_Click()}>{curImageIndex.toString() + "/" + generatedImages.toString()}</AButton>

                            <AButton id="NextButton"
                                style={{ padding: 0, width: '30px', height: '34px' }}
                                icon={<RightOutlined style={{ fontSize: '20px' }} />}
                                onClick={() => NextButton_Click()} />

                            <Tooltip title="更多">
                                <AButton id="MoreButton"
                                    style={{ padding: 0, width: '30px', height: '34px' }}
                                    icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => MoreButton_Click()} />
                            </Tooltip>

                            <Tooltip title="确定">
                                <AButton id="ApplyButton"
                                    disabled={curImageIndex == 0}
                                    style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                    onClick={() => ApplyButton_Click()}> Y </AButton>
                            </Tooltip>

                            <Tooltip title="取消">
                                <AButton id="CancelButton"
                                    style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                    onClick={() => CancelButton_Click()}> N </AButton>
                            </Tooltip>

                            <Tooltip title="保存到资源库">
                                <AButton disabled={curImageIndex == 0} id="SaveResourcesButton"
                                    style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                    onClick={() => SaveResourcesButton_Click()}> R </AButton>
                            </Tooltip>



                            {/* <AButton id="DownloadButton"
                            style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                            onClick={() => DownloadButton_Click()}> S </AButton> */}
                        </Space>

                    )}

                </Form.Item>

            </Form>

            <DreamBuilder
                open={drOpen}
                setOpen={setDrOpen}
                ckptTitle={modelTitle}
                positiveTags={prompt}
                setPositiveTags={setPrompt}
                negativeTags={negativePrompt}
                setNegativeTags={setNegativePrompt}
                updateDreamPrompt={updateDreamPrompt} />

            <ControlNetPanel ckptTitle={modelTitle} open={cnOpen} setOpen={setCnOpen} onChange={handleCnChange} originalUnits={cnUnits} />
        </BasePanel>
    );
}




export default ImageDreamPanel;