import * as React from "react";

import { Radio, Space } from "antd";
import { Checkbox, Button } from "antd";
import BasePanel from "./BasePanel";


function MaskPanel(props) {
    // console.log("MaskPanel::init............................ : " + window.PSMan);

    // const [mode, setMode] = React.useState(true);
    // const [color, setColor] = React.useState("#000000cc");
    const [maskShow, setMaskShow] = React.useState(false);
    // const [maskShow, setMask] = React.useState(false);

    React.useEffect(() => {
        console.log("MaskPanel::componentWillMount() " + window.PSMan);

        window.PSMan_onMaskEvents = function (nm, obj) {
            console.log("PSMan_onMaskEvents()>>>>>>:: ", nm, obj);

            if (nm == "mask::state") {
                // var layersReverse = Array.from(obj.layers).reverse();
                // setMode(obj["mode"]);
                setMaskShow(obj["show"]);
            }
        };

        return () => {
            console.log("MaskPanel::componentWillUnmount()");
            // Anything in here is fired on component unmount.
            delete window.PSMan_onMaskEvents;
        };
    }, []);

    // React.useEffect(() => {

    //     window.PSMan?.getInstance().maskManager().enable(enabled);
    //     window.PSMan?.getInstance().maskManager().show(maskShow);

    // }, [enabled, maskShow]);

    // const enableMask = (on) => {
    //     window.PSMan.getInstance()
    //         .maskManager().enable(on);
    // }
    // const showMask = (on) => {
    //     window.PSMan.getInstance()
    //         .maskManager().show(on);
    // }

    // const onMaskModeChange = (e) => {
    //     const on = e.target.value;
    //     // setMode(on);
    //     console.log("--------- maskMode: ", on);
    //     let o = window.PSMan.getInstance().maskManager().setMode(on);
    //     // if (on) {
    //         // window.PSMan.getInstance().maskManager().show(on);
    //         // setMaskShow(on);
    //     // }
    // };
    const onMaskShowChange = (vs, e) => {
        setMaskShow(vs.target.checked);
        // // console.log("--------- maskShow: ", vs.target.checked);
        window.PSMan.getInstance().maskManager().show(vs.target.checked);
    };

    const onMaskReverse = (e) => {
        window.PSMan.getInstance().maskManager().reverse();
    };

    const onMaskClear = (e) => {
        window.PSMan.getInstance().maskManager().clear();
    };

    // AgGridReact
    const gridRef = React.useRef();
    const [rowData, setRowData] = React.useState([]);
    // const [selectedId, setSelectedId] = React.useState(-1);
    const [columnDefs] = React.useState([
        {
            headerName: "ID",
            field: "id",
            width: 70,
            rowDrag: (params) => !params.node.group,
        },
        {
            headerName: "Name",
            field: "name",
            width: 150,
        },
        {
            headerName: "",
            // field: "vis",
            // cellRenderer: VisComponent,
            width: 70,
        },
    ]);

    const onRowClicked = (e) => {
        let agridapi = e.api;
        let nodes = agridapi.getSelectedNodes();

        if (nodes.length === 0) {
            console.log("NO ROW SELECTED!!");
            return;
        }

        let id = rowData[nodes[0].rowIndex].id;
        console.log("mask onRowClicked ", id);
    };

    const newMask = () => { 
        console.log("mask ", window.PSMan?.getInstance().maskManager());
     }
    const delMask = () => {
        
    }

    return (
        <BasePanel className={props.className}>
            <Space direction="vertical">
                {/* <Radio.Group onChange={onMaskModeChange} value={mode}>
                    <Radio value={true}>重绘非蒙版区</Radio>
                    <Radio value={false}>重绘蒙版区</Radio>
                </Radio.Group> */}
                {/* <div className="ag-theme-balham" style={{ height: 200 }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        suppressHorizontalScroll={true}
                        suppressCellFocus={true}
                        suppressMovableColumns={true}
                        suppressRowClickSelection={false}
                        rowDragManaged={true}
                        animateRows={true}
                        rowSelection="single"
                        onRowClicked={onRowClicked}
                    ></AgGridReact>
                </div> */}
                <Space>
                    <Checkbox onChange={onMaskShowChange} checked={maskShow}>
                        显示
                    </Checkbox>
                    <Button
                        // shape="circle"
                        size="small"
                        onClick={onMaskReverse}
                    >
                        反转
                    </Button>

                    <Button
                        type="dashed"
                        danger
                        // shape="circle"
                        size="small"
                        onClick={onMaskClear}
                    >
                        清除
                    </Button>

                    {/* <Button
                    type="link"
                    size="middle"
                    icon={<FileAddOutlined />}
                    onClick={() => newMask(props.value)}
                ></Button>
                
                <Button
                    type="link"
                    size="middle"
                    icon={<DeleteOutlined />}
                    onClick={() => delMask(props.value)}
                ></Button> */}
                </Space>
            </Space>
        </BasePanel>
    );
}

export default MaskPanel;
