import React, { useEffect, useMemo, useState } from "react";
import { Form, Dropdown, Button, Slider} from "antd";

import BasePanel from "./BasePanel";
import { Space } from 'antd';
import { DownOutlined } from "@ant-design/icons";

function FilterPanel(props) {

    const [tutuFilters, setTutuFilters] = useState([])
    const [customFilters, setCustomFilters] = useState([])

    const DEFAULT_INTENSITY = 50
    const [name, setName] = useState("")
    const [filter, setFilter] = useState(null)
    const [intensity, setIntensity] = useState(DEFAULT_INTENSITY);


    useEffect(() => {

        if (props.tool === "filter") {
            const load = async () => {
                const { default: tutuFilters } = await import("../static/tutu-filters.json");
                setTutuFilters(tutuFilters.map(g => {
                    g.icon = <FilterIcon path={`/tutu-filters/groups/${g.id}.jpg`} />
                    g.children.forEach((c) => {
                        c.icon = <FilterIcon path={`/tutu-filters/filters/${c.id}.jpg`} />;
                        c.name = `${g.label} / ${c.label}`
                    })
                    return g
                }))
                refreshCustomFilters();
            }
            load()
            setFilter(null)

            window.PSMan_onToolEvents = function (nm, obj) {
                console.log("PSMan_onToolEvents(filter)>>>>>>:: ", nm, obj);

                if (nm === "tool::filter-config-update") {
                    let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
                    if (cfg != null) {
                        if (cfg.intensity) {
                            setIntensity(cfg.intensity);
                        }
                    }
                }
            }
        }
    }, [props])

    const items = useMemo(
        () => {
            const list = [
                {
                    key: "tutu",
                    label: "tutu",
                    children: tutuFilters,
                },
            ]
            if (customFilters?.length > 0) {
                list.push({
                    key: "custom",
                    label: "自定义",
                    children: customFilters
                })
            }
            return list
        },
        [tutuFilters, customFilters]
    );



    const refreshCustomFilters = () => {
        let flist = window.PSMan.getInstance().resourceManager().list("/psman");
        flist = flist.filter((item) => item.path.endsWith(".3dl")
            || item.path.endsWith(".3DL")
            || item.path.endsWith(".cube")
            || item.path.endsWith(".CUBE"));

        setCustomFilters(flist.map((f) => ({ key: f.path, label: f.name, name: f.name })));
    };

    const FilterIcon = ({ path }) => {
        return <img src={path} width={25} height={25} style={{ marginRight: 10, borderRadius: 3 }} alt="" />
    }

    const intensity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setIntensity(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ intensity: newValue });
    };

    const handleMenuClick = ({ item, key }) => {
        console.log("FilterPanel:: handleMenuClick", item, key);
        setName(item.props.name)
        setFilter(key)

        if (key.endsWith(".3dl") || key.endsWith(".3DL")) {
            window.PSMan?.getInstance().toolManager().current().setConfig({ type: "LUT_3DL", key: key });
        }
        else if (key.endsWith(".cube") || key.endsWith(".CUBE")) {
            window.PSMan?.getInstance().toolManager().current().setConfig({ type: "LUT_CUBE", key: key });
        }
        else {
            window.PSMan?.getInstance().toolManager().current().setConfig({ type: "LUT_TU", key: key });
        }
    }

    const cancal_onClick = () => {
        console.log("FilterPanel:: cancal_onClick");
        setFilter(null)
        window.PSMan.getInstance().toolManager().current().sendAction("cancel", {});
    };

    const apply_onClick = () => {
        console.log("FilterPanel:: apply_onClick");
        setFilter(null)
        window.PSMan.getInstance().toolManager().current().sendAction("apply", {});
    };


    return (
        <BasePanel className={props.className} title={"滤镜:"}>
            <Form size="middle" labelCol={{ span: 6 }} labelAlign="left">
                <Form.Item label="选择滤镜">
                    <Dropdown
                        onOpenChange={(open) => open && refreshCustomFilters()}
                        menu={{ items, onClick: handleMenuClick }}
                    >
                        <Button>
                            <Space>
                                {filter ? name : '未选择'}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Form.Item>
                {filter && <>
                    <Form.Item>
                        <Slider min={0} max={100} value={intensity} onChange={intensity_onChange} />
                    </Form.Item>
                    <Form.Item>
                        <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                            <Button size="small" onClick={cancal_onClick}>
                                取消
                            </Button>
                            <Button size="small" onClick={apply_onClick} type="primary">
                                确定
                            </Button>
                        </Space>
                    </Form.Item>
                </>}
            </Form>
        </BasePanel>
    );
}

export default FilterPanel;
