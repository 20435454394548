import * as React from "react";
import { Form, Select, ColorPicker, InputNumber, Slider, Row, Col, Radio, Space } from "antd";
import { useState } from "react";
import { blendModes, gradientModes } from "../../utils/defs";
import { hexToRGB, rgbaToHex } from "../../utils/util";

function LayerStyleStroke({ config, setConfig }) {
    const MODE_OUTSIDE = 0;
    const MODE_INSIDE = 1;
    const MODE_CENTER = 2;

    const FILL_MODE_COLOR = 0;
    const FILL_MODE_GRADIENT = 1;

    const strokePositions = [
        { label: "外部", value: MODE_OUTSIDE },
        { label: "内部", value: MODE_INSIDE },
        { label: "居中", value: MODE_CENTER },
    ];

    const [blendMode, setBlendMode] = useState(config?.layer_styles.stroke["blend"] ?? 0);
    const [opacity, setOpacity] = useState(config?.layer_styles.stroke["opacity"] ?? 100);
    const [fillMode, setFillMode] = useState(config?.layer_styles.stroke["fill"] ?? FILL_MODE_COLOR);
    const [strokePos, setStrokePos] = useState(config?.layer_styles.stroke["position"] ?? MODE_OUTSIDE);
    const [strokeWidth, setStrokeWidth] = useState(config?.layer_styles.stroke["width"] ?? 20);
    const [color, setColor] = useState(config ? rgbaToHex(config.layer_styles.stroke["color"]) : "#ff00ffff");
    const [gradMode, setGradMode] = useState(config?.layer_styles.stroke["grad_mode"] ?? 0);
    const [gradStartColor, setGradStartColor] = useState(
        config ? rgbaToHex(config.layer_styles.stroke["grad_start_color"]) : "#ffff00ff"
    );
    const [gradEndColor, setGradEndColor] = useState(
        config ? rgbaToHex(config.layer_styles.stroke["grad_end_color"]) : "#ff00ffff"
    );
    const [gradScale, setGradScale] = useState(config?.layer_styles.stroke["grad_scale"] ?? 100);
    const [gradDegree, setGradDegree] = useState(config?.layer_styles.stroke["grad_degree"] ?? 0);

    const updateConfig = (c) => {
        let cfg = Object.assign({}, config);
        cfg.layer_styles.stroke = { ...cfg.layer_styles.stroke, ...c };
        setConfig(cfg);
    };

    // parameters ------------------------------
    const blendMode_onChange = (idx) => {
        setBlendMode(idx);
        updateConfig({ blend: idx });
    };

    const opacity_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setOpacity(newValue);
        updateConfig({ opacity: newValue });
    };

    const fillMode_onChange = (e) => {
        if (e == null) {
            return;
        }
        setFillMode(e.target.value);
        updateConfig({ fill: e.target.value });
    };

    const strokePos_onChange = (idx) => {
        setStrokePos(idx);
        updateConfig({ position: idx });
    };

    const color_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setColor(newValue.toHexString());
        updateConfig({ color: hexToRGB(newValue.toHexString()) });
    };

    const strokeWidth_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setStrokeWidth(newValue);
        updateConfig({ size: newValue });
    };

    const gradMode_onChange = (idx) => {
        setGradMode(idx);
        updateConfig({ grad_mode: idx });
    };

    const gradStartColor_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradStartColor(newValue.toHexString());
        updateConfig({ grad_start_color: hexToRGB(newValue.toHexString()) });
    };

    const gradEndColor_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradEndColor(newValue.toHexString());
        updateConfig({ grad_end_color: hexToRGB(newValue.toHexString()) });
    };

    const gradScale_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradScale(newValue);
        updateConfig({ grad_scale: newValue });
    };

    const gradDegree_onChange = (newValue) => {
        if (newValue == null) {
            return;
        }
        setGradDegree(newValue);
        updateConfig({ grad_degree: newValue });
    };

    return (
        <Form size="small" labelCol={{ span: 6 }} labelAlign="left">
            <Form.Item>
                <Radio.Group onChange={fillMode_onChange} value={fillMode}>
                    <Radio value={FILL_MODE_COLOR}>颜色</Radio>
                    <Radio value={FILL_MODE_GRADIENT}>渐变</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="混合模式">
                <Select value={blendMode} options={blendModes} onChange={blendMode_onChange}></Select>
            </Form.Item>

            <Form.Item label="不透明度">
                <Row align={"middle"}>
                    <Col span={14}>
                        <Slider min={0} max={100} onChange={opacity_onChange} value={opacity} />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                            min={0}
                            max={100}
                            style={{ width: 60, marginLeft: 10 }}
                            value={opacity}
                            onChange={setOpacity}
                        />
                        %
                    </Col>
                </Row>
            </Form.Item>

            <div className={fillMode === FILL_MODE_COLOR ? "" : "hidden"}>
                <Form.Item label="颜色">
                    <ColorPicker value={color} onChange={color_onChange} />
                </Form.Item>
            </div>

            <div className={fillMode === FILL_MODE_GRADIENT ? "" : "hidden"}>
                <Form.Item label="颜色">
                    <Space>
                        <ColorPicker value={gradStartColor} onChange={gradStartColor_onChange} />
                        <div
                            style={{
                                width: "240px",
                                height: "20px",
                                background: `linear-gradient(to right, ${gradStartColor}, ${gradEndColor})`,
                            }}
                        ></div>
                        <ColorPicker value={gradEndColor} onChange={gradEndColor_onChange} />
                    </Space>
                </Form.Item>
                <Form.Item label={"样式"}>
                    <Select value={gradMode} options={gradientModes} onChange={gradMode_onChange}></Select>
                </Form.Item>
                <Form.Item label="角度">
                    <Row align={"middle"}>
                        <Col span={14}>
                            <Slider min={0} max={359} onChange={gradDegree_onChange} value={gradDegree} />
                        </Col>
                        <Col span={8}>
                            <Space>
                                <InputNumber
                                    min={0}
                                    max={359}
                                    style={{ width: 60, marginLeft: 10 }}
                                    value={gradDegree}
                                    onChange={gradDegree_onChange}
                                />
                                度
                            </Space>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="缩放">
                    <Row align={"middle"}>
                        <Col span={14}>
                            <Slider min={10} max={150} onChange={gradScale_onChange} value={gradScale} />
                        </Col>
                        <Col span={8}>
                            <Space>
                                <InputNumber
                                    min={10}
                                    max={150}
                                    style={{ width: 60, marginLeft: 10 }}
                                    value={gradScale}
                                    onChange={gradScale_onChange}
                                />
                                %
                            </Space>
                        </Col>
                    </Row>
                </Form.Item>
            </div>

            <Form.Item label={"位置"}>
                <Select value={strokePos} options={strokePositions} onChange={strokePos_onChange}></Select>
            </Form.Item>
            <Form.Item label="宽度">
                <InputNumber onChange={strokeWidth_onChange} min={1} max={250} value={strokeWidth} />
            </Form.Item>
        </Form>
    );
}

export default LayerStyleStroke;
