import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Layout from "./Layout";
import Home from "./Home";
import Testbed from "./Testbed";

// import Blogs from "./pages/Blogs";
import Contact from "./Contact";
import NoPage from "./NoPage";
import { ConfigProvider, theme } from "antd";

import { LoadingOutlined, } from '@ant-design/icons';

// import { Button } from 'antd';


/*
function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/testbed" element={<Testbed />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>


  );
}
*/

function App() {
  let psmanLoaderUrl = "./psman-testbed-loader.js";

  return (
      <ConfigProvider
          theme={{
              algorithm: theme.darkAlgorithm,
              token: {
              },
          }}
      >
          <div className="App">
              <div id="init-loading">
                  <LoadingOutlined style={{ fontSize: "32px", color: "#08c" }} />
              </div>
              <header className="App-header">{/* <img src={logo} className="App-logo" alt="logo" /> */}</header>
              {/* <Button type="primary" shape="circle">
                            A
                        </Button> */}
              {/* <script
            src={psmanLoaderUrl}
            //   crossorigin="anonymous"
            sync
        ></script> */}

              <Testbed />
          </div>
      </ConfigProvider>
  );
}









export default App;
/*
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />



*/


