import React, { useState } from 'react';

import { Helmet } from "react-helmet"

import 'antd/dist/reset.css';

import MainPanel from './panels/MainPanel';

import "./Testbed.css";

import { Dropdown } from "antd";


import { loadConfig } from './utils/configio';

function Testbed(props) {
    // return testbedHtml;
    let psmanLoaderUrl = "./psman-testbed-loader.js?" + window.app_version;
    let psmanUrl = "./psman-testbed.js?" + window.app_version;

    let [curTool, setCurTool] = useState("");

    React.useEffect(() => {
        console.log("Testbed::componentWillMount() " + window.PSMan);

        window.PSMan_onCanvasEvents = function (nm, obj) {
            console.log("PSMan_onCanvasEvents()>>>>>> :: ", nm, obj);

            if (nm === "::ready") {

                loadConfig((cfg) => {
                    console.log("XXXXXX : ", cfg);
                    window.PSMan.getInstance().setConfig(cfg);
                });
            }

            if (nm === "::change-tool") {
                console.log("TOOL CHANGEDDDD : ", nm, obj);
                setCurTool(obj);
            }

            //浏览器丢失GLContext后恢复
            if (nm === "::pre-restore") {
                console.log("PRE-RESTORE : ", nm);
                setCurTool("");
            }

            if (nm === "canvas::not-support") {
                let ele = document.getElementById("canvas");
                ele.style.cursor = "not-allowed";
            } else if (nm === "canvas::support") {
                document.getElementById("canvas").style.cursor = "default";
            } else if (nm === "canvas::cursor") {
                document.getElementById("canvas").style.cursor = obj;

            }

        }

        return () => {
            console.log("Testbed::componentWillUnmount()");
            delete window.PSMan_onCanvasEvents;
        }
    }, [])

    function contextMenu(e) {
        e.preventDefault();
        // addMenu.popup(e.clientX, e.clientY);
    }

    function clickCanvas(e) {
        console.log("canvasd init focus!!!");

        e.target.tabIndex = 1;
        e.target.focus();

        // let myCanvas = document.getElementById('canvas');
        // myCanvas.tabIndex = 1;
        // myCanvas.focus();
        // myCanvas.style.cursor = 'move';
    }

    function focusCanvas(e) {
        console.log("canvasd focused!!!");
        // let myCanvas = document.getElementById('canvas');
        // myCanvas.tabIndex = 1;
        // myCanvas.focus();

        // e.target.style.cursor = 'move';
    }

    function blurCanvas(e) {
        console.log("canvasd focus out!!!");
        // e.target.style.cursor = 'default';
    }

    const [items, setItems] = useState([])

    const menuItems = [
        {
            label: "1st menu item",
            key: "1",
        },
        {
            label: "2nd menu item",
            key: "2",
        },
        {
            label: "3rd menu item",
            key: "3",
        },
    ];

    const onMenuClick = ({ key }) => {
        console.log(`Click on item ${key}`);
        // console.log("onMenuClick: ", items);

        let tarl = items.filter(item => item.key == key);
        let a = tarl[0];
        // console.log("onMenuClick: ", key, tarl);

        if (key >= 50) {
            let curtool = window.PSMan.getInstance().layerManager().current();
            curtool.sendAction(a.label, {});
        } else {
            let curtool = window.PSMan.getInstance().toolManager().current();
            curtool.sendAction(a.label, {});
        }

        document.getElementById("canvas").focus()
    };

    const handleOpenChange = (open) => {
        if (open) {

            let curItems = [];
            let idxOffset = 0;

            let curtool = window.PSMan.getInstance().toolManager().current();
            console.log(curtool);
            if (curtool != null) {
                let acts = curtool.getActions();
                console.log(acts);
                if (acts != null) {
                    acts = acts.filter(v => v.flags === undefined || v.flags === 1);
                    let nmenuItems = acts.map((item, idx) => ({ label: item.name, key: idxOffset + idx }));
                    // console.log(nmenuItems);
                    // setItems(nmenuItems);
                    curItems = curItems.concat(nmenuItems);
                }
            }
            curItems = curItems.concat({ type: 'divider' });
            idxOffset = 50;
            let curlayer = window.PSMan.getInstance().layerManager().current();
            {
                let acts = curlayer.getActions();
                console.log(acts);
                if (acts != null) {
                    acts = acts.filter(v => v.flags === undefined || v.flags === 1);
                    let nmenuItems = acts.map((item, idx) => ({ label: item.name, key: idxOffset + idx }));
                    curItems = curItems.concat(nmenuItems);
                }

            }
            setItems(curItems);
        }
    }

    return (
        <>


            <MainPanel curTool={curTool} setCurTool={setCurTool} />
            <Dropdown
                menu={{ items, onClick: onMenuClick, style: { display: items.length === 0 ? 'none' : 'block' } }} onOpenChange={handleOpenChange}
                trigger={["contextMenu"]}
            >
                <canvas
                    id="canvas"
                    onContextMenu={contextMenu}
                    onClick={clickCanvas}
                    onFocus={focusCanvas}
                    onBlur={blurCanvas}
                // tabIndex="0"
                ></canvas>
            </Dropdown>
            <Helmet>
                <script
                    src={psmanLoaderUrl}
                //   crossorigin="anonymous"
                //sync={true}
                ></script>

                <script
                    src={psmanUrl}
                //   crossorigin="anonymous"
                //sync
                ></script>
            </Helmet>


        </>
    );
}

export default Testbed;