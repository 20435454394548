import { createGlobalStore } from 'hox'
import { Set as ImmutableSet, OrderedSet as ImmutableOrderedSet } from "immutable";
import { useMemo, useState } from 'react'
import { useTagStore } from './tags'
import Decimal from "decimal.js-light";
import { useSettingsStore } from './setting';


function tagNameMapper(n) {
    switch (n.type) {
        case 'tag':
        case 'embedding':
            return n.name
        case 'preset':
        case 'alternate':
        case 'group':
            return n.children.flatMap((e) => tagNameMapper(e))
        case 'editing':
            return n.children
                .filter(
                    (e) => e.type !== 'null'
                )
                .flatMap((e) => tagNameMapper(e))
        default:
    }
}

function wrapParen(content, char, length) {
    for (let i = 0; i < length; i++) {
        switch (char) {
            case '(':
                content = `(${content})`
                break
            case '{':
                content = `{${content}}`
                break
            case '[':
                content = `[${content}]`
                break
            default:
        }
    }
    return content
}

export function wrapParenByWeight( content, weight, newEmphasis) {
    if (newEmphasis) {
        if (!weight.toDecimalPlaces(8).equals(1)) {
            return `(${content}:${weight.toDecimalPlaces(8)})`
        }
    } else {
        const oldWeight = weight.log(1.05).toInteger().toNumber()
        if (oldWeight !== 0) {
            return wrapParen(
                content,
                oldWeight > 0 ? '{' : '[',
                Math.abs(oldWeight)
            )
        }
    }
    return content
}

function serialize(items, newEmphasis) {
    return ImmutableOrderedSet.of(
        ...items.reduce((a, t) => {
            if (t.type === "tag" || t.type === "embedding") {
                let name = t.name.replaceAll("\\", "\\\\");
                if (newEmphasis) {
                    name = name
                        .replaceAll("(", "\\(")
                        .replaceAll(")", "\\)")
                        .replaceAll("[", "\\[")
                        .replaceAll("]", "\\]");
                } else {
                    name = name
                        .replaceAll("{", "\\{")
                        .replaceAll("}", "\\}")
                        .replaceAll("[", "\\[")
                        .replaceAll("]", "\\]");
                }
                a.push(wrapParenByWeight(name, t.weight, newEmphasis));
            } else if (t.type === "preset") {
                a.push(serialize(t.children, newEmphasis));
            } else if (t.type === "alternate") {
                if (newEmphasis) {
                    a.push(
                        wrapParenByWeight(
                            `[${t.children.map((n) => serialize([n], newEmphasis)).join("|")}]`,
                            t.weight,
                            newEmphasis
                        )
                    );
                } else {
                    a.push(wrapParenByWeight(serialize(t.children, newEmphasis), t.weight, newEmphasis));
                }
            } else if (t.type === "editing") {
                if (newEmphasis) {
                    a.push(
                        wrapParenByWeight(
                            `[${t.children[0]?.type !== "null" ? serialize([t.children[0]], newEmphasis) : ""}:${
                                t.children[1]?.type !== "null" ? serialize([t.children[1]], newEmphasis) : ""
                            }:${t.breakpoint.toDecimalPlaces(3).toNumber()}]`,
                            t.weight,
                            newEmphasis
                        )
                    );
                } else {
                    a.push(wrapParenByWeight(serialize(t.children, newEmphasis), t.weight, newEmphasis));
                }
            } else if (t.type === "group") {
                a.push(wrapParenByWeight(serialize(t.children, newEmphasis), t.weight, newEmphasis));
            }
            return a;
        }, [])
    ).join(", ");
}




function useCart() {
    
    const tagStore = useTagStore()
    const settingsStore = useSettingsStore()

    const [positive, setPositive] = useState([])
    const [negative, setNegative] = useState([])

    const positivePresets = useMemo(() => {
        return ImmutableSet.of(
            ...positive
                .filter((t) => t.type === 'preset')
                .map(({ category, name }) => JSON.stringify([category, name])
            )
        )
    }, [positive])

    const negativePresets = useMemo(() => {
        return ImmutableSet.of(
            ...negative.filter((t) => t.type === "preset")
                .map(({ category, name }) => JSON.stringify([category, name]))
        );
    }, [negative]);

    const positiveTagsShallow = useMemo(() => {
        return ImmutableSet.of(
            ...positive
                .filter(({ type }) => ["tag", "embedding", "preset"].includes(type))
                .flatMap(tagNameMapper)
        );
    }, [positive])

    const negativeTagsShallow = useMemo(() => {
        return ImmutableSet.of(
            ...negative.filter(({ type }) => ["tag", "embedding", "preset"].includes(type))
                .flatMap(tagNameMapper)
        );
    }, [negative]); 

    const existsPositive = (type, name, category = null) => {
        if (type === "tag" || type === "embedding") {
            return positiveTagsShallow.includes(name);
        } else if (type === "preset") {
            return positivePresets.includes(JSON.stringify([category, name]));
        }
        return false;
    }

    const existsNegative = (type, name, category = null) => {
        if (type === 'tag' || type === 'embedding') {
            return negativeTagsShallow.includes(name)
        } else if (type === 'preset') {
            return negativePresets.includes(
                JSON.stringify([category, name])
            )
        }
        return false
    }

    const appendPositiveTag = (tagName, weight = new Decimal(1)) => {
        appendTag(
            positive,
            setPositive,
            tagName,
            weight,
            existsPositive,
            removeNegativeTag
        )
    }

    const removePositiveTag = (tagName, type = 'tag') => {
        removeTag(positive, setPositive, tagName, type)
    }

    const appendNegativeTag = (tagName, weight = new Decimal(1)) => {
        appendTag(
            negative,
            setNegative,
            tagName,
            weight,
            existsNegative,
            removePositiveTag
        )
    }

    const removeNegativeTag = (tagName, type = 'tag') => {
        removeTag(negative, setNegative, tagName, type)
    }

    function determineNextSwitchableMixture(item) {
        if (item.type === 'editing') {
            const effectiveChildren = item.children.filter(
                (child) => child.type !== 'null'
            )
            if (effectiveChildren.length < 2) return 'group'
            else return 'alternate'
        } else if (item.type === 'alternate') {
            return 'group'
        } else if (item.type === 'group') {
            if (item.children.length < 3) {
                return 'editing'
            } else {
                return 'alternate'
            }
        }
        return null
    }

    function isMixtureSwitchable( item, to = null) {
        if (to === null) {
            return determineNextSwitchableMixture(item)
        } else if (to === item.type) {
            return false
        } else if (to === 'group' && item.type !== 'group') {
            return item.children.some(
                (child) =>
                    child.type !== 'null'
            )
        } else if (to === 'editing' && item.type !== 'editing') {
            return item.children.length < 3
        } else if (to === 'alternate' && item.type !== 'alternate') {
            const effectiveChildren = item.children
                .filter(
                    (child) =>
                        child.type !== 'null'
                )
            return effectiveChildren.length > 1
        } else {
            return false
        }
    }
    
    function switchMixtureType(direction, item, to) {
        const root =
            item?.parent?.children ??
            (direction !== null ? this[direction] : null)
        if (root === null) {
            throw new Error('Switching mixture type on null')
        }

        const dest = to ?? determineNextSwitchableMixture(item)
        if (!dest) return
        const switchable = isMixtureSwitchable(item, dest)
        if (switchable) {
            if (dest === 'editing' && item.type !== 'editing') {
                const newMixture = {
                    type: 'editing',
                    label: '标签替换',
                    breakpoint: new Decimal(0),
                    children: null,
                    weight: new Decimal(1),
                    parent: item?.parent ?? null,
                }

                const children = item.children
                    .map(
                        (child) => {
                            if (
                                child.type === 'group' &&
                                child.children.length === 1
                            ) {
                                return {
                                    ...child.children[0],
                                    parent: newMixture,
                                }
                            } else {
                                return {
                                    ...child,
                                    parent: newMixture,
                                }
                            }
                        }
                    )
                    .slice(0, 2)
                if (children.length === 1) {
                    children.push({
                        type: 'null',
                        label: '无标签',
                        parent: newMixture,
                        children: null,
                    })
                } else if (children.length !== 2) {
                    throw new Error('Invalid state')
                }
                newMixture.children = children

                const idx = root.indexOf(item)
                root.splice(idx, 1, newMixture)
            } else if (dest === 'alternate' && item.type !== 'alternate') {
                const newMixture = {
                    type: 'alternate',
                    label: '标签轮转',
                    parent: item?.parent ?? null,
                    children: null,
                    weight: new Decimal(1),
                }
                newMixture.children = item.children
                    .filter(
                        (child) => child.type !== 'null'
                    )
                    .map((child) => {
                        if (
                            child.type === 'group' &&
                            child.children.length === 1
                        ) {
                            return {
                                ...child.children[0],
                                parent: newMixture,
                            }
                        } else {
                            return {
                                ...child,
                                parent: newMixture,
                            }
                        }
                    })

                const idx = root.indexOf(item)
                root.splice(idx, 1, newMixture)
            } else if (dest === 'group' && item.type !== 'group') {
                const newMixture = {
                    type: 'group',
                    label: '标签组',
                    parent: item?.parent ?? null,
                    children: null,
                    weight: new Decimal(1),
                }
                newMixture.children = item.children
                    .filter(
                        (child) => child.type !== 'null'
                    )
                    .map((child) => {
                        if (
                            child.type === 'group' &&
                            child.children.length === 1
                        ) {
                            return {
                                ...child.children[0],
                                parent: newMixture,
                            }
                        } else {
                            return {
                                ...child,
                                parent: newMixture,
                            }
                        }
                    })

                const idx = root.indexOf(item)
                root.splice(idx, 1, newMixture)
            }

            this.convergeToFit(root)
        }
    }

    function dismissCartItem(direction, item) {
        const root = item.parent?.children ?? this[direction]
        const parent = item.parent
        const children = item.children
            .filter(
                (n) => n.type !== 'null'
            )
            .map(
                (n) => ({
                    ...n,
                    parent,
                    ...(n.type !== 'group' && { weight: new Decimal(1) }),
                })
            )
        root.splice(
            root.indexOf(item),
            1,
            ...children
        )
        this[setter(direction)]([...this[direction]]);
    }

    function convergeToFit(root) {
        const isRoot = root === positive || root === negative
        let changed = true
        while (changed) {
            changed = false
            for (let i = 0; i < root.length; i++) {
                const { type, children, parent } = root[i]
                if (isRoot && parent !== null) {
                    root[i].parent = null
                    changed = true
                }
                if (children) {
                    for (let j = 0; j < children.length; j++) {
                        if (children[j].parent !== root[i]) {
                            children[j].parent = root[i]
                            changed = true
                        }
                    }
                    this.convergeToFit(children)
                }
                if (type === 'editing') {
                    const valid =
                        children?.some((child) => child.type !== 'null') ??
                        false
                    const singular = children?.length === 1
                    const tooMany = children?.length > 2
                    if (!valid) {
                        root.splice(i, 1)
                        changed = true
                        i--
                    } else if (singular) {
                        children.push({
                            type: 'null',
                            label: '无标签',
                            parent: root[i],
                            children: null,
                        })
                        changed = true
                        i--
                    } else if (tooMany) {
                        this.switchMixtureType(
                            null,
                            root[i],
                            'alternate'
                        )
                        changed = true
                        i--
                    }
                } else if (type === 'alternate' || type === 'group') {
                    const valid = children?.length > 0
                    const singular = children?.length === 1
                    if (!valid) {
                        root.splice(i, 1)
                        changed = true
                        i--
                    } else if (singular && type !== 'group') {
                        const child = children[0]
                        root.splice(i, 1, { ...child, parent: root[i] })
                        changed = true
                        i--
                    }
                }
            }
        }
        setNegative([...negative])
        setPositive([...positive])
    }

    function setter(name) {
        return this[`set${name.charAt(0).toUpperCase() + name.slice(1)}`];
    }

    function appendCartItem(direction, item) {
        this.setter(direction)([...this[direction], item]);
    }

    function removeCartItem(direction, item) {
        const root = item.parent?.children ?? this[direction];
        root.splice(root.indexOf(item), 1);
        this.setter(direction)([...this[direction]]);

        if (item.parent !== null) {
            if (item.parent.type === "preset") {
                this.dismissCartItem(direction, item.parent);
            }
            this.convergeToFit(item.parent.parent?.children ?? this[direction]);
        }
    }

    function genPositiveTag(tagName, weight = new Decimal(1)) {
        return genTag(tagName, weight, existsPositive);
    }

    function genNegativeTag(tagName, weight = new Decimal(1)) {
        return genTag(tagName, weight, existsNegative);
    }

    function genTag(tagName, weight, existsFn) {
        if (existsFn("tag", tagName)) return null;
        const tag = tagStore.resolve(tagName);
        if (tag !== null) {
           return {
               label: `${tagName} - ${tag.meta.name}`,
               type: "tag",
               name: tagName,
               category: tag.meta.category,
               weight,
               children: null,
               parent: null,
           }; 
        }
        return {
            label: tagName,
            type: "tag",
            name: tagName,
            category: null,
            weight,
            parent: null,
            children: null,
        };
    }

    function appendTag(ref, setFn, tagName, weight, existsFn, removeRevFn) {
        // const embeddingStore = useEmbeddingStore()

        if (existsFn("tag", tagName)) return;
        const tag = tagStore.resolve(tagName);
        if (tag !== null) {
            setFn([...ref, {
                label: `${tagName} - ${tag.meta.name}`,
                type: "tag",
                name: tagName,
                category: tag.meta.category,
                weight,
                children: null,
                parent: null,
            }])

            removeRevFn(tagName, "tag");
            return;
        }

        // const embedding = embeddingStore.resolve(tagName)
        // if (embedding !== null) {
        //     ref.push({
        //         label: `[E] ${tagName} - ${embedding.name}`,
        //         type: 'embedding',
        //         name: tagName,
        //         category: embedding.category,
        //         weight,
        //         parent: null,
        //         children: null,
        //     })
        //     removeRevFn(tagName, 'embedding')
        //     return
        // }

        setFn([...ref, {
            label: tagName,
            type: "tag",
            name: tagName,
            category: null,
            weight,
            parent: null,
            children: null,
        }])
        removeRevFn(tagName, "tag");
    }

    function removeTag(ref, setFn, tagName, type = "tag") {
        // Direct tags
        const index = ref.findIndex((n) => n.type === type && n.name === tagName);
        if (index !== -1) {
            ref.splice(index, 1);
        } else {
            // Complex groups
            for (let i = 0; i < ref.length; i++) {
                if (ref[i].type === "preset") {
                    const preset = ref[i];
                    const parent = preset.parent;
                    const idx = preset.children.findIndex((n) => n.type === type && n.name === tagName);
                    if (idx !== -1) {
                        // Decompose the preset
                        const decomposedTagArray = preset.children
                            .filter((n) => n.name !== tagName)
                            .map((n) => ({ ...n, parent }));
                        ref.splice(i, 1, ...decomposedTagArray);
                    }
                } else if (ref[i].type === "editing" || ref[i].type === "alternate") {
                    const item = ref[i];
                    const idx = item.children.findIndex((n) => n.type === type && n.name === tagName);
                    item.children.splice(idx, 1);
                    if (item.children.every((n) => n.type === "null")) {
                        // No effective item in this mixture
                        ref.splice(i, 1);
                    } else if (item.children.length === 1) {
                        // Only one effective item in this mixture
                        const child = item.children[0];
                        const parent = item.parent;
                        if (child.type === "tag" || child.type === "embedding") {
                            if (item.type === "editing") {
                                item.children.push({
                                    type: "null",
                                    label: "无标签",
                                    parent: item,
                                    children: null,
                                });
                            } else {
                                ref.splice(i, 1, {
                                    ...child,
                                    parent,
                                    weight: new Decimal(1),
                                });
                            }
                        }
                    }
                }
            }
        }
        setFn([...ref])
    }

    function clear() {
        setNegative([])
        setPositive([])
    }

    function importClassic(direction, content) {
        // as per https://github.com/wfjsw/danbooru-diffusion-prompt-builder/issues/6
        // this.clear()
        const run = ( text, genFn)  => {
            let tags = []
            let weight = new Decimal(1)
            let guessNew = true
            const trimmedText = text.trim()
            if (trimmedText === '') return []
            const textList = trimmedText
                .replaceAll('_', ' ')
                .split(/\s*,\s*|\s*，\s*/)
            for (const token of textList) {
                let tag = null
                let text = null
                // TODO: parse alternate/editing
                // check numeric emphasis
                const numericalEmphasis = token.match(
                    /\(([^:]+):(\d+(?:.\d+)?)\)/
                )
                if (numericalEmphasis) {
                    const [content, emphasis] = numericalEmphasis.slice(1)
                    text = content
                    weight = new Decimal(emphasis)
                } else {
                    for (const char of token) {
                        if (char === '(') {
                            guessNew = true
                            weight = weight.times(1.1)
                        } else if (char === '{') {
                            guessNew = false
                            weight = weight.times(1.05)
                        } else if (char === '[') {
                            weight = weight.times(guessNew ? 1.1 : 1.05)
                        } else {
                            break
                        }
                    }
                    const name = token.match(/^[([{]*(.*?)[)\]}]*$/)
                    if (name) {
                        text = name[1]
                        if (text.endsWith('\\')) {
                            text +=
                                token[token.lastIndexOf(text) + text.length]
                        }
                    }
                }
                if (text) { 
                    text = text
                        .replaceAll('\\(', '(')
                        .replaceAll('\\)', ')')
                        .replaceAll('\\[', '[')
                        .replaceAll('\\]', ']')
                        .replaceAll('\\{', '{')
                        .replaceAll('\\}', '}')
                        .toLowerCase()
                    let matchText = text
                    let resolvedTag = tagStore.resolve(text)
                    if (!resolvedTag) {
                        matchText = text + 's'
                        resolvedTag = tagStore.resolve(matchText)
                    }
                    if (!resolvedTag) {
                        matchText = text + 'es'
                        resolvedTag = tagStore.resolve(matchText)
                    }
                    if (!resolvedTag && text.endsWith('s')) {
                        matchText = text.slice(0, -1)
                        resolvedTag = tagStore.resolve(matchText)
                    }
                    if (!resolvedTag && text.endsWith('es')) {
                        matchText = text.slice(0, -2)
                        resolvedTag = tagStore.resolve(matchText)
                    }
                    matchText = resolvedTag ? matchText : text
                    tag = genFn(matchText, weight);
                }
                if (!numericalEmphasis) {
                    const reversed = Array.from(token).reverse()
                    for (let i = 0; i < reversed.length; i++) {
                        const char = reversed[i]
                        const nextChar = reversed[i + 1]
                        if (nextChar !== '\\') {
                            if (char === ')' && nextChar !== '\\') {
                                guessNew = true
                                weight = weight.div(1.1)
                            } else if (char === '}') {
                                guessNew = false
                                weight = weight.div(1.05)
                            } else if (char === ']') {
                                weight = weight.div(guessNew ? 1.1 : 1.05)
                            } else {
                                break
                            }
                        } else {
                            break
                        }
                    }
                } else {
                    weight = new Decimal(1)
                }
                if (tag != null) {
                    tags.push(tag)
                }
            }
            return tags
        }
        const tags = run(
            content,
            direction === 'positive'
                ? genPositiveTag
                : genNegativeTag
        )
        if (direction === 'positive') {
            setPositive([...tags])
        } else {
            setNegative([...tags])
        }
    }
    
    const positiveToString = useMemo(() => {
        return serialize(positive, settingsStore.newEmphasis)
    }, [positive, settingsStore.newEmphasis])

    const negativeToString = useMemo(() => {
        return serialize(negative, settingsStore.newEmphasis)
    }, [negative, settingsStore.newEmphasis])

    return {
        positive,
        setPositive,
        negative,
        setNegative,
        setter,
        existsPositive,
        existsNegative,
        appendPositiveTag,
        removePositiveTag,
        appendNegativeTag,
        removeNegativeTag,
        removeCartItem,
        appendCartItem,
        dismissCartItem,
        switchMixtureType,
        convergeToFit,
        clear,
        importClassic,
        positiveToString,
        negativeToString
    };
}

export const [useCartStore] = createGlobalStore(useCart)