import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Button as AButton, Space, Button, Dropdown, Image, Form, Row, InputNumber, Input } from "antd";

import {
    EyeOutlined, SettingOutlined, EyeInvisibleOutlined,
    FileAddOutlined, DeleteOutlined, SaveOutlined,
    LockOutlined, UnlockOutlined,
    ColumnWidthOutlined, ColumnHeightOutlined,
    RotateRightOutlined
} from '@ant-design/icons';

import { AgGridReact } from 'ag-grid-react';

import "ag-grid-community/styles/ag-grid.css";
import "../styles/ag-theme-custom.css";
import { Icon, Slide } from "@mui/material";
import AppContext from "antd/es/app/context";
import LayerStylePanel from "./LayerStylePanel";
import Resizer from "../components/Resizer/Resizer";
import { datetimeString } from "../utils/util.js";
import { downloadImageResource, downloadJSONResource } from "../utils/download.js";

function LayerPanel(props) {


    // console.log("LayerPanel::init............................ : " + window.PSMan);

    // https://www.ag-grid.com/react-data-grid/getting-started/
    const gridRef = useRef();

    

    useEffect(() => {
        console.log("LayerPanel::componentWillMount() " + window.PSMan);

        window.PSMan_onLayerEvents = function (nm, obj) {
            console.log("LayerPanel layer event: ", nm, obj);

            if (nm.startsWith("layer::")) {
                var layersReverse = Array.from(obj.layers).reverse();
                var rowData = layersReverse.map((item, idx) => ({
                    key: item.id,
                    vis: [item.vis, item.id],
                    id: item.id,
                    idx: item.idx,
                    name: item.name,
                    thumbnail: item.thumbnail,
                    trans: item.t,
                    onNameUpdate: (aid, v) => {
                        // console.log("RDD : ", rowData);
                        xupdateName(aid, v, rowData);
                    }
                }));

                let trl = layersReverse.filter((item) => item.id == obj.cur);

                if (trl.length >= 1) {

                    let tri = trl[0];
                    console.log("TRL: ", trl, tri);
                    setTransX(0);
                    setTransY(0);
                    setTransW(0);
                    setTransH(0);
                    setTransDegree(0);

                    if (tri.t !== undefined) {
                        let tr = tri.t;
                        let w = tr[2] - tr[0]
                        let h = tr[3] - tr[1]
                        setTransX(tr[0]);
                        setTransY(tr[1]);
                        setTransW(w);
                        setTransH(h);

                        if (tri.tr !== undefined) {
                            setTransDegree(tri.tr.r);
                            setTransDegreeDisabled(false);
                            setTransXYDisabled(false);
                            setTransWHDisabled(true);

                            posX.current = tri.tr.t[0];
                            posY.current = tri.tr.t[1];
                        } else {
                            // setTransDegree(0);
                            setTransDegreeDisabled(true);
                            setTransXYDisabled(false);
                            setTransWHDisabled(false);
                            posX.current = tr[0] + w / 2;
                            posY.current = tr[1] + h / 2;
                        }
                    } else {
                        console.log("==================================================")
                        setTransDegreeDisabled(true);
                        setTransXYDisabled(true);
                        setTransWHDisabled(true);
                        // setTransDegree(0);
                        // setTransDegreeDisabled(true);
                    }

                }


                setRowData(rowData);
                setSelectedId(obj.cur);

            }
        }

        return () => {
            console.log("LayerPanel::componentWillUnmount()");
            // Anything in here is fired on component unmount.
            delete window.PSMan_onLayerEvents;
        }
    }, [])



    const newLayer = () => {
        // let o = window.PSMan.instance.layer_manager.new();

        // let o = window.PSMan.getInstance().layerManager().current();
        // let o = window.PSMan.getInstance().layerManager().new();
        let o = window.PSMan.getInstance().layerManager().newForMask();

        console.log(" =======> ", o);
        // window.PSMan.instance.layer_manager.layer(3).set_config({a:100});
        // window.PSMan.instance.layer_manager.layer(1).set_config({a:100});
    }

    const delLayer = () => {

        let cid = window.PSMan.getInstance().layerManager().current().id();
        // console.log(" =======> ", cid);
        // let o = window.PSMan.instance.layer_manager.delete();
        let o = window.PSMan.getInstance().layerManager().delete(cid);
        console.log(" =======> ", o);
        // window.PSMan.instance.layer_manager.layer(3).set_config({a:100});
        // window.PSMan.instance.layer_manager.layer(1).set_config({a:100});
    }

    const saveLayer = () => {

        let cid = window.PSMan.getInstance().layerManager().current().id();
        // console.log(" =======> ", cid);
        // let o = window.PSMan.instance.layer_manager.delete();
        let o = window.PSMan.getInstance().layerManager().saveAsResource(cid);
        console.log(" =======> ", o);
        // window.PSMan.instance.layer_manager.layer(3).set_config({a:100});
        // window.PSMan.instance.layer_manager.layer(1).set_config({a:100});
    }

    const [stylePanelOpen, setStylePanelOpen] = useState(false)
    const [styleLayerData, setStyleLayerData] = useState(null)
    const layerConfig = useRef(null)





    const [rowData, setRowData] = useState([
    ]);



    const [selectedId, setSelectedId] = useState(-1);
    const selectedIds = useRef([]);




    const dragEnterIndex = useRef(-1);

    const onRowDragEnter = (e) => {
        // console.log('onRowDragEnter', e);
        // console.log('onRowDragEnter0', e.node.data.id);
        console.log('onRowDragEnter: ', e.overIndex);
        dragEnterIndex.current = e.overIndex;
    };

    const onRowDragEnd = (e, end) => {
        // console.log('onRowDragEnd', e);
        // console.log('onRowDragEnd0', e.node.data.id);
        let pv = dragEnterIndex.current;
        dragEnterIndex.current = -1;
        let cv = e.overIndex;
        console.log('onRowDragEnd: ' + end, pv, cv, rowData.length);
        if (!end) {
            return;
        }

        if (cv < 0) {
            cv = pv;
        }
        console.log('onRowDragEnd: :::::: ', rowData[pv], rowData[cv]);

        // window.PSMan.instance.layer_manager.swap(rowData[cv].id,
        // rowData[pv].id);
        console.log("onRowDragEnd : ", rowData[pv].idx, rowData[cv].idx);
        let o = window.PSMan.getInstance().layerManager().move(rowData[pv].idx,
            rowData[cv].idx);

    };

    const onRowClicked = (e) => {
        const targetClassList = e.event.target.classList
        if (
            !targetClassList.contains("ag-cell") &&
            !targetClassList.contains("ag-cell-value") &&
            !targetClassList.contains("ag-cell-wrapper") &&
            !targetClassList.contains("layer-name")
        ) {
            return;
        }
        // e.node.setSelected(true, false);

        let agridapi = e.api;
        let nodes = agridapi.getSelectedNodes();
        let rows = agridapi.getSelectedRows();
        // console.log("xxxxxxxxxxxxxxx", nodes);
        // console.log("xxxxxxxxxxxxxxx", rows);

        selectedIds.current = [];

        if (nodes.length == 0) {
            console.log("NO ROW SELECTED!!");
            return;
        }
        if (nodes.length == 1) {
            // setSelectedIds([])
            let id = rowData[nodes[0].rowIndex].id;
            console.log("onSelectionChanged", id, window.PSMan.getInstance().layerManager().layer(id))
            window.PSMan.getInstance().layerManager().select(null);
            let o = window.PSMan.getInstance().layerManager().layer(id).activate();
        } else {
            let ids = nodes.map((v, idxx) => ({ idx: rowData[v.rowIndex].idx, id: rowData[v.rowIndex].id }));
            let sids = nodes.map((v, idxx) => rowData[v.rowIndex].id );

            selectedIds.current = ids;
            console.log("IDS:: ", ids, selectedIds.current, sids);
            window.PSMan.getInstance().layerManager().select(sids);
        }
        
    };

    const onRowDataUpdated = (e) => {
        let agridapi = gridRef.current.api;

        console.log("+++++++++++= : ", agridapi);
        console.log("+++++++++++=  ID : ", selectedId, selectedIds.current);

        agridapi.forEachNode((rowNode, index) => {
            // console.log("-------------: ", rowNode);
            if (rowNode.data.id == selectedId) {
                // console.log("-------------: ", rowNode);
                rowNode.setSelected(true);
            }

            let aa = selectedIds.current.filter(it => it.id == rowNode.data.id);
            if (aa.length > 0) {
                rowNode.setSelected(true);
            }
        });

    };


    const xupdateName = (id, nm, rd) => {
        // console.log("xupdateName : ", id, nm, rd);
        let nrd = rd.map((item) => {
            let nit = {...item}
            if (item.id == id) {
                nit.name = nm;
            }
            return nit;
        });
        setRowData(nrd);
    };

    const cvtLayer = () => {

        let cid = window.PSMan.getInstance().layerManager().current().id();
        // console.log(" =======> ", cid);


        // let o = window.PSMan.instance.layer_manager.delete();
        // let o = window.PSMan.getInstance().layerManager().current().rasterize();
        let o = window.PSMan.getInstance().layerManager().current().createMask();

        console.log(" =======> ", o);
        // window.PSMan.instance.layer_manager.layer(3).set_config({a:100});
        // window.PSMan.instance.layer_manager.layer(1).set_config({a:100});
    }
    const testDownload = (e) => {

    }

    // const [maskShow, setMaskShow] = React.useState(false);

    // const onMaskModeChange = (on, e) => {
    //     console.log("--------- maskMode: ", on);
    //     let o = window.PSMan.getInstance().maskManager().enable(on);
    //     if (on) {
    //         window.PSMan.getInstance().maskManager().show(on);
    //         setMaskShow(on);
    //     }
    // };
    // const onMaskShowChange = (vs, e) => {
    //     console.log("--------- maskShow: ", vs.target.checked);
    //     // window.PSMan.getInstance().maskManager().show(vs.target.checked);
    //     window.PSMan.getInstance().maskManager().reverse();
    //     setMaskShow(vs.target.checked);
    // };
    function findAncestor(className, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node.classList?.contains(className)) {
                return node;
            }
            node = node.parentNode;
        }
        return null;
    }

    const nmMap = new Map([
        ["erase", [3, "清空图层"]],
        ["rasterize", [1, "栅格化图层"]],
        ["create-alpha-mask", [2, "创建Alpha选区"]],

    ]);
    const nmArray = [...nmMap].map(([k, v]) => ([k, v]))


    const menuItems = [
        {
            label: "混合模式",
            key: "101",
        },
        {
            label: "复制图层",
            key: "103",
        },
        {
            label: "删除图层",
            key: "102",
        },
        {
            label: "保存图层到本地",
            key: "105",
        },
    ];

    const menuItems2 = [
        {
            label: "合并图层",
            key: "104",
        },
        {
            label: "删除图层",
            key: "102",
        },
        {
            label: "保存图层到本地",
            key: "105",
        },
    ];

    const menuItems3 = [
        {
            label: "删除图层",
            key: "102",
        },
        {
            label: "保存图层到本地",
            key: "105",
        },
    ];

    const [items, setItems] = useState([])


    const onMenuClick = ({ key }) => {
        console.log("ooo menuclick", key, menuLayer.current);

        if (key == "101") {
            layerConfig.current = window.PSMan.getInstance().layerManager().layer(menuLayer.current.id).getConfig();
            setStylePanelOpen(true);
            setStyleLayerData(menuLayer.current);
        } else {
            let id = menuLayer.current.id;

            if (key == "102") {
                let dids;
                let ids = selectedIds.current;
                if (ids.length > 0) {
                    dids = ids.map(item => item.id);
                } else {
                    dids = id;
                }
                let o = window.PSMan.getInstance().layerManager().delete(dids);
            } else if (key == "103") {
                let o = window.PSMan.getInstance().layerManager().duplicate(id);
            } else if (key == "104") {
                let ids = selectedIds.current;
                let idx0 = ids[ids.length - 1].idx
                let idx1 = ids[0].idx
                console.log("MERGE, [[ ", idx0, idx1);
                let o = window.PSMan.getInstance().layerManager().mergeRange(idx0, idx1);
                // let o = window.PSMan.getInstance().layerManager().eraseMask(id);
            } else if (key == "105") {
                let suffix = datetimeString();

                let dids = [id];
                if (selectedIds.current.length > 0) {
                    dids = selectedIds.current.map(item => item.id); 
                }

                for (let i = 0; i < dids.length; i++) {
                    let id = dids[i];
                    let o = window.PSMan.getInstance().layerManager().encodeImage((ok, img) => {
                        let oname = "layer-" + id + "-" + suffix + ".png";
                        downloadImageResource(oname, img);
                    }, id);
                }
                    
            } else {
                console.log("nmArray----", nmArray);
                const cc = nmArray.filter((o) => o[1][0] == key);
                // let id = menuLayer.current.id;

                let aname = cc[0][0];
                console.log("-===== ", aname);
                if (aname == "rasterize") {
                    let o = window.PSMan.getInstance().layerManager().layer(id).rasterize();
                } else {
                    let o = window.PSMan.getInstance().layerManager().layer(id).sendAction(aname, {});
                }

            }
        }

    };

    const menuLayer = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)

    const handleContextMenu = useCallback(
        (e) => {
            const ancestor = findAncestor("ag-row", e.target);
            if (ancestor && ancestor.classList.contains("layer-panel-row")) {
                e.preventDefault();
                const idx = ancestor.getAttribute("row-index");
                menuLayer.current = rowData[idx];

                console.log("menuLayer, ", menuLayer.current);
                console.log("selectedIds, ", selectedIds.current);

                if (selectedIds.current.length == 0) {
                    // 单选的情况
                    let id = menuLayer.current.id;
                    let acts = window.PSMan.getInstance().layerManager().layer(id).getActions();
                    console.log("Layer actions : ", acts, nmMap);
                    // let acts = curtool.getActions();
                    if (acts != null) {
                        acts = acts.filter(v => v.flags === undefined || v.flags === 1);
                        let nmenuItems = acts.map((item, idx) => ({ name: item.name, key: idx }));
                        let nmenuItems1 = nmenuItems.map((item, idx) => ({
                            name: item.name,
                            key: nmMap.get(item.name)[0],
                            label: nmMap.get(item.name)[1],
                        }));
                        const merged = [...menuItems, ...nmenuItems1];
                        // console.log(merged);
                        setItems(merged);
                    } else {
                        setItems([]);
                    }
                } else {
                    // 多选的情况
                    // selectedIds.current.length >= 2
                    const mergeOrMulSel = (ids) => {
                        let idx0 = ids[ids.length - 1].idx
                        let idx1 = ids[0].idx
                        if (idx1 - idx0 == ids.length - 1) {
                            return true;
                        }
                        return false;
                    }

                    if (mergeOrMulSel(selectedIds.current)) {
                        // 如果选择的图层是连续的
                        setItems(menuItems2);
                    } else {
                        setItems(menuItems3);
                    }
                }
                setMenuOpen(true);
            } else {
                setMenuOpen(false);
            }
        }
        ,
        [rowData]
    );

    const cancelContextMenu = useCallback(
        () => {
            if (menuOpen) {
                setMenuOpen(false)
            }
        }, [menuOpen])

    useEffect(() => {
        document.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("click", cancelContextMenu);
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
            document.removeEventListener("click", cancelContextMenu);
        };
    }, [handleContextMenu, cancelContextMenu]);


    function VisComponent(props) {

        const VisibleButtonClick = (vis) => {
            // var arr = window.PSMan.instance.layer_manager.layer(vis[1]).set_config({ vis: !vis[0] });
            let o = window.PSMan.getInstance().layerManager().layer(vis[1]).setConfig({ vis: !vis[0] });
        }

        return (
            <div style={{ height: "100%" }}>
                {
                    props.value[0]
                        ?
                        <AButton type="text" style={{ height: "100%" }} icon={<EyeOutlined />}
                            onClick={() => VisibleButtonClick(props.value)}></AButton>
                        :
                        <AButton type="text" style={{ height: "100%" }} icon={<EyeInvisibleOutlined />}
                            onClick={() => VisibleButtonClick(props.value)}></AButton>
                }
            </div>
        );
    }

    function ThumbnailComponent(props) {

        return (
            <Image style={{ objectFit: "contain", height: "100%", width: "100%", }}
                preview={false} src={`data:image/jpeg;base64,${props.data.thumbnail}`} />
        );
    }

    const operateComponent = (props) => {

        const operateComponent_onClick = () => {
            layerConfig.current = window.PSMan.getInstance().layerManager().layer(props.data.id).getConfig();
            setStylePanelOpen(true);
            setStyleLayerData(props.data)
        }

        return (
            <div style={{ height: "100%" }}>
                <Button style={{ height: "100%" }} type="text" icon={<SettingOutlined />}
                    onClick={operateComponent_onClick} />
            </div>
        )
    }

    function NameComponent(props) {
        const [inputEnable, setInputEnable] = useState(false)

        const inputRef = useRef(null)

        const showInput = () => {
            setInputEnable(true);
            setTimeout(() => {
                inputRef.current.input?.focus();
                inputRef.current.input?.select();
            }, 0)
        }

        const updateName = () => {
            // console.log("ooo", inputRef.current.input.value);
            // console.log("oooooooooooo = ", props.data, props.rowData);
            let nname = inputRef.current.input.value;
            let id = props.data.id;
            console.log("updateName :: ", id, nname);
            window.PSMan?.getInstance().layerManager().layer(id).setName(nname);
            setInputEnable(false);
            props.data.onNameUpdate(id, nname);
        }

        return (
            <div onDoubleClick={showInput} className="layer-name">
                {inputEnable && (
                    <Input
                        ref={inputRef}
                        style={{ border: "none", outline: "none" }}
                        onBlur={updateName}
                        defaultValue={props.data.name}
                        onPressEnter={updateName}
                    />
                )}
                {!inputEnable && props.data.name}
            </div>
        );
    }

    const [columnDefs] = useState([
        {
            headerName: "",
            field: "vis",
            cellRenderer: VisComponent,
            width: 70,
            rowDrag: (params) => !params.node.group,
        },
        {
            headerName: 'thumb',
            width: 40,
            height: 36,
            cellRenderer: ThumbnailComponent,
        },
        {
            headerName: "Name",
            field: "name",
            width: 125,
            cellRenderer: NameComponent
        },
        {
            headerName: "",
            cellRenderer: operateComponent,
            width: 50,
        },
    ]);


    const [transLock, setTransLock] = useState(true);
    const [transW, setTransW] = useState(1000);
    const [transH, setTransH] = useState(1000);
    const [transX, setTransX] = useState(20);
    const [transY, setTransY] = useState(20);
    const [transDegree, setTransDegree] = useState(0);
    const [transDegreeDisabled, setTransDegreeDisabled] = useState(false);
    const [transWHDisabled, setTransWHDisabled] = useState(false);
    const [transXYDisabled, setTransXYDisabled] = useState(false);

    const posX = useRef(0);
    const posY = useRef(0);

    const updateValue = (e, b, cb) => {
        if (b) {
            if (e && e.target) {
                console.log("==========", e.target.value);
                let cv = 0;
                if (e.target.value) {
                    cv = parseInt(e.target.value);
                }
                cb(cv);
            }
        } else {
            cb(e);
        }
    }

    // useEffect(() => {
    //     let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ pos: [transX, transY], rot: transDegree });
    // }, [transX, transY, transDegree]);

    // trans --------------------------------------------------
    const lock_onClick = () => {
        if (transLock) {
            setTransLock(false);
        }
        else {
            setTransLock(true);
        }

    }

    const updateTransW = (val) => {
        if (val == null) return;
        if (!transLock) {
            let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ width: val });
        } else {
            let nh = val * transH / transW;
            let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ width: val, height: nh });
        }
        setTransW(val);
    }

    const updateTransH = (val) => {
        if (val == null) return;
        if (!transLock) {
            let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ height: val });
        } else {
            let nw = val * transW / transH;
            let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ width: nw, height: val });
        }
        setTransH(val);
        // let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ height: val });
    }

    const updateTransX = (val) => {
        if (val == null) return;

        let nv = posX.current + (val - transX);
        posX.current = nv;
        setTransX(val);
        let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ posX: nv });
    }

    const updateTransY = (val) => {
        if (val == null) return;

        let nv = posY.current + (val - transY);
        posY.current = nv;
        setTransY(val);
        let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ posY: nv });
    }

    const updateTransDegree = (val) => {
        if (val == null) return;
        setTransDegree(val);
        let o = window.PSMan?.getInstance().layerManager().layer(selectedId).setConfig({ rot: val });

    }

    const flipH_onClick = () => {
        let o = window.PSMan?.getInstance().layerManager().layer(selectedId).sendAction("flip", "h");
    }

    const flipV_onClick = () => {
        let o = window.PSMan?.getInstance().layerManager().layer(selectedId).sendAction("flip", "v");
    }


    return (
        <>
            {/* <Stack direction="horizontal" gap={1}>
                    <Switch
                        style={{ width: 40 }}
                        checkedChildren="M"
                        unCheckedChildren="L"
                        defaultChecked={false}
                        onChange={onMaskModeChange}
                    />
                    <Checkbox onChange={onMaskShowChange} checked={maskShow}>
                        show
                    </Checkbox>
                </Stack> */}

            {/* <div className="ag-theme-balham-dark" style={{ height: 200 }}> */}
            <Dropdown
                menu={{
                    items: items,
                    onClick: onMenuClick,
                }}
                trigger={["contextMenu"]}
                open={menuOpen}>

                <Resizer maxHeight={450} minHeight={100} wrapper={<div></div>}>
                    <div className="ag-theme-custom-dark" style={{ height: 200 }}>
                        <AgGridReact className="resource-grid" rowClass="layer-panel-row"
                            headerHeight={0}
                            rowHeight={36}
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            suppressHorizontalScroll={true}
                            suppressCellFocus={true}
                            suppressMovableColumns={true}
                            // suppressRowClickSelection={true}
                            rowDragManaged={true}
                            animateRows={true}
                            // rowSelection="single"
                            rowSelection={"multiple"}
                            // rowMultiSelectWithClick={true}
                            // enableRangeSelection={true}
                            onRowDragEnter={onRowDragEnter}
                            onRowDragEnd={(e) => onRowDragEnd(e, true)}
                            onRowDragLeave={(e) => onRowDragEnd(e, false)}
                            // onRowDragLeave={onRowDragLeave}
                            onRowClicked={onRowClicked}
                            onRowDataUpdated={onRowDataUpdated}
                        ></AgGridReact>
                    </div>
                </Resizer>
            </Dropdown>

            <Form labelCol={{ span: 6 }} labelAlign="left">
                {/* <Form.Item  >
                    <Space > 操作：</Space>
                </Form.Item> */}

                <Form.Item  >
                    <Row align="stretch" >
                        <Button type="text" icon={<FileAddOutlined />} onClick={() => newLayer(props.value)} />
                        <Button type="text" icon={<DeleteOutlined />} onClick={() => delLayer(props.value)} />
                        <Button type="text" icon={<SaveOutlined />} onClick={() => saveLayer(props.value)} />
                    </Row>
                </Form.Item>

                <Form.Item  >
                    <Space > 变换：</Space>
                </Form.Item>

                <Form.Item >
                    <Space align="center">

                        <Button type="text" icon={transLock ? <LockOutlined /> : <UnlockOutlined />}
                            onClick={lock_onClick} />

                        <Form style={{ paddingLeft: 5 }} labelCol={{ span: 6 }} labelAlign="left">
                            <Form.Item  >
                                <Row align="stretch" >
                                    <Space style={{ paddingRight: 8, width: 20 }} > W: </Space>
                                    <InputNumber readOnly={transWHDisabled} precision={1} style={{ width: 80 }} min={1} max={10000} value={transW} //onChange={(e) => updateTransW(e)}
                                        onStep={(e) => updateValue(e, false, (e1) => updateTransW(e1))} onPressEnter={(e) => updateValue(e, true, (e1) => updateTransW(e1))}
                                    />
                                    <Space style={{ paddingLeft: 20, paddingRight: 8 }} > X: </Space>
                                    <InputNumber readOnly={transXYDisabled} precision={1} style={{ width: 80 }} min={-10000} max={10000} value={transX} //onChange={(e) => updateTransX(e)}
                                        onStep={(e) => updateValue(e, false, (e1) => updateTransX(e1))} onPressEnter={(e) => updateValue(e, true, (e1) => updateTransX(e1))}
                                    />
                                </Row>

                                <Row align="stretch" >
                                    <Space style={{ paddingRight: 8, width: 20 }} > H: </Space>
                                    <InputNumber readOnly={transWHDisabled} precision={1} style={{ width: 80 }} min={1} max={10000} value={transH} //onChange={(e) => updateTransH(e)}
                                        onStep={(e) => updateValue(e, false, (e1) => updateTransH(e1))} onPressEnter={(e) => updateValue(e, true, (e1) => updateTransH(e1))}

                                    />
                                    <Space style={{ paddingLeft: 20, paddingRight: 8 }} > Y: </Space>
                                    <InputNumber readOnly={transXYDisabled} precision={1} style={{ width: 80 }} min={-10000} max={10000} value={transY}
                                        //onChange={(e) => updateTransY(e)}
                                        onStep={(e) => updateValue(e, false, (e1) => updateTransY(e1))} onPressEnter={(e) => updateValue(e, true, (e1) => updateTransY(e1))}
                                    />
                                </Row>

                            </Form.Item>
                        </Form>
                    </Space>
                </Form.Item>

                <Form.Item  >
                    <Space style={{ paddingLeft: 36, paddingRight: 8, width: 80 }} align="center" > <RotateRightOutlined />
                        <InputNumber readOnly={transDegreeDisabled} style={{ width: 80 }} min={-10000} max={10000} value={transDegree}
                            // onChange={(e) => updateTransDegree(e)}
                            onStep={(e) => updateValue(e, false, (e1) => updateTransDegree(e1))} onPressEnter={(e) => updateValue(e, true, (e1) => updateTransDegree(e1))}
                        />
                    </Space>
                </Form.Item>
                {!transWHDisabled ? (<Form.Item  >
                    <Button type="text" icon={<ColumnWidthOutlined />} onClick={flipH_onClick} > 水平翻转 </Button>
                    <Button type="text" icon={<ColumnHeightOutlined />} onClick={flipV_onClick} > 垂直翻转 </Button>
                </Form.Item>) : <></>}


            </Form>

            {stylePanelOpen && layerConfig.current && (
                <LayerStylePanel
                    data={styleLayerData}
                    open={stylePanelOpen}
                    setOpen={setStylePanelOpen}
                    originalConfig={layerConfig.current} />
            )}
        </>
    );
}


export default LayerPanel;