import { Button, Form, Slider, Select, Space } from "antd";
// import BasePanel from "./BasePanel"
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { ChannelModes } from "../../utils/defs";


function BlackWhitePanel(props) {

    const DEFALUT_RED = 40;
    const DEFALUT_YELLOW = 60;
    const DEFALUT_GREEN = 40;
    const DEFALUT_CYAN = 60;
    const DEFALUT_BLUE = 20;
    const DEFALUT_MAGENTA = 80;

    const [red, setRed] = useState(DEFALUT_RED);
    const [yellow, setYellow] = useState(DEFALUT_YELLOW);
    const [green, setGreen] = useState(DEFALUT_GREEN);
    const [cyan, setCyan] = useState(DEFALUT_CYAN);
    const [blue, setBlue] = useState(DEFALUT_BLUE);
    const [magenta, setMagenta] = useState(DEFALUT_MAGENTA);

    useEffect(() => {
        if (props.tool === "adjustment" && props.subTool === "adjustment-black-white") {

            console.log("BlackWhitePanel: ", props);
            // window.PSMan_onToolEvents = function (nm, obj) {
            //     console.log("PSMan_onToolEvents(hue-saturation)>>>>>>:: ", nm, obj);
            // }
            let cfg = window.PSMan?.getInstance().toolManager().current()?.getConfig();
            if (cfg != null) {
                console.log("BlackWhitePanel: init config:", cfg);
                setRed(cfg.red);
                setYellow(cfg.yellow);
                setGreen(cfg.green);
                setCyan(cfg.cyan);
                setBlue(cfg.blue);
                setMagenta(cfg.magenta);
            }
        }
    }, [props]);


    const red_onChange = (newValue) => {
        if (newValue == null) { return; }
        setRed(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ red: newValue });
    };
    const yellow_onChange = (newValue) => {
        if (newValue == null) { return; }
        setYellow(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ yellow: newValue });
    };
    const green_onChange = (newValue) => {
        if (newValue == null) { return; }
        setGreen(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ green: newValue });
    };
    const cyan_onChange = (newValue) => {
        if (newValue == null) { return; }
        setCyan(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ cyan: newValue });
    };
    const blue_onChange = (newValue) => {
        if (newValue == null) { return; }
        setBlue(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ blue: newValue });
    };
    const magenta_onChange = (newValue) => {
        if (newValue == null) { return; }
        setMagenta(newValue);
        window.PSMan?.getInstance().toolManager().current().setConfig({ magenta: newValue });
    };

    const reset_onClick = () => {
        setRed(DEFALUT_RED);
        setYellow(DEFALUT_YELLOW);
        setGreen(DEFALUT_GREEN);
        setCyan(DEFALUT_CYAN);
        setBlue(DEFALUT_BLUE);
        setMagenta(DEFALUT_MAGENTA);

        window.PSMan?.getInstance().toolManager().current().setConfig({
            red: DEFALUT_RED,
            yellow: DEFALUT_YELLOW,
            green: DEFALUT_GREEN,
            cyan: DEFALUT_CYAN,
            blue: DEFALUT_BLUE,
            magenta: DEFALUT_MAGENTA
        });
    }

    const apply_onClick = () => {
        window.PSMan?.getInstance().toolManager().current().sendAction("apply", null);
        setRed(DEFALUT_RED);
        setYellow(DEFALUT_YELLOW);
        setGreen(DEFALUT_GREEN);
        setCyan(DEFALUT_CYAN);
        setBlue(DEFALUT_BLUE);
        setMagenta(DEFALUT_MAGENTA);
        props.onApply();
    }


    return (
        <>
            <FormItem label="红色">
                <Slider min={-200} max={300} value={red} onChange={red_onChange} />
            </FormItem>
            <FormItem label="黄色">
                <Slider min={-200} max={300} value={yellow} onChange={yellow_onChange} />
            </FormItem>
            <FormItem label="绿色">
                <Slider min={-200} max={300} value={green} onChange={green_onChange} />
            </FormItem>
            <FormItem label="青色">
                <Slider min={-200} max={300} value={cyan} onChange={cyan_onChange} />
            </FormItem>
            <FormItem label="蓝色">
                <Slider min={-200} max={300} value={blue} onChange={blue_onChange} />
            </FormItem>
            <FormItem label="洋红">
                <Slider min={-200} max={300} value={magenta} onChange={magenta_onChange} />
            </FormItem>

            <Form.Item>
                <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button size="small" onClick={reset_onClick}>重置</Button>
                    <Button size="small" onClick={apply_onClick} type="primary">确定</Button>
                </Space>
            </Form.Item>
        </>
    );
}

export default BlackWhitePanel;