import { createGlobalStore } from "hox";
import { useState } from "react";

function useSettings() {

    const [newEmphasis, setNewEmphasis] = useState(true)
    const [showRestricted, setShowRestricted] = useState(false)
    const [useFixedMultiplier, setUseFixedMultiplier] = useState(false)
    const [showImage, setShowImage] = useState(false)


    return {
        newEmphasis,
        setNewEmphasis,
        showRestricted,
        setShowRestricted,
        useFixedMultiplier,
        setUseFixedMultiplier,
        showImage,
        setShowImage
    }
}

export const [useSettingsStore] = createGlobalStore(useSettings);