
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Button as AButton, Space, Popover, Radio, Tooltip, Collapse, Button, Form } from 'antd';


// import React 
// import React from "react";

// import styled from "styled-components";


// import { Button as AButton, Space, } from 'antd';

import { LeftOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { CopyOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';



// import { Input } from 'antd';

// const { TextArea } = Input;


// function DreamInfoTextView({ info }) {
//     return 
//     <div>
//         <Space size={10} style={{ width: "100%" }} direction="vertical">
//             <p>{info}</p>
//         </Space>
//     </div>;
// }

function DreamResultPopover({ disabled, info }) {


    const [hovered, setHovered] = useState(false);
    const handleHoverChange = (open) => {
        setHovered(open);
        // setClicked(false);
    };

    // const hoverContent = ({ info }) => {

    //     return 
    // }

    const onClickCopy = (e) => {
        // let t = info;
        // console.log("============================\n", t);
        navigator.clipboard.writeText(info);
    }

    return (
        <Popover
            style={{
                width: "500px",
            }}
            content={
                <div style={{ "white-space": "pre-wrap", "max-width": "500px" }}>
                    {info}
                </div>
            }
            title={
                <>
                    <Space size={6}>
                        <label style={{"font-size":"12pt", "color":"#00ff00"}}> 生成信息: </label>
                        <Button shape="circle" icon={<CopyOutlined />} onClick={onClickCopy}></Button>
                    </Space>
                </>
            }
            trigger="hover"
            open={hovered}
            onOpenChange={handleHoverChange}
        >
            <Button style={{ padding: 0, width: '32px', height: '32px' }} disabled={disabled} type="primary" shape="circle" icon={<InfoCircleOutlined />} />
        </Popover>
    );


}


function DreamResultPanel({ actionState, imageIndex, imageSize, dreamInfo }) {

    const [indexButtonLabel, setIndexButtonLabel] = useState("0/0");
    // const [info, setInfo] = useState("");

    // useEffect(() => {
    //     console.log("DreamResultPanel::componentWillMount() " + window.PSMan);
    //     return () => {
    //         console.log("HistoryPanel::componentWillUnmount()");
    //         // Anything in here is fired on component unmount.
    //     }
    // }, [])

    useEffect(() => {
        // console.log("CURRENT ::::::::::: DDINFO ", dreamInfo);
        let lb = "" + imageIndex + "/" + imageSize;
        setIndexButtonLabel(lb);
        if (imageIndex <= 0 || imageIndex > imageSize || dreamInfo.length === 0) {
            // setInfo("");
            // console.log("CURRENT ::::::::::: 。。。。。。。。。。。。。。。。");
        } else {
            // let cinfo = dreamInfo[imageIndex - 1];
            // setInfo(dreamInfo);
            console.log("CURRENT ::::::::::: INFO ", dreamInfo);
        }
    }, [imageIndex, imageSize, dreamInfo]);


    // dream result ------------------------------
    const DreamButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("dream", {});
    }
    const PreviousButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("prev", {});
    }

    const IndexButton_Click = () => {
    }

    const NextButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("next", {});
    }

    const MoreButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("more", {});
    }

    const ApplyButton_Click = () => {
        window.PSMan.getInstance().toolManager().current().sendAction("apply", {});
    }

    const CancelButton_Click = () => {
        // setResultPanelVisible(false)
        window.PSMan.getInstance().toolManager().current().sendAction("cancel", {});
    }

    const SaveResourcesButton_Click = () => {
        // download current index image .
        window.PSMan.getInstance().toolManager().current().sendAction("resources", {});
    }


    return (
        <div id="PanelDiv" >

            <Form.Item id="resultPanel" style={{ paddingTop: 5 }} >
                {actionState == 1 && (
                    <Space size={6} >

                        <Tooltip title="生成">
                            <AButton id="MoreButton"
                                // disabled={actionState < 1}
                                type="primary"
                                style={{ padding: 0, width: '42px', height: '34px', fontSize: '14px' }}
                                // icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                                onClick={() => DreamButton_Click()}> 生成 </AButton>
                        </Tooltip>

                        {/* <Tooltip title="取消">
                                <AButton id="CancelButton"
                                    // disabled={!resultPanelVisible}
                                    style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                    onClick={() => CancelButton_Click()}> N </AButton>
                            </Tooltip> */}
                    </Space>

                )}

                {actionState == 2 && (
                    <Space size={2} >
                        <AButton id="PreviousButton"
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            icon={<LeftOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => PreviousButton_Click()} />

                        <AButton id="IndexButton"
                            style={{ padding: 0, width: '50px', height: '34px', fontSize: '20px' }}
                            onClick={() => IndexButton_Click()}>{indexButtonLabel}</AButton>

                        <AButton id="NextButton"
                            style={{ padding: 0, width: '30px', height: '34px' }}
                            icon={<RightOutlined style={{ fontSize: '20px' }} />}
                            onClick={() => NextButton_Click()} />

                        <Tooltip title="更多">
                            <AButton id="MoreButton"
                                // type="primary"
                                style={{ padding: 0, width: '42px', height: '34px', fontSize: '14px' }}
                                onClick={() => MoreButton_Click()}> 生成 </AButton>
                        </Tooltip>

                        <Tooltip title="确定">
                            <AButton id="ApplyButton"
                                type="primary"
                                disabled={imageIndex == 0}
                                style={{ padding: 0, width: '42px', height: '34px', fontSize: '14px' }}
                                onClick={() => ApplyButton_Click()}> 应用 </AButton>
                        </Tooltip>

                        <Tooltip title="取消">
                            <AButton id="CancelButton"
                                style={{ padding: 0, width: '42px', height: '34px', fontSize: '14px' }}
                                onClick={() => CancelButton_Click()}> 重置 </AButton>
                        </Tooltip>

                        <Tooltip title="保存到资源库">
                            <AButton disabled={imageIndex == 0} id="SaveResourcesButton"
                                style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                                onClick={() => SaveResourcesButton_Click()}> R </AButton>
                        </Tooltip>

                        <DreamResultPopover disabled={imageIndex == 0} info={dreamInfo}></DreamResultPopover>

                        {/* <AButton id="DownloadButton"
                            style={{ padding: 0, width: '30px', height: '34px', fontSize: '20px' }}
                            onClick={() => DownloadButton_Click()}> S </AButton> */}
                    </Space>

                )}
                {actionState == 3 && (
                    <Space size={6} >

                        {/* <Tooltip title="生成">
                                <AButton id="MoreButton"
                                    // disabled={actionState < 1}
                                    style={{ padding: 0, width: '30px', height: '34px' }}
                                    icon={<PlusOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => DreamButton_Click()} />
                            </Tooltip> */}

                        <Tooltip title="取消">
                            <AButton id="CancelButton"
                                type="primary"
                                danger
                                // disabled={!resultPanelVisible}
                                style={{ padding: 0, width: '64px', height: '34px', fontSize: '14px' }}
                                onClick={() => CancelButton_Click()}> 取消生成 </AButton>
                        </Tooltip>
                    </Space>
                )}

            </Form.Item>

            {/* <DreamResultPopover></DreamResultPopover> */}

        </div>
    )
}




export default DreamResultPanel;